import { debounce, useMediaQuery } from '@mui/material';
import { GDMContainer, GDMLoading, GDMTypography } from 'components';
import { RecommendationService } from 'core/api/services/recommendation';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { DataTableMode } from 'core/typing/types/common/dataTableMode.type';
import dayjs from 'dayjs';
import { IDashboardFilters } from 'pages/dashboard/components';
import { useCallback, useEffect, useState } from 'react';
import GDMRecommendationFilter from './gdm-recommendation-filter/GDMRecommendationFilter';
import GDMRecommendationTable from './gdm-recommendation-list-desktop/GDMRecommendationTable';

const RecommendationList = () => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const filtersOfDashboard = JSON.parse(sessionStorage.getItem('recommendationFilters'));
  const isDesktop = useMediaQuery('(min-width:960px)');
  const currentYear = new Date().getFullYear();
  const initial = new Date(Date.UTC(currentYear, 0, 1)).toISOString();
  const end = new Date(Date.UTC(currentYear, 11, 31, 23, 59, 59, 999)).toISOString();

  const fromDate = dayjs(initial).startOf('day').add(1, 'day');
  const toDate = dayjs(end).endOf('day');

  const [periods, setPeriods] = useState({
    periodFrom: fromDate,
    periodTo: toDate,
  });

  const [listResponse, setListResponse] = useState({ rows: null, pages: 0, filters: null });
  const [prevFilters, setPrevFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataTableMode, setDataTableMode] = useState<DataTableMode>();
  const [initialState, setInitialState] = useState(true);
  const [filter, setFilter] = useState({
    page: 1,
    itemsPerPage: 4,
    orderDesc: false,
    orderColumn: '',
    search: '',
    brand: [],
    culture: [],
    technology: [],
    genotype: [],
    status: [],
    state: [],
    city: [],
    role: [],
    multiplier: [],
    distributor: [],
    user: [],
    periodFrom: periods.periodFrom,
    periodTo: periods.periodTo,
  });

  const columnTitle: { name: string; title: any; type: string }[] = [
    {
      name: 'Name',
      title: getLabelTranslation('name_recommendation'),
      type: 'text',
    },
    {
      name: 'FarmerName',
      title: getLabelTranslation('farmer'),
      type: 'text',
    },
    {
      name: 'UserName',
      title: getLabelTranslation('account'),
      type: 'text',
    },
    {
      name: 'RattingValue',
      title: getLabelTranslation('ratting'),
      type: 'text',
    },
  ];

  const loadRecommendations = async () => {
    try {
      setIsLoading(true);
      const response = await RecommendationService.getListRecommendation(filter);
      if (!filter.search && !response.items?.length) setDataTableMode(DataTableMode.DEFAULT);
      setListResponse({
        pages: response.totalPages,
        rows: response.items,
        filters: response.filter,
      });
    } catch (error) {
      console.error(error);
      setDataTableMode(DataTableMode.ERROR);
    } finally {
      if (!filtersOfDashboard) {
        setIsLoading(false);
        setInitialState(false);
      }
    }
  };

  useEffect(() => {
    if (filtersOfDashboard && listResponse.filters) {
      const brandFilter = listResponse.filters.brand
        .filter(filter => filtersOfDashboard?.brand?.includes(filter.id))
        .map(brand => ({ ...brand, type: 'brand' }));
      const cultureFilter = listResponse.filters.culture
        .filter(filter => filtersOfDashboard?.culture?.includes(filter.id))
        .map(culture => ({ ...culture, type: 'culture' }));

      const stateFilter = listResponse.filters.state
        .filter(filter => filtersOfDashboard?.state?.includes(filter.id))
        .map(state => ({ ...state, type: 'state' }));

      const statusFilter = listResponse.filters.status
        .filter(filter => filtersOfDashboard?.status?.includes(filter.id))
        .map(status => ({ ...status, type: 'status' }));

      const cityFilter = listResponse.filters.city
        .filter(filter => filtersOfDashboard?.city?.includes(filter.id))
        .map(city => ({ ...city, type: 'city' }));

      const roleFilter = listResponse.filters.role
        .filter(filter => filtersOfDashboard?.role?.includes(filter.id))
        .map(role => ({ ...role, type: 'role' }));

      const multiplierFilter = listResponse.filters.multiplier
        .filter(filter => filtersOfDashboard?.multiplier?.includes(filter.id))
        .map(multiplier => ({ ...multiplier, type: 'multiplier' }));
      const distributorFilter = listResponse.filters.distributor
        .filter(filter => filtersOfDashboard?.distributor?.includes(filter.id))
        .map(user => ({ ...user, type: 'distributor' }));
      const userFilter = listResponse.filters.user
        .filter(filter => filtersOfDashboard?.user?.includes(filter.id))
        .map(user => ({ ...user, type: 'user' }));

      setPrevFilters([
        brandFilter.length > 0 ? brandFilter : [],
        cultureFilter.length > 0 ? cultureFilter : [],
        statusFilter.length > 0 ? statusFilter : [],
        cityFilter.length > 0 ? cityFilter : [],
        stateFilter.length > 0 ? stateFilter : [],
        roleFilter.length > 0 ? roleFilter : [],
        multiplierFilter.length > 0 ? multiplierFilter : [],
        distributorFilter.length > 0 ? distributorFilter : [],
        userFilter.length > 0 ? userFilter : [],
        dayjs(filtersOfDashboard.startDate),
        dayjs(filtersOfDashboard.endDate),
      ]);
      if (filtersOfDashboard) {
        setFilter(prev => ({
          ...prev,
          brand: filtersOfDashboard.brand ? filtersOfDashboard.brand : [],
          culture: filtersOfDashboard.culture ? filtersOfDashboard.culture : [],
          status: filtersOfDashboard.status ? filtersOfDashboard.status : [],
          city: filtersOfDashboard.city ? filtersOfDashboard.city : [],
          state: filtersOfDashboard.state ? filtersOfDashboard.state : [],
          role: filtersOfDashboard.role ? filtersOfDashboard.role : [],
          multiplier: filtersOfDashboard.multiplier ? filtersOfDashboard.multiplier : [],
          distributor: filtersOfDashboard.distributor ? filtersOfDashboard.distributor : [],
          user: filtersOfDashboard.user ? filtersOfDashboard.user : [],
          periodFrom: dayjs(filtersOfDashboard.startDate),
          periodTo: dayjs(filtersOfDashboard.endDate),
        }));
      }
      sessionStorage.removeItem('recommendationFilters');
    }
  }, [JSON.stringify(filtersOfDashboard), listResponse.filters]);

  useEffect(() => {
    if (dataTableMode !== DataTableMode.ERROR && !!filter.search)
      setDataTableMode(DataTableMode.SEARCH);
  }, [filter.search]);

  const handleChangeFilter = useCallback(
    debounce((search: string) => {
      setFilter(prev => ({ ...prev, search, page: 1 }));
    }, 1000),
    [],
  );

  useEffect(() => {
    loadRecommendations();
  }, [filter]);

  return (
    <GDMContainer
      component="div"
      sx={{
        boxShadow: isDesktop && '0 2px 8px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        borderRadius: '24px',
        padding: isDesktop ? '59px 66px' : '4px 12px',
        backgroundColor: isDesktop && '#fff',
      }}
    >
      <GDMTypography
        sx={{
          marginBottom: '32px',
          color: '#A1A9B8',
          fontSize: '14px',
          textAlign: isDesktop ? 'start' : 'center',
        }}
      >
        {getLabelTranslation('breadcrumb_list_recommendations')}
      </GDMTypography>

      <GDMContainer
        sx={{
          display: 'flex',
          marginBottom: '32px',
          gap: isDesktop ? '80px' : '24px',
          flexDirection: isDesktop ? 'row' : 'column',
        }}
      >
        <GDMContainer
          sx={{
            color: '#222',
            display: 'flex',
            flexDirection: 'column',
            gap: isDesktop ? '8px' : '9px',
            textAlign: isDesktop ? 'start' : 'center',
          }}
        >
          <GDMTypography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '32px', md: '32px' },
              lineHeight: { xs: '35.2px', md: '35.2px' },
            }}
          >
            {getLabelTranslation('see_recommendations')}
          </GDMTypography>
          <GDMTypography
            sx={{ fontSize: { xs: '16px', md: '16px' }, lineHeight: { xs: '20px', md: '20px' } }}
          >
            {getLabelTranslation('enter_and_manage_recommendations')}
          </GDMTypography>
        </GDMContainer>
        <GDMContainer sx={{ flex: 1 }}>
          <GDMRecommendationFilter
            filters={listResponse.filters}
            prevFilters={prevFilters}
            setPrevFilters={setPrevFilters}
            setFilter={setFilter}
            filter={filter}
            periods={periods}
            setPeriods={setPeriods}
            handleChangeSearch={handleChangeFilter}
          />
        </GDMContainer>
      </GDMContainer>

      <GDMContainer>
        {isLoading && (
          <GDMContainer
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20%',
              paddingBottom: '15%',
            }}
          >
            <GDMLoading />
          </GDMContainer>
        )}
        {!isLoading && !initialState && (
          <GDMRecommendationTable
            orderDesc={filter.orderDesc}
            header={columnTitle}
            rows={listResponse?.rows || []}
            currentPage={filter.page}
            pages={listResponse?.pages || 0}
            setFilter={setFilter}
            setListResponse={setListResponse}
            dataTableMode={dataTableMode}
          />
        )}
      </GDMContainer>
    </GDMContainer>
  );
};

export default RecommendationList;
