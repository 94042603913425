import { MaskConfig, Masks } from 'core/typing/types/common/mask.type';
import { GetMaskFunction } from './getMask.type';

const maskConfig: MaskConfig = {
  Brasil: {
    companyDocumentNumber: Masks.COMPANY_DOCUMENT_NUMBER_BR,
    personDocumentNumber: Masks.PERSON_DOCUMENT_NUMBER_BR,
    phoneNumber: Masks.PHONE_NUMBER_BR,
  },
  Argentina: {
    companyDocumentNumber: Masks.COMPANY_DOCUMENT_NUMBER_AR,
    personDocumentNumber: Masks.PERSON_DOCUMENT_NUMBER_AR,
    phoneNumber: Masks.PHONE_NUMBER_AR,
  },
};
const getMask: GetMaskFunction = ({ countryName, type }) => {
  return maskConfig[countryName]?.[type];
};

export default getMask;
