import { CircularProgressProps, LinearProgressProps } from '@mui/material';

import { GDMComponent } from 'components/GDMComponent.type';

export enum GDMLoadingType {
  CIRCULAR = 'circular',
  LINEAR = 'linear',
}

export interface IGDMLoadingProps {
  type?: GDMLoadingType | `${GDMLoadingType}`;
}

export type GDMLoadingComponentProps =
  | (IGDMLoadingProps & CircularProgressProps)
  | (IGDMLoadingProps & LinearProgressProps);

export type GDMLoadingComponent = GDMComponent<GDMLoadingComponentProps>;
