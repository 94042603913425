import { LocationsService } from 'core/api/services/locations';
import { LoadCountriesFunction } from './loadCountries.type';

const loadCountries: LoadCountriesFunction = async ({ onInit, onSuccess, onError, onFinally }) => {
  if (onInit) onInit();

  try {
    const states = await LocationsService.getCountries();

    if (onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (onError) onError(error);

    return [];
  } finally {
    if (onFinally) onFinally();
  }
};

export default loadCountries;
