import { SxProps } from '@mui/material';

export const formContainerStyles: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '16px',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
    borderRadius: 'none',
    width: '100%',
    boxShadow: 'none',
  },
  input: {
    borderRadius: '6px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    color: 'initial',
    ':disabled': {
      background: '#F2F4F9',
    },
  },

  '& .MuiInputLabel-shrink': {
    visibility: 'hidden',
  },
  '.MuiFormHelperText-root': {
    marginLeft: '0',
  },
};

export const gridForm: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(5, 80px)',
};

export const buttonStyle = ({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  padding: '14px 64px',
  width: '187px',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    background: theme.palette.action.disabled,
    color: 'white',
  },
});
