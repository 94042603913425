/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Box, Tooltip, Zoom } from '@mui/material';
import { GDMTypography } from 'components';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { Genotypes, TransformedLine } from 'pages/varieties-to-compare/VarietiesToCompare.types';
import { FaCircleInfo } from 'react-icons/fa6';

interface ContentLinesProps {
  lines: TransformedLine[];
  genotypes: Genotypes[];
  varietyId: string;
}

export default function ContentLines({
  lines,
  genotypes,
  varietyId,
}: ContentLinesProps): JSX.Element {
  const { theme } = useThemeSwitcher();

  return (
    <>
      {lines.map(line => {
        return (
          <>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: varietyId ? '611px 290px' : '340px 290px 290px 290px',
              }}
            >
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    borderStyle: 'solid',
                    borderWidth: '0 0 1px 0',
                    borderColor: '#D9DBE4',
                    padding: '8px 16px',
                    height: 'auto',
                    backgroundColor: '#F2F4F9',
                  }}
                >
                  <GDMTypography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {line.characteristic}
                  </GDMTypography>
                  {(() => {
                    const value =
                      line.values[Object.keys(line.values).find(key => key.startsWith(`legend_`))];
                    if (value) {
                      return (
                        <Tooltip title={value} arrow placement="top" TransitionComponent={Zoom}>
                          <button type="button" style={{ border: 'none', background: 'none' }}>
                            <FaCircleInfo color="#A1A9B8" size={16} />
                          </button>
                        </Tooltip>
                      );
                    }
                    return null;
                  })()}
                </Box>
              </>
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    borderStyle: 'solid',
                    borderWidth: '0 0 1px 1px',
                    borderColor: '#D9DBE4',
                    padding: '8px 16px',
                    height: 'auto',
                    backgroundColor: '#F2F4F9',
                  }}
                >
                  <GDMTypography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {(() => {
                      const genotypeId = genotypes[0]?.genotype;
                      const value =
                        line.values[
                          Object.keys(line.values).find(
                            key => key === `characteristicValue_${genotypeId}`,
                          )
                        ];
                      const toolTip =
                        line.values[
                          Object.keys(line.values).find(key => key === `toolTip_${genotypeId}`)
                        ];

                      const toolTipKey = `toolTip_${genotypeId}`;
                      if (Array.isArray(value) && value.length > 1) {
                        return value.map((item, index) => (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <GDMTypography
                              sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: theme.palette.primary.main,
                              }}
                            >
                              • {item}
                              <br />
                            </GDMTypography>
                            {line.values[toolTipKey] && (
                              <Tooltip
                                title={line.values[toolTipKey][index]}
                                arrow
                                placement="top"
                                TransitionComponent={Zoom}
                              >
                                <button
                                  type="button"
                                  style={{ border: 'none', background: 'none' }}
                                >
                                  <FaCircleInfo color="#A1A9B8" size={16} />
                                </button>
                              </Tooltip>
                            )}
                          </Box>
                        ));
                      }
                      if (Array.isArray(value) && value.length === 1) {
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <GDMTypography
                              sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: theme.palette.primary.main,
                              }}
                            >
                              {value}
                            </GDMTypography>
                            {toolTip && (
                              <Tooltip
                                title={toolTip}
                                arrow
                                placement="top"
                                TransitionComponent={Zoom}
                              >
                                <button
                                  type="button"
                                  style={{ border: 'none', background: 'none' }}
                                >
                                  <FaCircleInfo color="#A1A9B8" size={16} />
                                </button>
                              </Tooltip>
                            )}
                          </Box>
                        );
                      }

                      return '-';
                    })()}
                  </GDMTypography>
                </Box>
              </>
              {genotypes.length > 1 && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      borderStyle: 'solid',
                      borderWidth: '0 0 1px 1px',
                      borderColor: '#D9DBE4',
                      padding: '8px 16px',
                      height: 'auto',
                      backgroundColor: '#F2F4F9',
                    }}
                  >
                    <GDMTypography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: theme.palette.primary.main,
                      }}
                    >
                      {(() => {
                        const genotypeId = genotypes[1]?.genotype;
                        const value =
                          line.values[
                            Object.keys(line.values).find(
                              key => key === `characteristicValue_${genotypeId}`,
                            )
                          ];
                        const toolTip =
                          line.values[
                            Object.keys(line.values).find(key => key === `toolTip_${genotypeId}`)
                          ];
                        const toolTipKey = `toolTip_${genotypeId}`;
                        if (Array.isArray(value) && value.length > 1) {
                          return value.map((item, index) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <GDMTypography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                • {item}
                                <br />
                              </GDMTypography>
                              {line.values[toolTipKey] && (
                                <Tooltip
                                  title={line.values[toolTipKey][index]}
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                >
                                  <button
                                    type="button"
                                    style={{ border: 'none', background: 'none' }}
                                  >
                                    <FaCircleInfo color="#A1A9B8" size={16} />
                                  </button>
                                </Tooltip>
                              )}
                            </Box>
                          ));
                        }
                        if (Array.isArray(value) && value.length === 1) {
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <GDMTypography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {value}
                              </GDMTypography>
                              {toolTip && (
                                <Tooltip
                                  title={toolTip}
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                >
                                  <button
                                    type="button"
                                    style={{ border: 'none', background: 'none' }}
                                  >
                                    <FaCircleInfo color="#A1A9B8" size={16} />
                                  </button>
                                </Tooltip>
                              )}
                            </Box>
                          );
                        }

                        return '-';
                      })()}
                    </GDMTypography>
                  </Box>
                </>
              )}
              {genotypes.length > 2 && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                      borderStyle: 'solid',
                      borderWidth: '0 0 1px 1px',
                      borderColor: '#D9DBE4',
                      padding: '8px 16px',
                      height: 'auto',
                      backgroundColor: '#F2F4F9',
                    }}
                  >
                    <GDMTypography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: theme.palette.primary.main,
                      }}
                    >
                      {(() => {
                        const genotypeId = genotypes[2]?.genotype;
                        const value =
                          line.values[
                            Object.keys(line.values).find(
                              key => key === `characteristicValue_${genotypeId}`,
                            )
                          ];
                        const toolTip =
                          line.values[
                            Object.keys(line.values).find(key => key === `toolTip_${genotypeId}`)
                          ];
                        const toolTipKey = `toolTip_${genotypeId}`;
                        if (Array.isArray(value) && value.length > 1) {
                          return value.map((item, index) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <GDMTypography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                • {item}
                                <br />
                              </GDMTypography>
                              {line.values[toolTipKey] && (
                                <Tooltip
                                  title={line.values[toolTipKey][index]}
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                >
                                  <button
                                    type="button"
                                    style={{ border: 'none', background: 'none' }}
                                  >
                                    <FaCircleInfo color="#A1A9B8" size={16} />
                                  </button>
                                </Tooltip>
                              )}
                            </Box>
                          ));
                        }
                        if (Array.isArray(value) && value.length === 1) {
                          return (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                              <GDMTypography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: theme.palette.primary.main,
                                }}
                              >
                                {value}
                              </GDMTypography>
                              {toolTip && (
                                <Tooltip
                                  title={toolTip}
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                >
                                  <button
                                    type="button"
                                    style={{ border: 'none', background: 'none' }}
                                  >
                                    <FaCircleInfo color="#A1A9B8" size={16} />
                                  </button>
                                </Tooltip>
                              )}
                            </Box>
                          );
                        }

                        return '-';
                      })()}
                    </GDMTypography>
                  </Box>
                </>
              )}
            </Box>
          </>
        );
      })}
    </>
  );
}
