import { RegionsServices } from 'core/api/services/regions';
import { GetRegionsFunction } from './getRegions.type';

const getRegions: GetRegionsFunction = async ({
  countryId,
  onInit,
  onSuccess,
  onError,
  onFinally,
}) => {
  if (!!onInit) onInit();
  try {
    const states = await RegionsServices.getRegions(countryId);

    if (!!onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (!!onError) onError(error);

    return [];
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default getRegions;
