import { SxProps } from '@mui/material';
// import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { SxPropsThemeFunction } from './DashboardFilters.type';

// const { theme } = useThemeSwitcher();

export const filterContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  padding: {
    xs: '16px',
    sm: '16px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
  width: '100%',
  border: '1px solid #CBCDD9',
  backgroundColor: '#fff',
  borderRadius: '20px',
};

export const filterHeaderStyles: SxProps = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  justifyContent: 'space-between',
  padding: '8px 0px',
  gap: '16px',
  width: '100%',
};

export const filterTitleContainerStyles: SxProps = {
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
};

export const filterTitleStyles: SxPropsThemeFunction = theme => ({
  fontSize: '24px!important',
  lineHeight: '26.4px!important',
  fontWeight: 700,
  color: theme.palette.primary.main,
});

export const filterUpdateButtonStyles = theme => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
  minWidth: '181px',
  width: {
    xs: '100%',
    sm: '100%',
    md: 'fit-content',
    lg: 'fit-content',
    xl: 'fit-content',
  },
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    background: theme.palette.action.disabled,
    color: 'white',
  },
});

export const filterResetButtonStyles: SxProps = {
  borderRadius: '4px',
  fontWeight: '300',
  padding: '0px',
  color: '#0063F7',
  '&:hover, &:active, &:focus': {
    background: 'transparent',
    'span.MuiTouchRipple-root': {
      display: 'none',
    },
  },
  marginLeft: 'auto',
  height: {
    xs: 'fit-content',
    sm: 'fit-content',
    md: '51px',
    lg: '51px',
    xl: '51px',
  },
};

export const filterFormContainerStyles: SxProps = {
  display: 'flex',
  gap: '16px',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
};

export const filterFormContentStyles: SxProps = {
  display: 'flex',
  width: '100%',
  gap: '8px',
  '& > *': {
    flexGrow: 1,
    overflow: 'hidden',
  },
};

export const generalFiltersContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  gap: '8px',
  flex: 1,
};

export const adminFiltersContainerStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  flexDirection: {
    xs: 'column',
    sm: 'column',
    md: 'row',
    lg: 'row',
    xl: 'row',
  },
  flex: 1,
};

export const allFiltersContainerStyles: SxProps = {
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  flex: 1,
};

export const showMoreStyles: SxProps = {
  display: 'flex',
  // width: {
  //   xs: '100%',
  //   sm: '100%',
  //   md: '171px',
  //   lg: '171px',
  //   xl: '171px',
  // },
  // minWidth: {
  //   xs: '100%',
  //   sm: '100%',
  //   md: '171px',
  //   lg: '171px',
  //   xl: '171px',
  // },
  height: '51px',
  marginTop: {
    xs: '0px',
    sm: '0px',
    md: '44.18px',
    lg: '44.18px',
    xl: '44.18px',
  },
  alignItems: 'center',
  justifyContent: 'center',
  button: {
    paddin: '6px 16px',
    width: {
      xs: '100%',
      sm: '100%',
      md: '120px',
      lg: '140px',
      xl: '171px',
    },
    minWidth: {
      xs: '100%',
      sm: '100%',
      md: '120px',
      lg: '140px',
      xl: '171px',
    },
  },
};
