import { GDMContainer, GDMTypography } from 'components';
import {
  pageCardHeaderBreadcrumbsStyles,
  pageCardHeaderStyles,
  pageCardHeaderTitleStyles,
} from './GDMPageCardHeader.styles';
import { GDMPageCardHeaderComponent } from './GDMPageCardHeader.type';

const GDMPageCardHeader: GDMPageCardHeaderComponent = ({ title = '', breadcrumbs = '' }) => {
  const isBreadcrumbsString = typeof breadcrumbs === 'string';
  const isTitleString = typeof title === 'string';

  return (
    <GDMContainer sx={pageCardHeaderStyles}>
      {!isBreadcrumbsString && breadcrumbs}

      {isBreadcrumbsString && (
        <GDMTypography sx={pageCardHeaderBreadcrumbsStyles}>{breadcrumbs}</GDMTypography>
      )}

      {!isTitleString && title}

      {isTitleString && (
        <GDMTypography sx={pageCardHeaderTitleStyles} component="h1">
          {title}
        </GDMTypography>
      )}
    </GDMContainer>
  );
};

export default GDMPageCardHeader;
