import { GDMContainer } from 'components/gdm-container';
import { GDMFormHelperText } from 'components/gdm-form-helper-text';
import { GDMSecureImage } from 'components/gdm-secure-image';
import { GDMTypography } from 'components/gdm-typography';
import { useLanguageContext } from 'core/store/contexts/languageContext';
import { useEffect, useState } from 'react';
import { PiExportLight } from 'react-icons/pi';
import {
  fileBoxTextRequiredIndicatorStyles,
  fileBoxTextStyles,
  imageFileFieldContainerStyles,
  imageFileFieldContentStyles,
  imageFileFieldErrorMessageStyles,
  inputFileStyles,
  secureImageStyles,
} from './DynamicFormImageFileField.styles';
import {
  DynamicFormImageFileFieldComponent,
  ImageFileFieldValue,
} from './DynamicFormImageFileField.type';

const DynamicFormImageFileField: DynamicFormImageFileFieldComponent = ({
  placeholder,
  required,
  onChange,
  defaultValue,
  value,
  disabled,
  error,
  helperText,
}) => {
  const { getLabelTranslation, currentLanguage } = useLanguageContext();

  const [fileName, setFileName] = useState((value as File)?.name || '');
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const [textProps, setTextProps] = useState({
    placeholder: getLabelTranslation(placeholder),
    helperText: getLabelTranslation(helperText as string),
  });

  useEffect(() => {
    setTextProps({
      placeholder: getLabelTranslation(placeholder),
      helperText: getLabelTranslation(helperText as string),
    });
  }, [currentLanguage, placeholder, helperText]);

  useEffect(() => {
    const fileValue = value || defaultValue;

    const isBlobOrMediaOrFile =
      fileValue instanceof Blob ||
      (fileValue as MediaSource) instanceof MediaSource ||
      (fileValue as ImageFileFieldValue as File) instanceof File;

    if (isBlobOrMediaOrFile) {
      setFilePreview(URL.createObjectURL(fileValue as Blob));
    } else if (!!fileValue) {
      setFilePreview(fileValue as string);
    } else {
      setFilePreview(null);
    }
  }, [value, defaultValue]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      setFileName(file.name);
      setFilePreview(URL.createObjectURL(file));
      onChange(file);
    }
  };

  const showErrorMessage = !!(error && helperText);

  const isComponentErrorMessage = !!helperText && typeof helperText !== 'string';

  return (
    <GDMContainer sx={imageFileFieldContainerStyles}>
      <GDMContainer
        sx={{
          ...imageFileFieldContentStyles,
          ...(disabled ? { background: '#F2F4F9' } : {}),
          ...(error ? { borderColor: '#FB2047' } : {}),
        }}
        onClick={() => document.getElementById('file-upload')?.click()}
      >
        {filePreview && (
          <GDMSecureImage src={filePreview} alt="Preview" style={secureImageStyles} />
        )}

        {!filePreview && (
          <GDMTypography sx={fileBoxTextStyles}>
            {fileName || (
              <>
                <PiExportLight size={22} />
                <span>
                  {textProps.placeholder}{' '}
                  {required && <span style={fileBoxTextRequiredIndicatorStyles}>*</span>}
                </span>
              </>
            )}
          </GDMTypography>
        )}

        <input
          accept="image/*"
          style={inputFileStyles}
          id="file-upload"
          disabled={disabled}
          type="file"
          onChange={!disabled && handleFileChange}
        />
      </GDMContainer>

      {showErrorMessage &&
        (isComponentErrorMessage ? (
          helperText
        ) : (
          <GDMFormHelperText sx={imageFileFieldErrorMessageStyles}>
            {textProps.helperText}
          </GDMFormHelperText>
        ))}
    </GDMContainer>
  );
};

export default DynamicFormImageFileField;
