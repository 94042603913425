import { useMediaQuery } from '@mui/material';
import {
  GDMButton,
  GDMContainer,
  GDMGrid,
  GDMLoading,
  GDMTableContainer,
  GDMTypography,
} from 'components';
import { RecommendationService } from 'core/api/services/recommendation';
import LoadUserProfileUseCase from 'core/api/useCases/UserProfileUseCase/LoadUserProfileUseCase';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import useModal from 'hooks/useModal/useModal';
import { useEffect, useState } from 'react';
import { TbBuildingCottage, TbNotes } from 'react-icons/tb';
import {
  containerStyle,
  flapStyleActive,
  flapStyleDisabled,
} from './RecommendationPendingList.style';
import PendingListTable from './components/table/PendingListTable';

enum SelectedTab {
  PLANTING = 'PLANTING',
  HARVEST = 'HARVEST',
}

const STATUS_PLANTED = 'c363e89d-7e7e-459a-a323-0b323d566970';
const STATUS_INTERRUPTED = '02f37950-a428-4800-8eca-55072f20c376';

export default function RecommendationPendingList(): JSX.Element {
  const { theme } = useThemeSwitcher();
  const { openModal, closeModal } = useModal();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation, loading } = useLanguageSwitcher();
  const [pendingPlanting, setPendingPlanting] = useState([]);
  const [pendingHarvest, setPendingHarvest] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<SelectedTab>();
  const [selectedsIds, setSelectedsIds] = useState([]);
  const [changedFlap, setChangedFlap] = useState(false);
  const [orderColumn, setOrderColumn] = useState({
    orderDesc: false,
    column: '',
  });

  useEffect(() => {
    getPendingList(orderColumn);
  }, []);

  const getPendingList = async ({ column, orderDesc }) => {
    try {
      setIsLoading(true);
      const list = await RecommendationService.getPendingRecommendations({
        columnName: column,
        orderDesc,
      });
      setPendingPlanting(list.pendingPlanting);
      setPendingHarvest(list.pendingHarvest);
      if (!changedFlap) {
        setActiveTab(list.pendingPlanting?.length > 0 ? SelectedTab.PLANTING : SelectedTab.HARVEST);
        if (activeTab === SelectedTab.PLANTING) {
          setRows(list.pendingPlanting?.length > 0 ? list.pendingPlanting : list.pendingHarvest);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setRows(activeTab === SelectedTab.PLANTING ? pendingPlanting : pendingHarvest);
    setSelectedsIds([]);
  }, [activeTab]);

  const handleChangeFlap = flap => {
    setChangedFlap(true);
    setOrderColumn({ column: '', orderDesc: false });
    setActiveTab(flap);
  };

  const updateRecommendationStatus = async (statusId: string): Promise<void> => {
    try {
      setIsLoading(true);
      const dataToSend = selectedsIds.map((id: string) => {
        const item = { recommendationId: id, statusId };
        return item;
      });
      await RecommendationService.updatePendingRecommendationStatus({ data: dataToSend });
      setSelectedsIds([]);
      await getPendingList(orderColumn);
      await LoadUserProfileUseCase.execute();
      handleChangeFlap(activeTab);

      openModal({
        type: 'success',
        title: getLabelTranslation('recommendation_status_update_success_title'),
        message: getLabelTranslation('recommendation_status_update_success_message'),
        onClose: () => true,
      });
    } catch (error) {
      console.error(error);
      openModal({
        type: 'error',
        title: getLabelTranslation('recommendation_status_update_failed_title'),
        message: getLabelTranslation('recommendation_status_update_failed_message'),
        onClose: () => true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateButton = async (id: string): Promise<void> => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('recommendation_status_confirm_title'),
      message: getLabelTranslation('recommendation_status_confirm_message'),
      primaryAction: {
        label: getLabelTranslation('confirm'),
        action: () => {
          updateRecommendationStatus(id);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('cancel'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  useEffect(() => {
    getPendingList({
      column: orderColumn.column,
      orderDesc: orderColumn.orderDesc,
    });
  }, [orderColumn]);

  return (
    <>
      {(loading || isLoading) && (
        <GDMContainer
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw',
            height: '100%',
            position: 'absolute',
            paddingTop: '1px',
            top: 0,
            left: 0,
            background: 'rgba(0,0,0,0.2)',
            zIndex: 9999,
          }}
        >
          <GDMContainer sx={{ paddingTop: '15vh' }}>
            <GDMLoading />
          </GDMContainer>
        </GDMContainer>
      )}
      <GDMContainer
        component="div"
        sx={containerStyle}
        {...{ noValidate: true, autoComplete: 'off' }}
      >
        <GDMTypography
          variant="h4"
          sx={{
            fontWeight: '700',
            color: '#222',
            marginBottom: 0,
            fontSize: '32px',
            textAlign: 'start',
          }}
        >
          {getLabelTranslation('pending_actions_title')}
        </GDMTypography>
        <GDMTypography
          variant="body1"
          sx={{
            color: '#222',
            fontSize: '18px !important',
            textAlign: 'start',
          }}
        >
          {getLabelTranslation('pending_actions_subtitle')}
          <strong>{getLabelTranslation('pending_actions_subtitle_bold')}</strong>
        </GDMTypography>

        <GDMContainer
          sx={{
            width: '100%',
            background: '#F2F4F9',
            display: 'flex',
            borderRadius: '8px 8px 0 0',
          }}
        >
          {pendingPlanting.length > 0 && (
            <GDMGrid
              item
              sm={6}
              md={8}
              sx={activeTab === SelectedTab.PLANTING ? flapStyleActive : flapStyleDisabled}
              onClick={() => {
                handleChangeFlap(SelectedTab.PLANTING);
              }}
            >
              <TbNotes size={25} />
              <GDMTypography
                sx={{ fontSize: '16px', fontWeight: isDesktop ? 500 : 700, color: '#222' }}
              >
                {getLabelTranslation('planting_tab')}
              </GDMTypography>
              <GDMTypography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '18px',
                  width: '18px',
                  background: 'red',
                  borderRadius: '50%',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: '#fff',
                }}
              >
                {pendingPlanting.length}
              </GDMTypography>
            </GDMGrid>
          )}
          {pendingHarvest.length > 0 && (
            <GDMGrid
              item
              sm={6}
              md={8}
              sx={activeTab === SelectedTab.HARVEST ? flapStyleActive : flapStyleDisabled}
              onClick={() => {
                handleChangeFlap(SelectedTab.HARVEST);
              }}
            >
              <TbBuildingCottage size={25} />
              <GDMTypography sx={{ fontSize: '16px', color: '#222' }}>
                {getLabelTranslation('harvest_tab')}
              </GDMTypography>
              <GDMTypography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '18px',
                  width: '18px',
                  background: 'red',
                  borderRadius: '50%',
                  fontSize: '10px',
                  fontWeight: '700',
                  color: '#fff',
                }}
              >
                {pendingHarvest.length}
              </GDMTypography>
            </GDMGrid>
          )}
        </GDMContainer>
        <GDMContainer
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            gap: isDesktop ? '0' : '16px',
            paddingLeft: isDesktop ? '16px' : 0,
            alignItems: 'center',
          }}
        >
          <GDMTypography
            sx={{
              width: isDesktop ? '25%' : '100%',
              fontSize: '16px',
              lineHeight: isDesktop ? '22.4px' : '18px',
              textAlign: 'left',
            }}
          >
            {getLabelTranslation('select_recommendation_status_label')}
          </GDMTypography>
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: isDesktop ? 'row' : 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: isDesktop ? '24px' : '16px',
              flex: 1,
              width: isDesktop ? 'auto' : '100%',
            }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                visibility: selectedsIds.length > 0 ? 'visible' : 'hidden',
                height: '100%',
                width: isDesktop ? 'fit-content' : '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <GDMTypography sx={{ fontSize: '16px', color: '#777D88' }}>
                {selectedsIds.length}
                {getLabelTranslation('selecteds')}
              </GDMTypography>
              <GDMButton
                variant="text"
                onClick={() => setSelectedsIds([])}
                sx={{
                  height: isDesktop ? 'auto' : '20px',
                  textDecoration: 'underline',
                  color: theme.palette.info.main,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {getLabelTranslation('uncheck_all')}
              </GDMButton>
            </GDMContainer>
            <GDMButton
              variant="contained"
              color="primary"
              disabled={selectedsIds.length === 0}
              sx={{
                width: isDesktop ? '230px' : '100%',
                fontSize: '14px',
                color: '#fafafa !important',
                padding: '12px 24px',
                whiteSpace: 'nowrap',
                '&:disabled': {
                  background: '#a1a9b8',
                },
              }}
              onClick={() => {
                handleUpdateButton(STATUS_PLANTED);
              }}
            >
              {activeTab === 'PLANTING' ? getLabelTranslation('confirm_as_planted'): getLabelTranslation('confirm_as_harvested') }
            </GDMButton>
            <GDMButton
              variant="contained"
              color="secondary"
              disabled={selectedsIds.length === 0}
              sx={{
                width: isDesktop ? '230px' : '100% !important',
                fontSize: '14px',
                color: selectedsIds.length === 0 ? '#fafafa !important' : '#222 !important',
                padding: '12px 12px',
                '&:hover': {
                  background: theme.palette.primary.light,
                },
                '&:disabled': {
                  background: '#a1a9b8',
                },
              }}
              onClick={() => {
                handleUpdateButton(STATUS_INTERRUPTED);
              }}
            >
              {getLabelTranslation('confirm_as_interrupted')}
            </GDMButton>
          </GDMContainer>
        </GDMContainer>
        {!isLoading && (
          <GDMContainer>
            <GDMTableContainer sx={{ position: 'relative' }}>
              <PendingListTable
                rows={rows}
                setSelectedsIds={setSelectedsIds}
                selectedsIds={selectedsIds}
                orderColumn={orderColumn}
                setOrderColumn={setOrderColumn}
                showPlantingDate={activeTab === SelectedTab.PLANTING}
              />
              <GDMTypography
                sx={{
                  marginTop: '16px',
                  fontSize: '14px',
                  color: '#777D88',
                }}
              >
                {getLabelTranslation('table_footer_info')}
              </GDMTypography>
              {rows && rows.length === 0 && (
                <GDMTypography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '32px',
                    paddingBottom: '32px',
                    color: '#222',
                  }}
                >
                  {getLabelTranslation('no_pending_list')}
                </GDMTypography>
              )}
            </GDMTableContainer>
          </GDMContainer>
        )}
      </GDMContainer>
    </>
  );
}
