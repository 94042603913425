import { useMediaQuery, useTheme } from '@mui/material';
import { GDMCustomInputLabel } from 'components/gdm-custom-input-label';
import { GDMDocumentMask } from 'components/gdm-document-mask';
import { GDMFormControl } from 'components/gdm-form-control';
import { GDMFormHelperText } from 'components/gdm-form-helper-text';
import { GDMGrid } from 'components/gdm-grid';
import { GDMMenuItem } from 'components/gdm-menu-item';
import { GDMPhoneMask } from 'components/gdm-phone-mask';
import { GDMSelect } from 'components/gdm-select';
import { GDMTextField } from 'components/gdm-text-field';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { IoIosArrowDown } from 'react-icons/io';

const GDMFarmerRegisterForm = ({
  formData,
  errors,
  handleChangeValues,
  countries,
  user,
  handleStateChange,
  states,
  cities,
  handleCityChange,
  viewType,
}) => {
  const theme = useTheme();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
 

  return (
    <>
      <GDMGrid item xs={12} sm={12} md={6} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('formfields_full_name')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          <GDMTextField
            required={viewType !== 'view'}
            disabled={viewType === 'view'}
            sx={viewType === 'view' && { background: '#F2F4F9' }}
            focused={false}
            id="name"
            variant="outlined"
            placeholder={getLabelTranslation('formfields_full_name')}
            fullWidth
            value={formData.name}
            name="name"
            onChange={handleChangeValues}
          />
          {!!errors.name && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.name}</GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>
      {/* EMAIL */}
      <GDMGrid item xs={12} sm={12} md={6} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('formfields_email')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          <GDMTextField
            sx={viewType === 'view' && { background: '#F2F4F9' }}
            focused={false}
            required
            disabled={viewType === 'view'}
            id="contactEmail"
            name="contactEmail"
            placeholder={getLabelTranslation('formfields_email')}
            variant="outlined"
            fullWidth
            value={formData.contactEmail}
            onChange={handleChangeValues}
          />

          {!!errors.contactEmail && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.contactEmail}</GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>

      {/* PAÍS */}
      <GDMGrid item xs={12} sm={12} md={3} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('formfields_country')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          {viewType === 'view' || viewType === 'edit' ? (
            <GDMTextField
              focused={false}
              sx={{
                background: '#F2F4F9' ,
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                color: '#A1A9B8',
              }}
              required
              disabled
              id="countryName"
              placeholder={getLabelTranslation('formfields_country')}
              name="countryName"
              variant="outlined"
              fullWidth
              value={formData.countryName}
              onChange={handleChangeValues}
            />
          ) : (
            <GDMSelect
              sx={{
                background: '#F2F4F9',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                color: '#A1A9B8',
              }}
              IconComponent={IoIosArrowDown}
              placeholder={getLabelTranslation('formfields_country')}
              value={formData.countryId}
              variant="outlined"
              fullWidth
              disabled
            >
              {countries.map(ctry => (
                <GDMMenuItem key={ctry.id} value={ctry.id}>
                  {ctry.name}
                </GDMMenuItem>
              ))}
            </GDMSelect>
          )}
        </GDMFormControl>
      </GDMGrid>

      {/* TELEFONE */}
      <GDMGrid item xs={12} sm={12} md={3} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('formfields_phone')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          <GDMPhoneMask
            country={user.countryName}
            value={formData.phoneNumber}
            onChange={handleChangeValues}
            disabled={viewType === 'view'}
            sx={viewType === 'view' && { background: '#F2F4F9' }}
          >
            <GDMTextField
              focused={false}
              id="phoneNumber"
              name="phoneNumber"
              placeholder={getLabelTranslation('formfields_phone')}
              variant="outlined"
              fullWidth
            />
          </GDMPhoneMask>

          {!!errors.phoneNumber && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.phoneNumber}</GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>

      {/* DOCUMENT */}
      <GDMGrid item xs={12} sm={12} md={3} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={
            user.countryName === CountriesDomainEnum.Brasil
              ? getLabelTranslation('formfields_cpf')
              : getLabelTranslation('formfields_cuit')
          }
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          <GDMDocumentMask
            country={user.countryName}
            value={formData.documentNumber}
            onChange={handleChangeValues}
            disabled={viewType === 'view'}
            sx={viewType === 'view' && { background: '#F2F4F9' }}
          >
            <GDMTextField
              id="documentNumber"
              name="documentNumber"
              focused={false}
              placeholder={
                user.countryName === CountriesDomainEnum.Brasil
                  ? getLabelTranslation('formfields_cpf')
                  : getLabelTranslation('formfields_cuit')
              }
              variant="outlined"
              fullWidth
            />
          </GDMDocumentMask>

          {!!errors.documentNumber && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.documentNumber}</GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>

      {/* ENDEREÇO */}
      <GDMGrid item xs={12} sm={12} md={4} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('address')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          <GDMTextField
            focused={false}
            sx={{
              background: viewType === 'view' ? '#F2F4F9' : 'transparent',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              color: '#A1A9B8',
            }}
            required
            disabled={viewType === 'view'}
            id="address"
            placeholder={getLabelTranslation('address')}
            name="address"
            variant="outlined"
            fullWidth
            value={formData.address}
            onChange={handleChangeValues}
          />

          {!!errors.address && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.address}</GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>

      {/* ESTADO */}
      <GDMGrid item xs={12} sm={12} md={2} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('formfields_state')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          {viewType === 'view' ? (
            <GDMTextField
              focused={false}
              sx={{
                background: '#F2F4F9' ,
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                color: '#A1A9B8',
              }}
              required
              disabled={viewType === 'view'}
              id="uf"
              placeholder={getLabelTranslation('formfields_state')}
              name="uf"
              variant="outlined"
              fullWidth
              value={formData.stateName}
              onChange={handleChangeValues}
            />
          ) : (
            <GDMSelect
              sx={{
                background: 'transparent',
                fontSize: '14px',
                marginTop: '5px',
                height: '48px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                color: '#A1A9B8',
                '.MuiOutlinedInput-notchedOutline	': {
                  legend: {
                    display: 'none',
                  },
                },
              }}
              IconComponent={IoIosArrowDown}
              value={formData.stateId}
              onChange={handleStateChange}
              variant="outlined"
              disabled={viewType === 'view'}
              fullWidth
            >
              <GDMMenuItem value="0">{getLabelTranslation('formfields_state')}</GDMMenuItem>
              {states.map(state => (
                <GDMMenuItem key={state.id} value={state.id}>
                  {state.name}
                </GDMMenuItem>
              ))}
            </GDMSelect>
          )}

          {!!errors.ufId && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.ufId}</GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>

      {/* CIDADE */}
      <GDMGrid item xs={12} sm={12} md={3} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('city_name')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        {viewType === 'view' ? (
          <GDMTextField
            focused={false}
            sx={{
              background: '#F2F4F9',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              color: '#A1A9B8',
            }}
            required
            disabled={viewType === 'view'}
            id="city"
            placeholder={getLabelTranslation('formfields_correction_city')}
            name="city"
            variant="outlined"
            fullWidth
            value={formData.cityName}
            onChange={handleChangeValues}
          />
        ) : (
          <GDMSelect
            sx={{
              background:  'transparent',
              fontSize: '14px',
              marginTop: '5px',
              height: '48px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              color: '#A1A9B8',
              '.MuiOutlinedInput-notchedOutline	': {
                legend: {
                  display: 'none',
                },
              },
            }}
            IconComponent={IoIosArrowDown}
            value={formData.cityId !== 0 ? formData.cityId : 0}
            onChange={handleCityChange}
            variant="outlined"
            disabled={viewType === 'view'}
            fullWidth
          >
            <GDMMenuItem value="0">{getLabelTranslation('formfields_correction_city')}</GDMMenuItem>
            {cities.map(city => (
              <GDMMenuItem key={city.id} value={city.id}>
                {city.name}
              </GDMMenuItem>
            ))}
          </GDMSelect>
        )}

        {errors.cityId && (
          <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.cityId}</GDMFormHelperText>
        )}
      </GDMGrid>

      {/* AREA PLANTADA */}
      <GDMGrid item xs={12} sm={12} md={3} sx={!isDesktop && { textAlign: 'start' }}>
        <GDMCustomInputLabel
          label={getLabelTranslation('total_planted_area')}
          required={viewType !== 'view'}
          fontSize="16px"
          color="#222222"
        />
        <GDMFormControl fullWidth>
          <GDMTextField
            required
            type="number"
            focused={false}
            id="name"
            variant="outlined"
            placeholder={getLabelTranslation('total_planted_area')}
            fullWidth
            value={formData.totalPlantedArea}
            onChange={handleChangeValues}
            name="totalPlantedArea"
            disabled={viewType === 'view'}
            sx={viewType === 'view' && { background: '#F2F4F9' }}
            inputProps={{ min: 0 }}
            onKeyDown={e => {
              if (e.key === '-' || e.key === 'e') {
                e.preventDefault();
              }
            }}
          />

          {!!errors.totalPlantedArea && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>
              {errors.totalPlantedArea}
            </GDMFormHelperText>
          )}
        </GDMFormControl>
      </GDMGrid>
    </>
  );
};

export default GDMFarmerRegisterForm;
