import { DistributorService } from 'core/api/services/distributor';
import { OnSubmitDistributorFunction } from './onSubmitDistributor.type';

const onSubmitDistributor: OnSubmitDistributorFunction = async ({
  distributor,
  onInit,
  onError,
  onFinally,
  onSuccess,
  handleValidate,
  onValidate,
  method = 'post',
  id,
}) => {
  if (!!onInit) onInit();

  let errors: ObjectLiteral<string> = {};

  if (!!handleValidate) {
    errors = handleValidate(distributor);
  }

  if (!!onValidate) onValidate(errors);

  if (handleValidate && Object.keys(errors)?.length) return;

  const distributorFormData = new FormData();

  distributorFormData.append('name', distributor.name);
  distributorFormData.append('fantasyName', distributor.fantasyName);
  distributorFormData.append('PersonInChargeName', distributor.personInChargeName);
  distributorFormData.append('contactEmail', distributor.contactEmail);
  distributorFormData.append('phoneNumber', distributor.phoneNumber);
  distributorFormData.append('documentTypeId', distributor.documentTypeId);
  distributorFormData.append('documentNumber', distributor.documentNumber.replace('_', ''));
  distributorFormData.append('cityId', distributor.cityId);
  distributorFormData.append('sellerGdmUserId', distributor.sellerId || '');
  distributorFormData.append('brandIds', distributor.brandIds as any);
  distributorFormData.append('address', distributor.address);
  distributorFormData.append('multiplierId', distributor.multiplierId)

  if (distributor.logoFile) {
    distributorFormData.append('logoFile', distributor.logoFile);
  }

  try {
    const response = await DistributorService.upSertDistributor(method, distributorFormData, id);

    if (!!onSuccess) onSuccess(response);
  } catch (error) {
    if (!!onError) onError(error);
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default onSubmitDistributor;
