import { LanguageKeysService } from 'core/api/services/languageKeys';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GDMContainer, GDMLoading } from 'components';
import { useAuth } from 'core/hooks/useAuth';
import { useStore } from 'effector-react';
import UserProfileStore from '../stores/UserProfileStore/UserProfileStore';

import {
  Language,
  LanguageContextProviderProps,
  LanguageContextType,
} from './languageContext.type';
import getGroupByRoute from './utils/get-group-by-route/getGroupByRoute';
import { loadUserProfileDone } from '../stores';

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({ children }) => {
  const { isInitialized } = useAuth();
  const { user } = useStore(UserProfileStore);

  const [loading, setLoading] = useState<boolean>(true);
  const [translationsDictionary, setTranslationsDictionary] = useState<any>({});
  const [pathName, setPathName] = useState(window?.location?.pathname || '');
  const [routeGroup, setRouteGroup] = useState('/');

  useEffect(() => {
    window.addEventListener('changeRoute', (e: CustomEvent) => {
      if (pathName !== e.detail.pathname) setPathName(e.detail.pathname);
    });
  }, []);

  useEffect(() => {
    setRouteGroup(getGroupByRoute(pathName));
  }, [pathName]);

  useEffect(() => {
    loadDictionary(user?.languageIso);
  }, [user?.languageIso]);

  const getLabelTranslationFallback = (label: string) => {
    const _routeGroup = getGroupByRoute(window?.location?.pathname);

    return translationsDictionary?.[_routeGroup]?.[label] || label;
  };

  const getLabelTranslation = useCallback(
    (label: string, fallback = getLabelTranslationFallback) => {
      const resultTranslation = translationsDictionary?.[routeGroup]?.[label] || label;

      if (routeGroup !== '/' && resultTranslation === label) {
        const resultTranslationGeneral = translationsDictionary?.['/']?.[label] || label;

        if (resultTranslation === label) return fallback(resultTranslationGeneral);
      } else if (resultTranslation === label) return fallback(label);

      return resultTranslation;
    },
    [translationsDictionary, routeGroup],
  );

  const getTranslations = async (newLanguage: Language) => {
    if (!loading) setLoading(true);

    const sessionStorageCached = JSON.parse(
      sessionStorage.getItem('translationsDictionary') || '{}',
    );

    const languageId =
      user?.languageIso === 'pt-BR'
        ? '56d656e4-42e4-41c0-8b69-7f3eb0a894ab'
        : user?.languageIso === 'es-AR'
        ? '5ca0663b-026f-48c7-b83d-a88b61a77eb6'
        : undefined;

    if (sessionStorageCached?.[languageId]) {
      setTranslationsDictionary(sessionStorageCached[languageId]);
      setLoading(false);
    } else {
      try {
        const translationsResponse = await LanguageKeysService.getAllLanguageKeys(newLanguage);

        const _translationsDictionary = translationsResponse?.languageKeys?.[languageId];

        if (translationsDictionary !== _translationsDictionary)
          setTranslationsDictionary(_translationsDictionary);

        sessionStorage.setItem(
          'translationsDictionary',
          JSON.stringify({
            ...sessionStorageCached,
            ...(translationsResponse?.languageKeys || {}),
          }),
        );
      } catch (error) {
        console.error('Error fetching language keys:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const loadDictionary = async newLanguage => {
    await getTranslations(newLanguage);
  };

  const switchLanguage = async (newLanguage: Language) => {
    loadUserProfileDone({ ...user, languageIso: newLanguage });
  };

  const loadTranslations = () => {
    setLoading(true);

    const translationsDictionaryCached = JSON.parse(
      sessionStorage.getItem('translationsDictionary') || '{}',
    );

    const { languageIso } = user

    if (!!translationsDictionaryCached) {
      const routeTranslationsDictionaryCached =
        translationsDictionaryCached?.[user?.languageIso as Language]?.[routeGroup];

      if (!!routeTranslationsDictionaryCached) {
        setTranslationsDictionary(routeTranslationsDictionaryCached);
        setLoading(false);
      } else {
        getTranslations(languageIso as Language);
      }
    } else {
      getTranslations(languageIso as Language);
    }
  };

  useEffect(() => {
    if (!!isInitialized) loadTranslations();
  }, [isInitialized]);

  const contextValue = useMemo(
    () => ({
      currentLanguage: user?.languageIso as Language,
      switchLanguage,
      translationsDictionary,
      getLabelTranslation,
      loading,
    }),
    [user, loading, translationsDictionary, getLabelTranslation],
  );

  if (loading && !Object.keys(translationsDictionary)?.length && isInitialized) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading />
      </GDMContainer>
    );
  }

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageContext must be used within a LanguageContextProvider');
  }
  return context;
};
