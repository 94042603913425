import { BrandsServices } from 'core/api/services/brands';
import { GetBrandsFunction } from './getBrands.type';

const getBrands: GetBrandsFunction = async ({ onInit, onSuccess, onError, onFinally }) => {
  if (!!onInit) onInit();
  try {
    const states = await BrandsServices.getBrands();

    if (!!onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (!!onError) onError(error);

    return [];
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default getBrands;
