import { CountriesDomainEnum } from "core/typing/domains/CountriesDomain/CountriesDomain";
import { cnpj, cpf } from "cpf-cnpj-validator";
import cuitValidator from 'cuit-validator';


export const validateDocument = (document: string, country: string) => {
    const cleaned = document.replace(/\D/g, '');
    if (country === CountriesDomainEnum.Brasil) {
      if (cleaned.length === 11) {
        return cpf.isValid(cleaned);
      }
      if (cleaned.length < 1 && cleaned.length < 11) {
        return true;
      }
      if (cleaned.length === 14) return cnpj.isValid(cleaned);
    } else if (country === CountriesDomainEnum.Argentina) {
      if (cleaned.length === 0) {
        return true;
      }
      return cuitValidator(cleaned);
    }
    return false;
  };
  
  export const validatePhone = (phone: string, country: string) => {
    const phoneValidation = {
      BrasilFixedLinePhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{4})$/,
      BrasilCellPhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(9\d{4})-?(\d{4})$/,
      Argentina: /^[0-9()\s\-+]+$/, // Permitir + e pelo menos 10 caracteres
    };
  
    if (country === CountriesDomainEnum.Argentina) {
      return phoneValidation.Argentina.test(phone);
    }
  
    if (country === CountriesDomainEnum.Brasil) {
      let numericPhone = phone;
      numericPhone = numericPhone.replace(/\D/g, '');
      if (numericPhone.length >= 12 && numericPhone.length <= 13) {
        return phoneValidation.BrasilCellPhone.test(phone);
      }
      if (numericPhone.length === 12) {
        return phoneValidation.BrasilFixedLinePhone.test(phone);
      }
      return false; // Número inválido se não tiver o comprimento esperado
    }
  
    return false; // Valor padrão se o país não for Brasil ou Argentina
  };
  
  export const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  