import { useCallback, useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { IoAddOutline, IoArrowBack, IoEyeOutline } from 'react-icons/io5';
import { LuTrash2 } from 'react-icons/lu';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import { FarmerService } from 'core/api/services/farmer';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import {
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Modal,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  debounce,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import GDMTableDesktop from 'components/gdm-table-desktop/GDMTableDesktop';
import { GDMTableFilter } from 'components/gdm-table-filter';
import GDMTableMobile from 'components/gdm-table-mobile/GDMTableMobile';

import useModal from 'hooks/useModal/useModal';

import { GDMButton, GDMContainer, GDMTypography } from 'components';
import { useNavigate } from 'react-router-dom';
import { stringTruncate } from 'utils/formaters';
import colors from '../../core/design-system/style-guide/colors.json';
import { containerStyle } from './FarmerList.style';

export default function FarmerList() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(null);
  const [idToDelete, setIdToDelete] = useState('');
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [orderDesc, setOrderDesc] = useState(false);
  const [orderColumn, setOrderColumn] = useState('');
  const [search, setSearch] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [openFarmsModal, setOpenFarmsModal] = useState(false);
  const [farms] = useState([]);
  const [searchColumn, setSearchColumn] = useState<string>('none' as string);
  const { getLabelTranslation } = useLanguageSwitcher();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const [emptyMessage, setEmptyMessage] = useState<string | undefined>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const columns: { column_name: string; column_title: string; type: string }[] = [
    {
      column_name: 'Name',
      column_title: getLabelTranslation('name'),
      type: 'text',
    },
    {
      column_name: 'StateName',
      column_title: getLabelTranslation('state_name'),
      type: 'text',
    },
    {
      column_name: 'CityName',
      column_title: getLabelTranslation('city_name'),
      type: 'text',
    },
    { column_name: 'FarmsQuantity', column_title: getLabelTranslation('farm'), type: 'text' },
    {
      column_name: 'TotalPlantedArea',
      column_title: getLabelTranslation('total_planted_area'),
      type: 'text',
    },
  ];

  useEffect(() => {
    if (!loadingPage && !rows?.length) {
      const isSearching = !!search;

      if (hasError) setEmptyMessage('error_on_load_table_data_text');
      else if (isSearching) setEmptyMessage('empty_table_on_filter_text');
      else setEmptyMessage('empty_table_text');
    }
  }, [rows, loadingPage, search, hasError]);

  const columnTitle: { column_name: string; column_title: string; type: string }[] = [
    { column_name: 'all', column_title: getLabelTranslation('all'), type: 'text' },
    {
      column_name: 'Name',
      column_title: getLabelTranslation('name'),
      type: 'text',
    },
    {
      column_name: 'StateName',
      column_title: getLabelTranslation('state_name'),
      type: 'text',
    },
    {
      column_name: 'CityName',
      column_title: getLabelTranslation('city_name'),
      type: 'text',
    },
    {
      column_name: 'FarmsQuantity',
      column_title: getLabelTranslation('farm'),
      type: 'text',
    },
    {
      column_name: 'TotalPlantedArea',
      column_title: getLabelTranslation('total_planted_area'),
      type: 'text',
    },
  ];

  const changePage = page => {
    setCurrentPage(page);
  };
  const changeOrder = () => {
    setOrderDesc(!orderDesc);
    setCurrentPage(1);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((query: any, columnName: string) => {
      setSearchColumn(columnName);
      if (query !== null && query !== '') {
        setSearch(query);
      } else {
        setSearch('');
      }
      setCurrentPage(1);
    }, 1000),
    [],
  );

  const handleClick = id => {
    setOpen(open === id ? null : id);
  };

  const loadFarmers = async () => {
    try {
      const response = await FarmerService.getFarmersList({
        currentPage,
        itemsPerPage,
        search,
        searchColumn,
        orderDesc,
        orderColumn,
      });

      setHasError(false);
      setRows(response.items);
      setPages(response.totalPages);
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    loadFarmers();
  }, [currentPage, itemsPerPage, search, searchColumn, orderDesc, orderColumn]);

  if (loadingPage) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <CircularProgress />
      </GDMContainer>
    );
  }
  return (
    <GDMContainer sx={containerStyle(rows.length === 0)}>
      <GDMContainer>
        <Typography
          sx={{ fontWeight: '400', fontSize: '14px', color: '#A1A9B8' }}
        >{`${getLabelTranslation('see_register')} > ${getLabelTranslation('farmer')}`}</Typography>
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media (max-width: 900px)': { flexDirection: 'column', gap: '16px' },
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: '700' }}>
          {getLabelTranslation('view_farmer_registration')}
        </Typography>
        <GDMTableFilter handleSearch={handleSearch} columns={columnTitle} />
      </GDMContainer>
      <GDMContainer sx={{ marginTop: isDesktop ? '24px' : 0 }}>
        {isDesktop ? (
          <GDMTableDesktop
            emptyMessage={emptyMessage}
            pages={pages}
            setOrderColumn={setOrderColumn}
            currentPage={currentPage}
            changePage={changePage}
            columns={columns}
            changeOrder={changeOrder}
            isFarmerRegister
            rows={rows.map(row => (
              <TableRow sx={{ background: idToDelete === row.id ? '#CFEFFF' : '#FAFAFA' }}>
                <TableCell>
                  <Tooltip title={row.name}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: '14px',
                        lineHeight: '19.6px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '30ch',
                      }}
                    >
                      {stringTruncate(row.name, 50)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '19.6px',
                      textWrap: 'nowrap',
                    }}
                  >
                    {row.stateName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" sx={{ fontSize: '14px', lineHeight: '19.6px' }}>
                    {row.cityName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '19.6px',
                      color: '#0063F7',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '16px',
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        navigate(`/farmer-form-register/${row.id}/farm-flap`);
                      }}
                      sx={{
                        fontSize: '16px',
                        lineHeight: '19.6px',
                        color: '#0063F7',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                      }}
                    >
                      {row.farmsQuantity === 0 ? 0 : row.farmsQuantity}
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        navigate(`/farmer-register/${row.id}/farm-step`);
                      }}
                      sx={{
                        background: '#0A3C56',
                        borderRadius: '4px',
                        color: '#fff',
                        height: '24px',
                        width: '24px',
                        padding: 0,
                        '&:hover': { background: 'rgba(10, 60, 86, 0.8)' },
                      }}
                    >
                      <IoAddOutline color="#fff" size={16} />
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title={row.totalPlantedArea}>
                    <Typography variant="subtitle1" sx={{ fontSize: '14px', lineHeight: '19.6px' }}>
                      {stringTruncate(row.totalPlantedArea, 15)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      navigate(`/farmer-form-register/${row.id}/view`);
                    }}
                  >
                    <IoEyeOutline style={{ fontSize: '24px' }} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      navigate(`/farmer-form-register/${row.id}/edit`);
                    }}
                  >
                    <FiEdit style={{ fontSize: '22px' }} />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setIdToDelete(row.id);
                    }}
                  >
                    <DeleteConfirmationModal
                      setDeleteId={setIdToDelete}
                      id={row.id}
                      canBeDeleted={row.canBeDeleted}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      setPages={setPages}
                      setRows={setRows}
                      orderDesc={orderDesc}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          />
        ) : (
          <GDMTableMobile
            changePage={changePage}
            currentPage={currentPage}
            pages={pages}
            emptyMessage={emptyMessage}
            rows={rows.map(item => (
              <GDMContainer
                key={item.id}
                sx={{ background: idToDelete === item.id ? '#CFEFFF' : 'transparent' }}
              >
                <ListItemButton onClick={() => handleClick(item.id)} sx={{ pl: 0, pr: 0 }}>
                  <ListItem sx={{ pl: 0 }}>
                    <Typography variant="body2">{getLabelTranslation('name')} </Typography>
                    <Typography
                      variant="body1"
                      ml={1}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '20ch',
                      }}
                    >{` ${item.name}`}</Typography>
                  </ListItem>
                  <IconButton
                    sx={{
                      color: '#222',
                      width: '32px',
                      height: '32px',
                      backgroundColor: '#CFEFFF',
                      borderRadius: '50%',
                    }}
                  >
                    {open === item.id ? <MdExpandLess /> : <MdExpandMore />}
                  </IconButton>
                </ListItemButton>
                <Collapse
                  in={open === item.id}
                  timeout="auto"
                  unmountOnExit
                  sx={{ background: 'transparent' }}
                >
                  <List component="div" disablePadding>
                    <ListItem sx={{ pl: 0 }}>
                      <Typography variant="body2">{getLabelTranslation('state_name')} </Typography>
                      <Typography variant="body1" ml={1}>{` ${item.stateName}`}</Typography>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                      <Typography variant="body2">{getLabelTranslation('city_name')} </Typography>
                      <Typography variant="body1" ml={1}>{` ${item.cityName}`}</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', gap: '8px', pl: 0, pr: 0 }}>
                      <Typography variant="body2">{getLabelTranslation('farm')} </Typography>
                      <Typography
                        variant="body1"
                        ml={1}
                        onClick={() => {
                          navigate(`/farmer-form-register/${item.id}/farm-flap`);
                        }}
                        sx={{
                          fontSize: '16px',
                          lineHeight: '19.6px',
                          color: '#0063F7',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        {item.farmsQuantity === 0 ? 0 : item.farmsQuantity}
                      </Typography>
                      <button
                        onClick={() => {
                          navigate(`/farmer-register/${item.id}/farm-step`);
                        }}
                        aria-label="farm"
                        type="button"
                        style={{
                          background: '#0A3C56',
                          borderRadius: '4px',
                          color: '#fff',
                          border: 'none',
                          fontSize: '22px',
                          display: 'flex',
                        }}
                      >
                        <IoAddOutline color="#fff" />
                      </button>
                    </ListItem>
                    <ListItem sx={{ pl: 0 }}>
                      <Typography variant="body2">
                        {getLabelTranslation('total_planted_area')}{' '}
                      </Typography>
                      <Typography variant="body1" ml={1}>{` ${item.totalPlantedArea}`}</Typography>
                    </ListItem>
                    <ListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body2">{getLabelTranslation('actions')}</Typography>
                      <GDMContainer sx={{ display: 'flex' }}>
                        <ListItemIcon
                          onClick={() => {
                            navigate(`/farmer-form-register/${item.id}/view`);
                          }}
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            fontSize: '24px',
                          }}
                        >
                          <IoEyeOutline />
                        </ListItemIcon>
                        <ListItemIcon
                          onClick={() => {
                            navigate(`/farmer-form-register/${item.id}/edit`);
                          }}
                          sx={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            fontSize: '22px',
                          }}
                        >
                          <FiEdit />
                        </ListItemIcon>
                        <ListItemIcon
                          sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                          onClick={() => {
                            setIdToDelete(item.id);
                          }}
                        >
                          <DeleteConfirmationModal
                            id={item.id}
                            canBeDeleted={item.canBeDeleted}
                            setDeleteId={setIdToDelete}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            setPages={setPages}
                            setRows={setRows}
                            orderDesc={orderDesc}
                          />
                        </ListItemIcon>
                      </GDMContainer>
                    </ListItem>
                  </List>
                </Collapse>
                <Divider />
              </GDMContainer>
            ))}
          />
        )}
      </GDMContainer>
      {loadingPage === false && rows.length === 0 && (
        <GDMContainer
          sx={{
            display: 'flex',
            justifyContent: 'start',
            marginTop: 'auto',
            '@media (max-width: 900px)': {
              button: { width: '100%' },
            },
          }}
        >
          <GDMButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: isDesktop ? '187px' : '100%',
              color: '#222',
              gap: '8px',
            }}
            variant="outlined"
          >
            <IoArrowBack color="#222" />
            {getLabelTranslation('back')}
          </GDMButton>
        </GDMContainer>
      )}
      <Modal
        open={openFarmsModal}
        onClose={() => setOpenFarmsModal(false)}
        sx={{
          top: '4%',
          margin: 'auto',
          display: 'flex',
          borderRadius: '16px',
          width: 'fit-content',
          height: 'fit-content',
          justifyContent: 'center',
        }}
      >
        <GDMContainer
          sx={{
            background: 'white',
            width: '500px',
            maxHeight: '500px',
            borderRadius: '16px',
            padding: '32px',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <GDMTypography variant="h4" sx={{ fontWeight: 700 }}>
            {getLabelTranslation('farms')}
          </GDMTypography>
          {farms.map(farm => (
            <GDMTypography
              sx={{
                background: '#FAFAFA',
                borderRadius: '8px',
                padding: '8px',
                cursor: 'pointer',
                transition: '0.3s ease-in-out',
                '&:hover': { background: '#C1C1C1' },
                marginTop: '12px',
              }}
              onClick={() => {
                navigate(`/farmer-farm-register/${farm.id}`);
              }}
            >
              {farm.name}
            </GDMTypography>
          ))}
        </GDMContainer>
      </Modal>
    </GDMContainer>
  );
}

function DeleteConfirmationModal({
  id,
  currentPage,
  itemsPerPage,
  setRows,
  setPages,
  orderDesc,
  setDeleteId,
  canBeDeleted,
}) {
  const { getLabelTranslation } = useLanguageSwitcher();
  const { openModal, closeModal } = useModal();

  const handleClickOpen = () => {
    openModal({
      type: 'delete',
      title: getLabelTranslation('modal_confirm_question'),
      message: getLabelTranslation('modal_alert'),
      primaryAction: {
        label: getLabelTranslation('delete'),
        action: handleDelete,
      },
      secondAction: {
        label: getLabelTranslation('cancel'),
        action: handleClose,
      },
    });
  };

  const handleClose = () => {
    closeModal();
    setDeleteId('');
  };
  const handleCloseModalFeedback = () => {
    const searchColumn = 'none';
    const search = '';
    FarmerService.getFarmersList({
      currentPage,
      itemsPerPage,
      searchColumn,
      search,
      orderDesc,
    }).then(response => {
      setRows(response.items);
      setPages(response.totalPages);
    });
  };

  const handleDelete = async () => {
    if (canBeDeleted) {
      FarmerService.deleteFarmer({ id })
        .then(() => {
          openModal({
            type: 'success',
            title: getLabelTranslation('modal_tittle_delete_success'),
            onClose: handleCloseModalFeedback,
          });
        })
        .catch(() => {
          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
        });
    } else {
      openModal({
        type: 'error',
        title: getLabelTranslation('error_on_delete_register'),
        message: getLabelTranslation('error_on_delete'),
        onClose: closeModal,
      });
    }
  };

  return <LuTrash2 onClick={handleClickOpen} color={colors.dark1} style={{ fontSize: '22px' }} />;
}
