import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { useMediaQuery, useTheme } from '@mui/material';
import GDMCircularProgress from 'components/gdm-circular-progress/GDMCircularProgress';

import {
  GDMButton,
  GDMContainer,
  GDMDivider,
  GDMGrid,
  GDMLoading,
  GDMTypography,
} from 'components';

import { GDMStepper } from 'components/gdm-stepper';
import { FarmerService } from 'core/api/services/farmer';
import { LocationsService } from 'core/api/services/locations';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { errorHandler } from 'utils/form/errorHandler';

import GDMFarmerRegisterForm from 'components/gdm-farmer-register-form/GDMFarmerRegisterForm';

import { GDMFarmRegisterForm } from 'components/gdm-farm-register-form';
import { GDMLotRegisterForm } from 'components/gdm-lot-register-form';
import { FarmService } from 'core/api/services/farm';
import { LotsService } from 'core/api/services/lot';

import { IFarmerFormData, IFarmFormData, IFormData, ILotFormData } from './FarmerRegister.type';
import { containerStyle } from './FarmerRegisterstyles';
import { validateDocument, validateEmail, validatePhone } from './utils';

const initialStateFormDataFarmer = {
  name: '',
  contactEmail: '',
  phoneNumber: '',
  documentNumber: '',
  documentTypeId: '',
  countryId: '',
  address: '',
  stateId: '0',
  cityId: '0',
  totalPlantedArea: '',
};

export default function FarmerRegister(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [title, setTitle] = useState({ title: '', subtitle: '' });
  const [activeStep, setActiveStep] = useState(0);
  const [submitedStep, setSubmitedStep] = useState(null);
  const [countries, setCountries] = useState([]);
  const [viewType, setViewType] = useState('create');
  const [latLngLot, setLatLngLot] = useState({ lat: '', lng: '' });
  const [latLngFarm, setLatLngFarm] = useState({ lat: '', lng: '' });
  const [country, setCountry] = useState(CountriesDomainEnum.Brasil as string);
  const [saveLatLng, setSaveLatLng] = useState({
    farm: false,
    lot: false,
    openModalFarm: false,
    openModalLot: false,
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState<ObjectLiteral<string>>({});
  const { getLabelTranslation, translationsDictionary } = useLanguageSwitcher();
  const [coordinatesBackup, setCoordinatesBackup] = useState([] as { lat: number; lng: number }[]);
  const { openModal, closeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const { user, isLoading } = useStore(UserProfileStore);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  let submit;
  const [formDataFarmer, setFormDataFarmer] = useState<IFarmerFormData>(initialStateFormDataFarmer);

  const [mapImageURL, setMapImageURL] = useState({ img: null, isLoading: false });

  const [formDataFarm, setFormDataFarm] = useState<IFarmFormData>({
    cityId: '0',
    farmerId: '',
    farmName: '',
    latitude: '',
    longitude: '',
    stateId: '0',
    totalArea: null,
  });
  const [formDataLot, setFormDataLot] = useState<ILotFormData>({
    cityId: '0',
    stateId: '0',
    farmId: '',
    lotName: '',
    mapPinList: [
      {
        latitude: '',
        longitude: '',
      },
    ],
    totalArea: null,
  });

  const { farmerId } = useParams();

  const setMapPinListLot = mapPinList => {
    setFormDataLot(prev => ({ ...prev, mapPinList }));
  };
  const getFarmer = (farmerId: any) => {
    FarmerService.getFarmerById({ id: farmerId }).then(response => {
      const ufId = { target: { value: response.stateId } };
      const cityId = { target: { value: response.cityId } };
      setFormDataFarmer({
        address: response.address,
        cityId: response.cityId,
        contactEmail: response.contactEmail,
        countryId: response.countryId,
        documentNumber: response.documentNumber,
        documentTypeId: response.documentTypeId,
        name: response.name,
        phoneNumber: response.phoneNumber,
        stateId: response.stateId,
        totalPlantedArea: response.totalPlantedArea,
        stateName: response.stateName,
        cityName: response.cityName,
        countryName: response.countryName,
      });
      handleStateChangeFarmer(ufId);
      handleCityChangeFarmer(cityId);
    });
  };

  const getFarm = async (farmId: any) => {
    try {
      const response = await FarmService.getFarmById({ id: farmId });
      setFormDataFarm(prev => ({ ...prev, farmName: response.name, farmerId: response.farmerId }));
      setFormDataFarmer(prev => ({ ...prev, name: response.farmerName }));
      setFormDataLot(prev => ({ ...prev, cityId: response.cityId, stateId: response.stateId }));
      setLatLngFarm({ lat: response?.latitude, lng: response?.longitude });
      setLatLngLot({ lat: response?.latitude, lng: response?.longitude });
      const citiesList = await LocationsService.getCities(response.stateId);
      setCities(citiesList);
    } catch (err) {
      console.error(err);
    }
  };

  const exit = () => {
    navigate(-1);
    closeModal();
  };

  useEffect(() => {
    if (activeStep === 0) {
      setTitle(() => ({
        title: '',
        subtitle: getLabelTranslation('add_farmer_information_legend'),
      }));
    } else if (activeStep === 1) {
      setTitle(() => ({
        title: getLabelTranslation('add_farm_information'),
        subtitle: getLabelTranslation('add_farm_infos_of'),
      }));
    } else if (activeStep === 2) {
      setTitle(() => ({
        title: getLabelTranslation('add_lot_information'),
        subtitle: getLabelTranslation('add_lot_infos_of'),
      }));
    }
  }, [activeStep, translationsDictionary]);

  useEffect(() => {
    // on start useEffect
    const fetchData = async () => {
      try {
        const countriesList = await LocationsService.getCountries();
        setCountries(countriesList);

        const selectedCountryId = countriesList?.find(
          country => country.name === user.countryName,
        )?.id;

        setFormDataFarmer(prev => ({ ...prev, countryId: selectedCountryId }));
        setCountry(user.countryName);

        const liststates = await LocationsService.getStates(selectedCountryId, "farmer");
        setStates(liststates);

        setFormDataFarmer(prev => ({
          ...prev,
          countryId: selectedCountryId,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [activeStep, cities, user]);

  useEffect(() => {
    if (location.pathname.includes('lot-step')) {
      const farmId = farmerId;
      setViewType('create');
      getFarm(farmId);
      setFormDataLot(prev => ({ ...prev, farmId }));
      setActiveStep(2);
    } else if (location.pathname.includes('farm-step')) {
      setViewType('create');
      setActiveStep(1);
      getFarmer(farmerId);
      setFormDataFarm(prev => ({ ...prev, farmerId }));
    } else {
      setActiveStep(0);
      setSubmitedStep(null);
      setViewType('create');
    }
  }, []);

  const handleFarmerFormSubmit = async () => {
    setSubmitedStep(0);
    validateEntireForm(formDataFarmer).then(async hasError => {
      if (!hasError) {
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('name', formDataFarmer.name);
        formDataToSend.append('contactEmail', formDataFarmer.contactEmail);
        formDataToSend.append('phoneNumber', formDataFarmer.phoneNumber);
        formDataToSend.append('documentTypeId', await setDocId());
        formDataToSend.append('address', formDataFarmer.address);
        formDataToSend.append('countryId', formDataFarmer.countryId);
        formDataToSend.append('stateId', formDataFarmer.stateId);
        formDataToSend.append('cityId', formDataFarmer.cityId);
        formDataToSend.append('totalPlantedArea', `${formDataFarmer.totalPlantedArea}`);
        formDataToSend.append('documentNumber', formDataFarmer.documentNumber.replace(/\D/g, ''));

        FarmerService.upSertFarmer(formDataToSend)
          .then(response => {
            setLoading(false);
            setFormDataFarm(prev => ({ ...prev, farmerId: response }));
            openModal({
              type: 'success',
              title: getLabelTranslation('created_success'),
              primaryAction: {
                label: 'OK',
                action: () => {
                  closeModal();
                  setActiveStep(activeStep + 1);
                },
              },
            });
          })
          .catch(() => {
            setLoading(false);
            openModal({
              type: 'error',
              title: getLabelTranslation('register_error'),
              message: getLabelTranslation('modal_message_delete_error'),
            });
          });
      }
    });
  };

  const handleFarmFormSubmit = async () => {
    setSubmitedStep(1);
    validateEntireForm(formDataFarm).then(async hasError => {
      if (!hasError) {
        setLoading(true);
        const formDataToSend = new FormData();
        formDataToSend.append('name', formDataFarm.farmName);
        formDataToSend.append('farmerId', formDataFarm.farmerId);
        formDataToSend.append('cityId', formDataFarm.cityId);
        formDataToSend.append('totalArea', `${formDataFarm.totalArea}`);
        formDataToSend.append('latitude', latLngFarm.lat);
        formDataToSend.append('longitude', latLngFarm.lng);

        FarmService.createFarm(formDataToSend)
          .then(response => {
            setLoading(false);
            setFormDataLot(prev => ({ ...prev, farmId: response }));
            openModal({
              type: 'success',
              title: getLabelTranslation('created_success'),
              primaryAction: {
                label: 'OK',
                action: () => {
                  closeModal();
                  setActiveStep(activeStep + 1);
                },
              },
            });
          })
          .catch(error => {
            setLoading(false);
            errorHandler(error, setFieldErrors);
            openModal({
              type: 'error',
              title: getLabelTranslation('register_error'),
              message: getLabelTranslation('modal_message_delete_error'),
            });
          });
      }
    });
  };

  const handleLotFormSubmit = async () => {
    setSubmitedStep(2);
    validateEntireForm(formDataLot).then(async hasError => {
      if (!hasError) {
        setLoading(true);
        const formDataToSend = {
          name: formDataLot.lotName,
          farmId: formDataLot.farmId,
          cityId: formDataLot.cityId,
          totalArea: formDataLot.totalArea,
          mapPinList: formDataLot.mapPinList?.filter(
            x => !!Number(x.latitude) && !!Number(x.longitude),
          ),
          longitude: latLngLot.lng,
          latitude: latLngLot.lat,
          lotImage: mapImageURL.img,
        };

        LotsService.createLot(formDataToSend)
          .then(() => {
            setLoading(false);
            openModal({
              type: 'success',
              title: getLabelTranslation('created_success'),
              message: getLabelTranslation('modal_message_register_success'),
              primaryAction: {
                label: 'OK',
                action: () => {
                  setActiveStep(0);
                  setSubmitedStep(null);
                  navigate(`/farmer-form-register/${formDataFarm.farmerId}/farm-flap`);
                  closeModal();
                },
              },
            });
          })
          .catch(error => {
            setLoading(false);
            errorHandler(error, setFieldErrors);
            openModal({
              type: 'error',
              title: getLabelTranslation('register_error'),
              message: getLabelTranslation('modal_message_delete_error'),
            });
          });
      }
    });
  };

  const handleStateChangeFarmer = event => {
    const stateId = event.target.value;
    setFormDataFarmer(prev => ({ ...prev, stateId }));
    setFormDataLot(prev => ({ ...prev, stateId }));
    if (stateId !== '0') {
      const fetchData = async () => {
        try {
          const citiesList = await LocationsService.getCities(stateId);
          const error = validateFields('stateId', stateId);
          if (error.length === 0) {
            setErrors(e => {
              delete e.ufId;
              return { ...errors };
            });
          } else {
            setErrors(e => ({ ...e, ufId: error }));
          }

          setCities(citiesList);
        } catch (error) {
          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
        }
      };
      fetchData();
    } else {
      setCities([]);
    }
  };

  const handleStateChangeFarm = event => {
    const stateId = event.target.value;

    setFormDataFarm(prev => ({ ...prev, stateId }));

    if (stateId !== 0) {
      const fetchData = async () => {
        try {
          const cities = await LocationsService.getCities(stateId);
          const error = validateFields('stateId', stateId);
          if (error.length === 0) {
            setErrors(errors => {
              delete errors.ufId;
              return { ...errors };
            });
          } else {
            setErrors(errors => ({ ...errors, ufId: error }));
          }

          setCities(cities);
        } catch (error) {
          openModal({
            type: 'error',
            title: getLabelTranslation('modal_tittle_delete_error'),
            message: getLabelTranslation('modal_message_delete_error'),
          });
        }
      };
      fetchData();
    } else {
      setCities([]);
    }
    handleStateChangeLot(stateId);
  };

  const handleStateChangeLot = stateId => {
    setFormDataLot(prev => ({ ...prev, stateId }));
  };

  const handleCityChangeFarmer = event => {
    const cityId = event.target.value;
    const error = validateFields('cityId', cityId);
    if (error.length === 0) {
      setErrors(errors => {
        delete errors.cityId;
        return { ...errors };
      });
    } else {
      setErrors(errors => ({ ...errors, cityId: error }));
    }

    setFormDataFarmer(prev => ({ ...prev, cityId }));
  };

  const handleCityChangeFarm = event => {
    const cityId = event.target.value;
    const error = validateFields('cityId', cityId);
    if (error.length === 0) {
      setErrors(errors => {
        delete errors.cityId;
        return { ...errors };
      });
    } else {
      setErrors(errors => ({ ...errors, cityId: error }));
    }
    setFormDataFarm(prev => ({ ...prev, cityId }));
    handleCityChangeLot(cityId);
    handleGetStarterCoordinations(cityId, formDataFarm.stateId, formDataFarm.farmName);
  };

  const handleCityChangeLot = cityId => {
    setFormDataLot(prev => ({ ...prev, cityId }));
  };

  const handleGetStarterCoordinations = async (
    cityId: string,
    stateId: string,
    address: string,
  ) => {
    try {
      const cityName = cities?.find(c => c?.id === cityId)?.name;
      const cityNameToLowerCase = cityName?.toLowerCase();
      const stateName = states?.find(s => s?.id === stateId)?.name;

      if (cityName && stateName) {
        const cityLabel = getLabelTranslation('formfields_correction_city');
        const stateLabel = getLabelTranslation('formfields_state');
        const citySearch = `${cityLabel}: ${cityName}, ${stateLabel}: ${stateName}, ${user.countryName}, ${address}`;
        const geocoder = new window.google.maps.Geocoder();
      
        geocoder.geocode({ address: citySearch }, (results, status) => {
                if (status === 'OK') {
                  const { lat, lng } = results[0].geometry.location;
                  setLatLngFarm({ lat: lat().toString(), lng: lng().toString() });
                  setFormDataFarm(prev => ({ ...prev, latitude: lat().toString(), longitude: lng().toString() }));
                  setLatLngLot({ lat: lat().toString(), lng: lng().toString() });

                } else {
                  console.error('Erro in geocodification: ', status);
                }})
      }
    } catch (err) {
      console.error(err);
    }
  };

  const validateFields = (fieldName, fieldValue) => {
    if (!fieldValue && fieldName !== 'totalArea') return getLabelTranslation('required_field');

    if (fieldName === 'documentNumber') {
      if (!validateDocument(fieldValue, user.countryName)) {
        return getLabelTranslation('register_farmer_document_error');
      }
    }

    if (fieldName === 'phoneNumber') {
      if (!validatePhone(fieldValue, user.countryName)) {
        return getLabelTranslation('register_farmer_phone_error');
      }
    }

    if (fieldName === 'contactEmail') {
      if (!validateEmail(fieldValue)) {
        return getLabelTranslation('register_farmer_email_error');
      }
    }

    if (fieldName === 'totalPlantedArea') {
      if (parseInt(fieldValue, 10) <= 0) {
        return getLabelTranslation('register_farmer_area_error');
      }
    }
    if ((fieldName === 'longitude' || fieldName === 'latitude') && !fieldValue) {
      return getLabelTranslation('required_field');
    }
    if (fieldName === 'mapPinList' && fieldValue?.filter(x => !!Number(x?.latitude))?.length < 3) {
      return getLabelTranslation('min_3_pin_lot');
    }
    if ((fieldName === 'stateId' || fieldName === 'cityId') && fieldValue === '0') {
      return getLabelTranslation('required_field');
    }

    return '';
  };

  const validateEntireForm = form => {
    return new Promise<boolean>(resolve => {
      const keys = Object.keys(form) as (keyof IFormData)[];
      let hasError = false;
      keys.forEach(key => {
        const error = validateFields(key, form[key]);

        if (error.length === 0) {
          setErrors(errors => {
            delete errors[key];
            return { ...errors };
          });
        } else {
          setErrors(errors => ({ ...errors, [key]: error }));
          hasError = true;
        }
      });
      resolve(hasError);
    });
  };

  const setDocId = async () => {
    const docTypes = await LocationsService.getDocumentTypes();
    const cleanedNumber = formDataFarmer.documentNumber.replace(/\D/g, '').length;
    if (user.countryName === CountriesDomainEnum.Argentina) {
      const cuit = docTypes.find(doc => doc.detail === 'CUIT').id;
      return cuit;
    }
    if (cleanedNumber === 14) {
      const cnpj = docTypes.find(doc => doc.detail === 'CNPJ').id;
      return cnpj;
    }
    const cpf = docTypes.find(doc => doc.detail === 'CPF').id;
    return cpf;
  };

  const handleChangeValuesFarmer = useCallback(
    event => {
      const { name: field, value } = event.target;

      const error = validateFields(field, value);

      if (error.length === 0) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: error }));
      }

      setFormDataFarmer(prev => ({
        ...prev,
        [field]: value,
        documentTypeId: user.documentTypeId,
      }));
    },
    [country],
  );

  const handleChangeValuesFarm = useCallback(
    event => {
      const { name: field, value } = event.target;

      const error = validateFields(field, value);

      if (error.length === 0) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: error }));
      }

      setFormDataFarm(prev => ({
        ...prev,
        [field]: value,
      }));
    },
    [country],
  );

    useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    document.head.appendChild(script);
  }, []);

  const handleChangeValuesLot = useCallback(
    event => {
      const { name: field, value } = event.target;
      const error = validateFields(field, value);

      if (error.length === 0) {
        setErrors(errors => {
          delete errors[field];
          return { ...errors };
        });
      } else {
        setErrors(errors => ({ ...errors, [field]: error }));
      }

      setFormDataLot(prev => ({
        ...prev,
        [field]: value,
      }));
    },
    [country],
  );

  useEffect(() => {
    const isFarmerStep = activeStep === 0 && submitedStep === 0;
    const isFarmStep = activeStep === 1 && submitedStep === 1;
    const isLotStep = activeStep === 2 && submitedStep === 2;

    if (isFarmerStep) {
      validateEntireForm(formDataFarmer);
    } else if (isFarmStep) {
      validateEntireForm(formDataFarm);
    } else if (isLotStep) {
      validateEntireForm(formDataLot);
    }
  }, [translationsDictionary]);

  return (
    <GDMContainer
      component="form"
      onSubmit={submit}
      sx={containerStyle}
      {...{ noValidate: true, autoComplete: 'off' }}
    >
      <GDMTypography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ color: '#A1A9B8', fontSize: '14px' }}
      >
        {`${getLabelTranslation('farmer')} > ${getLabelTranslation('register_farmer')}`}
      </GDMTypography>

      <GDMTypography
        variant="h4"
        sx={{
          fontWeight: '700',
          color: '#222',
          marginBottom: 0,
          fontSize: isDesktop ? '32px' : '28px',
          textAlign: 'start',
        }}
        gutterBottom
      >
        {activeStep === 0 ? getLabelTranslation('register_farmer') : title.title}
      </GDMTypography>

      <GDMTypography
        variant="subtitle1"
        sx={{
          marginBottom: '10px',
          color: '#222',
          textAlign: 'start',
          fontSize: isDesktop ? '20px' : '16px',
        }}
        gutterBottom
      >
        {title.subtitle} {activeStep === 2 && <strong> {formDataFarm.farmName} </strong>}
        {activeStep === 2 && getLabelTranslation('of_the')}
        {activeStep > 0 && <b> {formDataFarmer.name}</b>}
      </GDMTypography>

      {viewType === 'create' && (
        <>
          <GDMDivider />

          <GDMGrid
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
          >
            <GDMStepper activeStep={activeStep} />
          </GDMGrid>
        </>
      )}

      <GDMGrid
        container
        spacing={2}
        sx={{
          flex: 1,
          flexWrap: 'wrap',
          flexDirection: activeStep === 0 ? 'row' : 'column',
          paddingLeft: activeStep !== 0 && '16px',
        }}
      >
        {activeStep === 0 && (
          <GDMFarmerRegisterForm
            user={user}
            errors={errors}
            states={states}
            cities={cities}
            viewType={viewType}
            countries={countries}
            formData={formDataFarmer}
            handleChangeValues={handleChangeValuesFarmer}
            handleStateChange={handleStateChangeFarmer}
            handleCityChange={handleCityChangeFarmer}
          />
        )}
        {activeStep === 1 && (
          <GDMFarmRegisterForm
            typeIcon="create"
            farmerName={formDataFarmer.name}
            errors={errors}
            states={states}
            cities={cities}
            formData={formDataFarm}
            setSaveLatLng={setSaveLatLng}
            handleChangeValues={handleChangeValuesFarm}
            handleStateChange={handleStateChangeFarm}
            openModalState={saveLatLng.openModalFarm}
            handleCityChange={handleCityChangeFarm}
            handleGetStarterCoordinations={handleGetStarterCoordinations}
            setCoordinates={({ lat, lng }) => {
              setLatLngFarm({ lat, lng });
              setLatLngLot({ lat, lng });
            }}
            latLong={latLngFarm}
          />
        )}
        {activeStep === 2 && (
          <GDMLotRegisterForm
            typeForm="create"
            farmName={formDataFarm.farmName}
            farmerName={formDataFarmer.name}
            errors={errors}
            states={states}
            cities={cities}
            formData={formDataLot}
            setSaveLatLng={setSaveLatLng}
            handleChangeValues={handleChangeValuesLot}
            openModalState={saveLatLng.openModalLot}
            setMapPinListLot={setMapPinListLot}
            setCoordinates={setLatLngLot}
            latLong={latLngLot}
            coordinatesBackup={coordinatesBackup}
            setCoordinatesBackup={setCoordinatesBackup}
            updateParentMapImageURL={setMapImageURL}
          />
        )}
        <GDMGrid
          container
          spacing={2}
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{
            marginTop: isDesktop ? '22px' : '16px',
            marginBottom: '0',
            flex: 1,
            width: '100%',
            flexWrap: 'wrap',
            padding: !isDesktop && 0,
            marginLeft: !isDesktop && activeStep === 0 ? '18px' : '0px',
            gap: !isDesktop ? '16px' : '27px',
            flexDirection: isDesktop ? 'row' : 'column-reverse',
          }}
        >
          {saveLatLng.farm && isDesktop && activeStep === 1 && (
            <GDMButton
              onClick={async () => {
                setSaveLatLng(prev => ({ ...prev, openModalFarm: true }));
              }}
              sx={{
                height: '51px',
                width: isDesktop ? 'auto' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_latitude_longitude')}
            </GDMButton>
          )}

          {saveLatLng.lot && isDesktop && activeStep === 2 && (
            <GDMButton
              onClick={() => setSaveLatLng(prev => ({ ...prev, openModalLot: true }))}
              sx={{
                height: '51px',
                width: isDesktop ? 'auto' : '100%',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_latitude_longitude')}
            </GDMButton>
          )}
          <GDMButton
            disabled={loading}
            onClick={() => {
              openModal({
                type: 'warning',
                title: getLabelTranslation('warning_exit'),
                message: getLabelTranslation('modal_message_exit_warning'),
                primaryAction: {
                  label: getLabelTranslation('exit'),
                  action: exit,
                },
                secondAction: {
                  label: getLabelTranslation('cancel'),
                  action: closeModal,
                },
              });
            }}
            sx={{
              mr: 4,
              height: '51px',
              flex: !isDesktop && 1,
              width: isDesktop ? '187px' : '100%',

              color: theme.palette.secondary.dark,
              background: theme.palette.secondary.main,
              boxShadow: 'none',
              marginRight: !isDesktop && 0,
              ':hover': {
                background: theme.palette.primary.light,
                boxShadow: 'none',
              },
              '&.Mui-disabled': {
                background: theme.palette.action.disabled,
                color: 'white',
              },
            }}
            variant="contained"
          >
            {getLabelTranslation('ReusableFormKey_Cancel')}
          </GDMButton>
          <GDMButton
            variant="contained"
            disabled={loading}
            onClick={() => {
              if (activeStep === 0) {
                submit = handleFarmerFormSubmit();
              } else if (activeStep === 1) {
                submit = handleFarmFormSubmit();
              } else if (activeStep === 2) {
                submit = handleLotFormSubmit();
              }
            }}
            sx={{
              height: '51px',
              flex: !isDesktop && 1,
              width: isDesktop ? '187px' : '100%',
              background: theme.palette.primary.main,
              boxShadow: 'none',
              ':hover': {
                boxShadow: 'none',
                background: theme.palette.primary.dark,
              },
              '&.Mui-disabled': {
                background: theme.palette.action.disabled,
                color: 'white',
              },
            }}
          >
            {loading || mapImageURL.isLoading ? (
              <GDMCircularProgress />
            ) : (
              getLabelTranslation('ReusableFormKey_Save')
            )}
          </GDMButton>
        </GDMGrid>
      </GDMGrid>
    </GDMContainer>
  );
}
