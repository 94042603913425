/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Collapse,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  Zoom,
} from '@mui/material';
import { GDMContainer, GDMTypography } from 'components';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { FaCircleInfo } from 'react-icons/fa6';
import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import colors from '../../../core/design-system/style-guide/colors.json';
import textStyles from '../../../core/design-system/style-guide/typography.json';
import useLanguageSwitcher from '../../../core/hooks/useLanguageSwitcher';

const CatalogTable = ({
  emptyMessage,
  header,
  rows,
  currentPage,
  setFilter = undefined,
  pages,
  orderDesc,
}: {
  emptyMessage: string;
  header: any;
  rows: any;
  currentPage: number;
  pages: number;
  setFilter: any;
  orderDesc: boolean;
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const [openCollapse, setOpenCollapse] = useState(null);
  const { theme } = useThemeSwitcher();
  const { user } = useStore(UserProfileStore);
  const [open, setOpen] = useState(null);

  const handleTooltipClose = event => {
    if (open && !event.currentTarget.contains(event.relatedTarget)) {
      setOpen(null);
    }
  };

  const handleTooltipOpen = id => {
    setOpen(id);
  };

  const userRole = user?.role;

  const handleOpenCollapse = id => {
    setOpenCollapse(openCollapse === id ? null : id);
  };

  const changeOrderColumn = column => {
    setFilter(prev => ({ ...prev, orderColumn: column.name, orderDesc: !orderDesc, page: 1 }));
  };

  return (
    <GDMContainer>
      <TableContainer>
        <Table>
          {isDesktop && (
            <TableHead>
              <TableRow sx={{ background: '#F2F4F9' }}>
                {header.map(column => (
                  <TableCell
                    sx={{
                      border: 'none',
                    }}
                  >
                    <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                      <GDMTypography
                        variant="subtitle2"
                        sx={{
                          color: '#777D88',
                        }}
                      >
                        {column.title}
                      </GDMTypography>
                      <IconButton
                        onClick={() => {
                          changeOrderColumn(column);
                        }}
                      >
                        <CgArrowsExchangeAltV />
                      </IconButton>
                    </GDMContainer>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('view')}
                    </GDMTypography>
                  </GDMContainer>
                </TableCell>
                {userRole === 'ADMINISTRATOR' && (
                  <TableCell
                    sx={{
                      border: 'none',
                    }}
                  >
                    <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                      <GDMTypography
                        variant="subtitle2"
                        sx={{
                          color: '#777D88',
                        }}
                      >
                        {getLabelTranslation('edit')}
                      </GDMTypography>
                    </GDMContainer>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isDesktop
              ? rows.map(row => (
                  // row.recommendationStatusName !== 'Generated' && (
                  <TableRow>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 400 }}>
                        {row.cultureName}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 400 }}>
                        {row.brandName}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 400 }}>
                        {row.genotype}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px', fontWeight: 400 }}>
                        {row.technology}
                      </GDMTypography>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={row.sellingTip}
                        arrow
                        placement="top"
                        TransitionComponent={Zoom}
                      >
                        <button
                          type="button"
                          style={{ border: 'none', background: 'none', marginLeft: '45px' }}
                        >
                          <FaCircleInfo color={row.sellingTip ? '#A1A9B8' : '#D9DBE4'} size={24} />
                        </button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Link href={`/varieties-to-compare/${row.id}`} component="a">
                        <IconButton sx={{ marginLeft: '-5px' }}>
                          <IoEyeOutline style={{ fontSize: '24px' }} />
                        </IconButton>
                      </Link>
                    </TableCell>
                    {userRole === 'ADMINISTRATOR' && (
                      <TableCell>
                        <Link href={`/varieties-to-compare/${row.id}/edit`} component="a">
                          <IconButton>
                            <FiEdit style={{ fontSize: '22px' }} />
                          </IconButton>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              : rows.map((row, index) => (
                  <GDMContainer>
                    <ListItemButton
                      onClick={() => handleOpenCollapse(row.id)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 0 10px 0',
                        ...(index !== rows.length - 1 &&
                          openCollapse !== row.id && {
                            borderStyle: 'solid',
                            borderBottomWidth: '2px',
                            borderBottomColor: '#E7E9F1',
                            marginBottom: '10px',
                            paddingBottom: '10',
                          }),
                      }}
                    >
                      <GDMContainer
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                            padding: '10px 0',
                          }}
                        >
                          <GDMTypography variant="body1" sx={{ fontWeight: 700 }}>
                            {row.genotype}
                          </GDMTypography>
                        </ListItem>
                        <IconButton
                          sx={{
                            color: '#222',
                            width: '32px',
                            height: '32px',
                            backgroundColor: theme.palette.secondary.main,
                            borderRadius: '50%',
                          }}
                        >
                          {openCollapse === row.id ? <MdExpandLess /> : <MdExpandMore />}
                        </IconButton>
                      </GDMContainer>
                    </ListItemButton>
                    <Collapse in={openCollapse === row.id} timeout="auto" unmountOnExit>
                      <List
                        component="div"
                        disablePadding
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">
                            {getLabelTranslation('culture')}
                          </GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.cultureName}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">
                            {getLabelTranslation('brand')}
                          </GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.brandName}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">
                            {getLabelTranslation('variety')}
                          </GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.genotype}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">
                            {getLabelTranslation('technology')}
                          </GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.technology}
                          </GDMTypography>
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMTypography variant="body2">
                            {getLabelTranslation('sell_tip')}
                          </GDMTypography>
                          {isDesktop ? (
                            <Tooltip
                              title={row.sellingTip}
                              arrow
                              placement="top"
                              TransitionComponent={Zoom}
                            >
                              <button type="button" style={{ border: 'none', background: 'none' }}>
                                <FaCircleInfo
                                  color={row.sellingTip ? '#A1A9B8' : '#D9DBE4'}
                                  size={24}
                                />
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={row.sellingTip}
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={open === row.sellingTip + index}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                              placement="top"
                              TransitionComponent={Zoom}
                            >
                              <button
                                type="button"
                                style={{ border: 'none', background: 'none' }}
                                onClick={() => handleTooltipOpen(row.sellingTip + index)}
                                onBlur={handleTooltipClose}
                              >
                                <FaCircleInfo
                                  color={row.sellingTip ? '#A1A9B8' : '#D9DBE4'}
                                  size={16}
                                />
                              </button>
                            </Tooltip>
                          )}
                        </ListItem>
                        <ListItem
                          sx={{
                            padding: '0 0 8px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <GDMContainer
                            sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                          >
                            <GDMContainer
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <GDMTypography variant="body2">
                                {getLabelTranslation('actions')}
                              </GDMTypography>
                              <GDMContainer
                                sx={{ display: 'flex', alignItems: 'center', gap: '62px' }}
                              >
                                <Link href={`/varieties-to-compare/${row.id}`} component="a">
                                  <IconButton>
                                    <IoEyeOutline />
                                  </IconButton>
                                </Link>
                                {userRole === 'ADMINISTRATOR' && (
                                  <Link href={`/varieties-to-compare/${row.id}/edit`} component="a">
                                    <IconButton>
                                      <FiEdit />
                                    </IconButton>
                                  </Link>
                                )}
                              </GDMContainer>
                            </GDMContainer>
                          </GDMContainer>
                        </ListItem>
                        <Divider sx={{ margin: '10px 0 20px 0' }} />
                      </List>
                    </Collapse>
                  </GDMContainer>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!rows?.length && !!emptyMessage && (
        <GDMTypography
          sx={{
            textAlign: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}

      {rows.length > 0 && (
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_, page) => setFilter(prev => ({ ...prev, page }))}
          variant="outlined"
          shape="rounded"
          boundaryCount={isDesktop ? 1 : 0}
          sx={{
            mt: 2,
            justifyContent: 'center',
            display: 'flex',
            '& ul > li > button': {
              backgroundColor: colors.light2,
              width: {
                xs: '35px',
                sm: '40px',
                md: '45px',
                lg: '51px',
                xl: '51px',
              },
              height: {
                xs: '35px',
                sm: '40px',
                md: '45px',
                lg: '51px',
                xl: '51px',
              },
              border: 'none',
              ...textStyles.table.pagination,
            },
            '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
              backgroundColor: colors.dark3,
            },
          }}
        />
      )}
    </GDMContainer>
  );
};

export default CatalogTable;
