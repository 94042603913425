import { GDMInputMask } from 'components/gdm-input-mask';
import { GDMTextField } from 'components/gdm-text-field';
import { useLanguageContext } from 'core/store/contexts/languageContext';
import { useEffect, useState } from 'react';
import { dynamicFormTextFieldMaskStyles } from './DynamicFormTextFieldMask.styles';
import { DynamicFormTextFieldMaskComponent } from './DynamicFormTextFieldMask.type';

const DynamicFormTextFieldMask: DynamicFormTextFieldMaskComponent = ({
  name,
  required,
  mask,
  maskChar,
  id,
  placeholder,
  disabled,
  value,
  defaultValue,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  const { getLabelTranslation, currentLanguage } = useLanguageContext();

  const [textProps, setTextProps] = useState({
    placeholder: getLabelTranslation(placeholder),
    helperText: getLabelTranslation(helperText as string),
  });

  useEffect(() => {
    setTextProps({
      placeholder: getLabelTranslation(placeholder),
      helperText: getLabelTranslation(helperText as string),
    });
  }, [currentLanguage, placeholder, helperText]);

  return (
    <GDMInputMask
      {...{
        mask,
        maskChar,
        onChange,
        onBlur,
        defaultValue,
        value,
        error,
        helperText: textProps.helperText,
        disabled,
      }}
    >
      <GDMTextField
        {...{
          id,
          name,
          required,
          placeholder: textProps.placeholder,
        }}
        variant="outlined"
        focused={false}
        fullWidth
        sx={dynamicFormTextFieldMaskStyles}
      />
    </GDMInputMask>
  );
};

export default DynamicFormTextFieldMask;
