import { LocationsService } from 'core/api/services/locations';
import { GetCitiesFunction } from './getCities.type';

const getCities: GetCitiesFunction = async ({ stateId, onInit, onSuccess, onError, onFinally }) => {
  if (!!onInit) onInit();
  try {
    const cities = await LocationsService.getCities(stateId);

    if (!!onSuccess) onSuccess(cities);

    return cities;
  } catch (error) {
    console.error(error);

    if (!!onError) onError(error);

    return [];
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default getCities;
