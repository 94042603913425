import { useMediaQuery, useTheme, SxProps } from '@mui/material';
import {
  DynamicFormData,
  GDMBox,
  GDMButton,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
  GDMTypography,
} from 'components';
import { GDMCard } from 'components/gdm-card';
import { GDMTableAccounts } from 'components/gdm-table-accounts/GDMTable';
import { MultiplierService } from 'core/api/services/multiplier';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { useEffect, useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { LiaChartBarSolid } from 'react-icons/lia';
import { LuNewspaper } from 'react-icons/lu';
import { PiBriefcaseMetal } from 'react-icons/pi';
import { useFetcher, useNavigate, useParams } from 'react-router-dom';
import { getBrands, getGDMSellers, getStates } from 'utils';

const DEFAULT_MULTIPLIER: Partial<DynamicFormData> = {
  address: '',
  brandIds: undefined,
  cityId: '',
  contactEmail: '',
};

export const flapStyleActive: SxProps = {
  display: 'flex',
  alignItems: 'center',
  background: 'white',
  gap: '8px',
  padding: '8px 24px',
  borderRadius: '8px 8px 0 0',
  border: '1px solid #CBCDD9',
  borderBottom: '1px solid white',
  cursor: 'pointer',
  '@media only screen and (max-width: 600px)': {
    padding: '8px 4px',
    justifyContent: 'center',
  },
};

export const flapStyleDisabled: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '12px 32px',
  borderBottom: '1px solid white',
  cursor: 'pointer',
  '@media only screen and (max-width: 600px)': {
    padding: '8px 4px',
    justifyContent: 'center',
  },
};

export const MultiplierViewPage = () => {
  const [activeFlap, setActiveFlap] = useState('register');

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const [multiplier, setMultiplier] = useState<Partial<DynamicFormData>>(DEFAULT_MULTIPLIER);
  const [brands, setBrands] = useState([]);
  const [gdmSellers, setgdmSellers] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [infosToView, setInfosToView] = useState({ accounts: undefined, statistics: null });
  const [filters, setFilters] = useState({
    orderDesc: false,
    orderColum: '',
    pages: 1,
    currentPage: 1,
    totalPages: 1,
  });

  const {
    user: { countryId },
  } = useStore(UserProfileStore);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [entityType, setEntityType] = useState(getLabelTranslation('brandeselector_multiplier'));
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();
  const { multiplierId } = useParams();

  const handleBack = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const getMultiById = async () => {
    try {
      setIsLoading(true);
      const response = await MultiplierService.getMultiById(multiplierId);
      setMultiplier({
        id: multiplierId,
        name: response.name,
        fantasyName: response.fantasyName,
        contactEmail: response.contactEmail,
        phoneNumber: response.phoneNumber,
        personInChargeName: response.personInChargeName,
        documentNumber: response.documentNumber,
        cityId: response.cityId,
        countryId: response.countryId,
        address: response.address,
        brandIds: response.brands.map(item => item.id),
        sellerId: response.sellerGdmUserId,
        ufId: response.stateId,
        cityName: response.cityName,
        stateName: response.stateName,
        sellerGdmName: response.sellerGdmName,
        logoFile: response.logoCdnUrl,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getMultiplierAccountById = async () => {
    try {
      const response = await MultiplierService.getMultiplierAccountById({
        id: multiplierId,
        orderColum: filters.orderColum,
        orderDesc: filters.orderDesc,
        page: filters.currentPage,
      });
      setInfosToView(prev => ({ ...prev, accounts: response.items }));
      setFilters(prev => ({ ...prev, totalPages: response.totalPages }));
    } catch (e) {
      console.error(e);
    }
  };

  const getMultiplierAccountByIdOnChangeFilters = async () => {
    try {
      setIsLoading(true);
      const response = await MultiplierService.getMultiplierAccountById({
        id: multiplierId,
        orderColum: filters.orderColum,
        orderDesc: filters.orderDesc,
        page: filters.currentPage,
      });
      setInfosToView(prev => ({ ...prev, accounts: response.items }));
      setFilters(prev => ({ ...prev, totalPages: response.totalPages }));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getMultipliersStatistcsById = async () => {
    try {
      const response = await MultiplierService.getMultipliersStatistcsById(multiplierId);
      setInfosToView(prev => ({ ...prev, statistics: response }));
    } catch (e) {
      console.error(e);
    }
  };

  const loadBrands = () => {
    return getBrands({
      countryId,
      onError: () => console.error('Error on load brands!'),
      onInit: () => setIsLoading(true),
      onSuccess: brands => setBrands(brands),
    });
  };
  const loadStates = () => {
    return getStates({
      countryId,
      onSuccess: states => setStates(states),
      onInit: () => setIsLoading(true),
      onError: () => console.error('Error on load states!'),
    });
  };
  const loadSellersGDM = () => {
    return getGDMSellers({
      payload: {
        currentPag: 1,
        itemsPerPage: 999999999,
      },
      onSuccess: sellersGDM => setgdmSellers(sellersGDM.items),
    });
  };

  useEffect(() => {
    const loadData: Array<Promise<any>> = [loadBrands(), loadSellersGDM(), loadStates()];
    if (multiplierId) {
      getMultiById();
      getMultiplierAccountById();
      getMultipliersStatistcsById();
    }
    Promise.all(loadData).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setEntityType(getLabelTranslation('brandeselector_multiplier'));
  }, [currentLanguage]);

  useEffect(() => {
    getMultiplierAccountByIdOnChangeFilters();
  }, [filters.orderColum, filters.currentPage, filters.orderDesc]);

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > ${getLabelTranslation('ReusableFormKey_Multiplier')}`}
      title={`${getLabelTranslation('reusableForm_ViewRegistrationOf_')} ${getLabelTranslation(
        'ReusableFormKey_Multiplier',
      )}`}
    >
      <GDMConditionalRender canRender={isLoading}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>

      <GDMConditionalRender canRender={!isLoading}>
        <GDMContainer
          sx={{
            display: isDesktop ? 'flex' : 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            width: '100%',
            background: '#F2F4F9',
            borderBottom: '1px solid #D9DBE4',
            borderRadius: '12px 12px 0 0',
            marginBottom: '24px',
          }}
        >
          <GDMContainer
            sx={activeFlap === 'register' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              setActiveFlap('register');
            }}
          >
            <LuNewspaper color={theme.palette.primary.main} />
            <GDMTypography sx={{ fontSize: '18px', color: '#222', fontWeight: 500 }}>
              {getLabelTranslation('register')}
            </GDMTypography>
          </GDMContainer>
          <GDMContainer
            sx={activeFlap === 'accounts' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              setActiveFlap('accounts');
            }}
          >
            <PiBriefcaseMetal color={theme.palette.primary.main} />
            <GDMTypography sx={{ fontSize: '18px', color: '#222', fontWeight: 500 }}>
              {getLabelTranslation('accounts')}
            </GDMTypography>
          </GDMContainer>
          <GDMContainer
            sx={activeFlap === 'statistics' ? flapStyleActive : flapStyleDisabled}
            onClick={() => {
              setActiveFlap('statistics');
            }}
          >
            <LiaChartBarSolid color={theme.palette.primary.main} />
            <GDMTypography sx={{ fontSize: '18px', color: '#222', fontWeight: 500 }}>
              {getLabelTranslation('statistics')}
            </GDMTypography>
          </GDMContainer>
        </GDMContainer>
      </GDMConditionalRender>
      <GDMConditionalRender canRender={!isLoading}>
        {activeFlap === 'register' && (
          <GDMDynamicFormFields
            {...{
              entityType,
              viewType: 'view',
              formData: multiplier,
              isSubmiting,
              errors,
              formDataEvents: {
                onBack: handleBack,
              },
              formDataOptions: {
                brands,
                cities,
                gdmSellers,
                roles: [],
                states,
              },
            }}
          />
        )}
        {activeFlap === 'accounts' && infosToView.accounts.length > 0 && (
          <GDMTableAccounts rows={infosToView.accounts} filters={filters} setFilters={setFilters} />
        )}

        {activeFlap === 'accounts' && infosToView.accounts.length === 0 && (
          <GDMTypography sx={{ textAlign: 'center', paddingTop: '24px' }}>
            {getLabelTranslation('this_profile_dont_have_accounts')}
          </GDMTypography>
        )}

        {activeFlap === 'statistics' && (
          <GDMContainer
            sx={{
              display: 'grid',
              gridTemplateColumns: isDesktop ? 'repeat(5, 1fr)' : '1fr',
              gap: '16px',
            }}
          >
            <GDMCard
              title={getLabelTranslation('recommendations_made')}
              percent={false}
              value={infosToView.statistics.recommendationsMade}
            />
            <GDMCard
              title={getLabelTranslation('recommendations_sale')}
              percent={false}
              value={infosToView.statistics.sales}
            />
            <GDMCard
              title={getLabelTranslation('recommendations_implemented')}
              value={infosToView.statistics.recommendationsImplemented}
              percent
            />
            <GDMCard
              title={getLabelTranslation('recommendation_lost')}
              percent
              value={infosToView.statistics.recommendationsLost}
            />
            <GDMCard
              title={getLabelTranslation('lot_history')}
              percent={false}
              value={infosToView.statistics.lotHistory}
            />
            <GDMCard
              title={getLabelTranslation('planting')}
              percent={false}
              value={infosToView.statistics.planting}
            />
            <GDMCard
              title={getLabelTranslation('planted')}
              percent={false}
              value={infosToView.statistics.planted}
            />
            <GDMCard
              title={getLabelTranslation('in_harvest')}
              percent={false}
              value={infosToView.statistics.inHarvest}
            />
            <GDMCard
              title={getLabelTranslation('harvested')}
              percent={false}
              value={infosToView.statistics.harvested}
            />
            <GDMCard
              title={getLabelTranslation('interrupted')}
              percent={false}
              value={infosToView.statistics.interrupted}
            />
          </GDMContainer>
        )}
        <GDMBox sx={{ display: 'flex', justifyContent: 'start', gap: '24px', marginTop: 'auto' }}>
          <GDMButton
            variant="outlined"
            onClick={() => navigate(-1)}
            sx={{
              color: theme.palette.secondary.dark,
              width: isDesktop ? '187px' : '100% !important',
            }}
          >
            <FaArrowLeftLong style={{ marginRight: '8px' }} />
            {getLabelTranslation('ReusableFormKey_Back')}
          </GDMButton>
        </GDMBox>
      </GDMConditionalRender>
    </GDMPageCard>
  );
};
