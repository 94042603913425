/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import { useEffect, useState } from 'react';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';

import { AccountsService } from 'core/api/services/accounts';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';

import { Autocomplete, Box, TextField, useMediaQuery, useTheme } from '@mui/material';

import {
  GDMButton,
  GDMContainer,
  GDMCustomInputLabel,
  GDMLoading,
  GDMTypography,
} from 'components';

import { boxStyle } from './AccountsVisualize.style';

export default function AccountsVisualize(): JSX.Element {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { accountId } = useParams();
  const { user } = useStore(UserProfileStore);
  const [brands, setBrands] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [initialData, setInitialData] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);

  const navigate = useNavigate();

  const { getLabelTranslation } = useLanguageSwitcher();

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const res = await AccountsService.getAccountId(accountId);
        setInitialData(res);
        setBrands(res.brands);
        setRoleId(res.roleId);
      } catch (error) {
        console.error('Failed to fetch account data', error);
      } finally {
        setLoadingPage(false);
      }
    };

    fetchAccountData();
  }, [accountId]);

  const setPerfilTitle = () => {
    if (roleId) {
      if (roleId === '6131237f-2943-468a-81ec-87661e9377bd') {
        return getLabelTranslation('seller_distributor');
      }
      if (roleId === '82f43a26-7855-4faf-88a1-4801d6181401') {
        return getLabelTranslation('manager_distributor');
      }
      if (roleId === 'dbb87c0b-34ac-4d45-8ace-80b08b1df2a9') {
        return getLabelTranslation('seller_multiplier');
      }
      if (roleId === 'dd1e670b-30d3-4bc7-bb13-40ebb93109b9') {
        return getLabelTranslation('manager_multiplier');
      }
    }

    return ' ';
  };

  const setMultOrDistTitle = () => {
    if (roleId) {
      if (
        roleId === '6131237f-2943-468a-81ec-87661e9377bd' ||
        roleId === '82f43a26-7855-4faf-88a1-4801d6181401'
      ) {
        return getLabelTranslation('brandeselector_distributor');
      }
      if (
        roleId === 'dbb87c0b-34ac-4d45-8ace-80b08b1df2a9' ||
        roleId === 'dd1e670b-30d3-4bc7-bb13-40ebb93109b9'
      ) {
        return getLabelTranslation('brandeselector_multiplier');
      }
    }

    return ' ';
  };

  const setMultOrDistName = () => {
    if (
      roleId === 'dbb87c0b-34ac-4d45-8ace-80b08b1df2a9' ||
      roleId === 'dd1e670b-30d3-4bc7-bb13-40ebb93109b9'
    ) {
      return initialData?.multiplierName;
    }

    return initialData?.distributorName;
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loadingPage) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  return (
    <>
      <Box>
        <Box sx={boxStyle}>
          <GDMTypography
            sx={{ color: '#A1A9B8', fontSize: '14px', m: 0 }}
            display="block"
            gutterBottom
          >
            {`${getLabelTranslation('administrative')} > ${getLabelTranslation(
              'ver_cadastros',
            )} > ${setPerfilTitle()}`}
          </GDMTypography>
          <GDMTypography
            variant="h4"
            textAlign="left"
            mt={3}
            mb={3}
            fontWeight={700}
            gutterBottom
            component="div"
            color="#222222"
          >
            {`${getLabelTranslation('reusableForm_ViewRegistrationOf_')} ${setPerfilTitle()}`}
          </GDMTypography>
          <GDMContainer
            sx={{
              display: 'flex',
              width: '100%',
              gap: '11px',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <GDMCustomInputLabel label="Perfil" fontSize="16px" color="#222222" />
              <TextField
                focused={false}
                variant="outlined"
                value={setPerfilTitle()}
                disabled
                sx={{
                  width: {
                    xs: '100%',
                    md: '333.5px',
                  },
                  minHeight: '48px',
                  backgroundColor: '#F2F4F9',
                  input: {
                    backgroundColor: '#F2F4F9',
                    padding: '0 0 0 14px',
                    border: 'none',
                    height: '51px',
                  },
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <GDMCustomInputLabel label={setMultOrDistTitle()} fontSize="16px" color="#222222" />
              <TextField
                focused={false}
                variant="outlined"
                value={setMultOrDistName()}
                disabled
                sx={{
                  width: {
                    xs: '100%',
                    md: '333.5px',
                  },
                  minHeight: '48px',
                  backgroundColor: '#F2F4F9',
                  input: {
                    backgroundColor: '#F2F4F9',
                    padding: '0 0 0 14px',
                    border: 'none',
                    height: '51px',
                  },
                }}
              />
            </Box>
          </GDMContainer>
          <form id="accounts-form" noValidate>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_full_name')}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={initialData?.name}
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_email')}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  value={initialData?.email}
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_country')}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  focused={false}
                  variant="outlined"
                  fullWidth
                  value={initialData?.countryName}
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_phone')}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  focused={false}
                  variant="outlined"
                  fullWidth
                  value={initialData?.phoneNumber}
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <GDMCustomInputLabel
                  label={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  focused={false}
                  variant="outlined"
                  fullWidth
                  value={initialData?.documentNumber}
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_business_name')}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  variant="outlined"
                  value={initialData?.fantasyName || ''}
                  disabled
                  fullWidth
                  sx={{
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                mb: 8,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('formfields_responsible')}
                  fontSize="16px"
                  color="#222222"
                />
                <TextField
                  placeholder={getLabelTranslation('formfields_responsible')}
                  variant="outlined"
                  fullWidth
                  value={initialData?.personInChargeName}
                  disabled
                  sx={{
                    width: {
                      xs: '100%',
                      md: '333.5px',
                    },
                    '& .Mui-disabled': {
                      backgroundColor: '#F2F4F9',
                    },
                    input: {
                      padding: '0 0 0 14px',
                      height: '48px',
                    },
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMCustomInputLabel
                  label={getLabelTranslation('header_brand')}
                  fontSize="16px"
                  color="#222222"
                />
                <Autocomplete
                  sx={{
                    '& .MuiAutocomplete-disabled': {
                      cursor: 'not-allowed',
                    },
                    '& .MuiChip-root.Mui-disabled': {
                      color: '#222222',
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      minHeight: '48px',
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    },
                    '& .MuiOutlinedInput-root': {
                      padding: '0px 9px',
                      minHeight: '48px',
                      boxSizing: 'border-box',
                    },
                    '& .MuiInputBase-input': {
                      padding: '10px 14px',
                      minHeight: 'auto',
                    },
                  }}
                  multiple
                  id="tags-outlined"
                  options={['brands']}
                  value={brands?.map(brand => brand.name)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      sx={{
                        width: {
                          xs: '100%',
                          md: '333.5px',
                        },
                        minHeight: '48px',
                        '& .MuiOutlinedInput-root': {
                          minHeight: '48px',
                        },
                        '& .Mui-disabled': {
                          backgroundColor: '#F2F4F9',
                        },
                        '& .MuiFormHelperText-root': {
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: theme.spacing(0.5),
                          backgroundColor: '#FAFAFA',
                        },
                      }}
                    />
                  )}
                  disabled
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                gap: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <GDMButton
                onClick={() => handleGoBack()}
                type="button"
                variant="outlined"
                sx={{
                  color: theme.palette.primary.dark,
                  borderColor: theme.palette.primary.main,
                  height: '51px',
                  width: {
                    xs: '100%',
                    md: '187px',
                  },
                  boxShadow: 'none',
                  ':hover': {
                    boxShadow: 'none',
                    borderColor: theme.palette.primary.dark,
                    background: theme.palette.primary.contrastText,
                  },
                }}
              >
                <FaArrowLeftLong style={{ marginRight: '8px' }} />
                {getLabelTranslation('reusableForm_GoBack_')}
              </GDMButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}
