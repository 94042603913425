import { UsersAdministratorService } from 'core/api/services/administrator';
import { OnSubmitAdminFunction } from './onSubmitAdmin.type';

const onSubmitAdmin: OnSubmitAdminFunction = async ({
  admin,
  onInit,
  onError,
  onFinally,
  onSuccess,
  handleValidate,
  onValidate,
  method = 'post',
  id,
}) => {
  if (!!onInit) onInit();

  let errors: ObjectLiteral<string> = {};

  if (!!handleValidate) {
    errors = handleValidate(admin);
  }

  if (!!onValidate) onValidate(errors);

  if (handleValidate && Object.keys(errors)?.length) return;

  const adminFormData = {
    name: admin.name,
    email: admin.contactEmail,
    phoneNumber: admin.phoneNumber,
    documentTypeId: admin.documentTypeId,
    documentNumber: admin.documentNumber,
    countryId: admin.countryId,
  };

  try {
    const response = await UsersAdministratorService.upSertAdmin(method, adminFormData, id);

    if (!!onSuccess) onSuccess(response);
  } catch (error) {
    if (!!onError) onError(error);
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default onSubmitAdmin;
