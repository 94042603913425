import { SxProps } from '@mui/material';

export const pageCardStyles: SxProps = {
  background: '#FAFAFA',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: '32px',
    sm: '32px',
    md: '16px',
    lg: '16px',
    xl: '16px',
  },
  boxShadow: {
    xs: 'none',
    sm: 'none',
    md: '0px 3.34px 10.03px 0px #2222221A',
    lg: '0px 3.34px 10.03px 0px #2222221A',
    xl: '0px 3.34px 10.03px 0px #2222221A',
  },
  borderRadius: {
    xs: '0px',
    sm: '0px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  },
  padding: {
    xs: '32px 0px',
    sm: '32px 0px',
    md: '48px 36px',
    lg: '48px 36px',
    xl: '48px 36px',
  },
};
