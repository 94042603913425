import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useState } from 'react';
import { GDMSliderComponent } from './GDMSlider.type';

const GDMSlider: GDMSliderComponent = ({ children }) => {
  const [slideIndexesList, setSlideIndexesList] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const fakeLabel = '';

  useEffect(() => {
    if (loaded && instanceRef.current) {
      const indexesList = [...Array(instanceRef.current.track.details?.slides.length).keys()];

      setSlideIndexesList(indexesList);
    }
  }, [loaded, instanceRef]);

  return (
    <div className="navigation-wrapper">
      <div ref={sliderRef} className="keen-slider">
        {children}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {slideIndexesList.map(idx => {
            return (
              <button
                key={idx}
                type="button"
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={`dot${currentSlide === idx ? ' active' : ''}`}
              >
                {fakeLabel}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GDMSlider;
