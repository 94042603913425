import { RolesServices } from 'core/api/services/roles';
import { GetRolesFunction } from './getRoles.type';

const getRoles: GetRolesFunction = async ({ onInit, onSuccess, onError, onFinally }) => {
  if (!!onInit) onInit();
  try {
    const states = await RolesServices.getRoles();

    if (!!onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (!!onError) onError(error);

    return [];
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default getRoles;
