const fieldsTransformMapper = {
  email: 'contactEmail',
};

export const errorHandler = (
  error: any,
  setFieldErrors: (errors: ObjectLiteral<string>) => void,
) => {
  if (!!error.response && !!error.response.data) {
    const resonseErrorData = error.response.data;
    if (resonseErrorData) {
      const fieldsError = Object.entries(resonseErrorData).reduce((acc, [key, value]) => {
        const formattedKey = toLowerCaseFirstLetter(key);
        const currentKey = fieldsTransformMapper[formattedKey] || formattedKey;
        acc[currentKey] = value[0];
        return acc;
      }, {});
      setFieldErrors(fieldsError);
    }
  } else {
    console.error('An unexpected error occurred', error);
  }
};

const toLowerCaseFirstLetter = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
};
