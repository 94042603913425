/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useCallback, useEffect, useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import { LuTrash2 } from 'react-icons/lu';

import { AccountsService } from 'core/api/services/accounts';
import { NavigateButton } from 'core/components/NavigateButton';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  debounce,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { GDMContainer, GDMLoading, GDMTypography } from 'components';
import { GDMTableFilter } from 'components/gdm-table-filter';

import useModal from 'hooks/useModal/useModal';
import { useNavigate } from 'react-router-dom';
import colors from '../../../core/design-system/style-guide/colors.json';
import textStyles from '../../../core/design-system/style-guide/typography.json';

export default function ManagerDistList() {
  const [rows, setRows] = useState([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderDesc, setOrderDesc] = useState(false);
  const [orderColumn, setOrderColumn] = useState<null | string>(null);
  const [itemsPerPage] = useState(4);
  const [loadingPage, setLoadingPage] = useState(true);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { user } = UserProfileStore.getState();
  const userRole = user?.role;
  const { getLabelTranslation } = useLanguageSwitcher();

  const [search, setSearch] = useState('');
  const [searchColumn, setSearchColumn] = useState('none' as string);
  const [emptyMessage, setEmptyMessage] = useState<string | undefined>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const columns: { column_name: string; column_title: string; type: string }[] = [
    {
      column_name: 'all',
      column_title: getLabelTranslation('all'),
      type: 'text',
    },
    {
      column_name: 'DistributorName',
      column_title: getLabelTranslation('brandeselector_distributor'),
      type: 'text',
    },
    {
      column_name: 'Name',
      column_title: getLabelTranslation('name'),
      type: 'text',
    },
    {
      column_name: 'FantasyName',
      column_title: getLabelTranslation('formfields_business_name'),
      type: 'text',
    },
    {
      column_name: 'PersonInChargeName',
      column_title: getLabelTranslation('formfields_responsible'),
      type: 'text',
    },
    {
      column_name: 'Brands',
      column_title: getLabelTranslation('formfields_brand'),
      type: 'text',
    },
  ];
  useEffect(() => {
    if (!loadingPage && !rows?.length) {
      const isSearching = !!search;

      if (hasError) setEmptyMessage('error_on_load_table_data_text');
      else if (isSearching) setEmptyMessage('empty_table_on_filter_text');
      else setEmptyMessage('empty_table_text');
    }
  }, [rows, loadingPage, search, hasError]);

  const changeOrder = column => {
    setOrderDesc(!orderDesc);
    setOrderColumn(column);
    setCurrentPage(1);
  };

  const roleIdOfManagerDistributor = '82f43a26-7855-4faf-88a1-4801d6181401';

  const loadAccounts = async () => {
    try {
      const response = await AccountsService.getAccounts({
        roleId: roleIdOfManagerDistributor,
        currentPage,
        itemsPerPage,
        search,
        searchColumn,
        orderDesc,
        orderColumn,
      });

      setHasError(false);
      setRows(response.items);
      setPages(response.totalPages);
    } catch (error) {
      console.error('Failed to fetch accounts', error);
      setHasError(true);
    } finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    loadAccounts();
  }, [currentPage, itemsPerPage, search, searchColumn, orderDesc]);

  const handleSearch = useCallback(
    debounce((query: any, columnName: string) => {
      setSearchColumn(columnName);
      if (query !== null && query !== '') {
        setSearch(query);
      } else {
        setSearch('');
      }
      setCurrentPage(1); // sends the user back to the first page of the searched results
    }, 1000),
    [],
  );

  if (loadingPage) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  return (
    <GDMContainer>
      <Paper
        elevation={isDesktop ? 1 : 0}
        sx={{
          padding: {
            xs: '14px',
            sm: '16px',
            md: '22px',
            lg: '34px',
            xl: '46px',
          },
          borderRadius: {
            xs: 0,
            sm: 0,
            md: '24px',
            lg: '24px',
            xl: '24px',
          },
          background: '#FAFAFA',
        }}
      >
        <GDMContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            display="block"
            gutterBottom
            sx={{
              ...textStyles.breadcrumbs,
              color: colors.dark2,
            }}
          >
            {`${getLabelTranslation('administrative')} > ${getLabelTranslation(
              'ver_cadastros',
            )} > ${getLabelTranslation('manager_distributor')}`}
          </Typography>
        </GDMContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: isDesktop ? 'row' : 'column',
          }}
        >
          <Typography
            variant="h4"
            textAlign={isDesktop ? 'left' : 'center'}
            my={2}
            gutterBottom
            color="#222222"
            sx={textStyles.pageTitle}
          >
            {getLabelTranslation('see_register_manager_distributor')}
          </Typography>
          <GDMTableFilter columns={columns} handleSearch={handleSearch} />
        </Box>
        {isDesktop ? (
          <DistributorTable emptyMessage={emptyMessage} rows={rows} changeOrder={changeOrder} />
        ) : (
          <>
            <Divider />

            <ListMobile emptyMessage={emptyMessage} rows={rows} />
          </>
        )}
        {!!rows?.length && (
          <Pagination
            count={pages}
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            variant="outlined"
            shape="rounded"
            boundaryCount={isDesktop ? 1 : 0}
            sx={{
              mt: 2,
              justifyContent: 'center',
              display: 'flex',
              '& ul > li > button': {
                backgroundColor: colors.light2,
                width: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                height: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                border: 'none',
                ...textStyles.table.pagination,
              },
              '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
                backgroundColor: colors.dark3,
              },
            }}
          />
        )}
        {userRole === 'ADMINISTRATOR' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '32px',
              '@media (max-width: 900px)': {
                button: { width: '100%' },
              },
            }}
          >
            <NavigateButton
              label={getLabelTranslation('new_register')}
              to="/accounts-register"
              icon=""
              sx={{
                width: '187px',
                height: '51px',
                fontSize: '16px',
                fontWeight: 700,
              }}
            />
          </Box>
        )}
      </Paper>
    </GDMContainer>
  );
}

function DistributorTable({
  emptyMessage,
  rows,
  changeOrder,
}: {
  emptyMessage: string;
  rows: any[];
  changeOrder: (column) => void;
}) {
  const { getLabelTranslation } = useLanguageSwitcher();
  const { user } = UserProfileStore.getState();
  const userRole = user?.role;

  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const handleDeleteClick = (rowId: any) => {
    setSelectedRow(rowId);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectedRow !== null && !event.target.closest('.delete-icon')) {
        setSelectedRow(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedRow]);

  return (
    <TableContainer component={Box}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: colors.light3 }}>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={colors.dark1} sx={textStyles.table.column}>
                  {getLabelTranslation('brandeselector_distributor')}
                </Typography>
                <IconButton
                  onClick={() => {
                    changeOrder('DistributorName');
                  }}
                >
                  <CgArrowsExchangeAltV />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={colors.dark1} sx={textStyles.table.column}>
                  {getLabelTranslation('name')}
                </Typography>
                <IconButton
                  onClick={() => {
                    changeOrder('Name');
                  }}
                >
                  <CgArrowsExchangeAltV />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={colors.dark1} sx={textStyles.table.column}>
                  {getLabelTranslation('formfields_business_name')}
                </Typography>
                <IconButton
                  onClick={() => {
                    changeOrder('FantasyName');
                  }}
                >
                  <CgArrowsExchangeAltV />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={colors.dark1} sx={textStyles.table.column}>
                  {getLabelTranslation('formfields_responsible')}
                </Typography>
                <IconButton
                  onClick={() => {
                    changeOrder('PersonInChargeName');
                  }}
                >
                  <CgArrowsExchangeAltV />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={colors.dark1} sx={textStyles.table.column}>
                  {getLabelTranslation('formfields_brand')}
                </Typography>
                <IconButton
                  onClick={() => {
                    changeOrder('Brands');
                  }}
                >
                  <CgArrowsExchangeAltV />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Typography color={colors.dark1} sx={textStyles.table.column}>
                {getLabelTranslation('visualize')}
              </Typography>
            </TableCell>
            {userRole === 'ADMINISTRATOR' && (
              <>
                <TableCell>
                  <Typography color={colors.dark1} sx={textStyles.table.column}>
                    {getLabelTranslation('edit')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color={colors.dark1} sx={textStyles.table.column}>
                    {getLabelTranslation('delete')}
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow sx={{ background: selectedRow === row.id ? '#CFEFFF' : '#fff' }} key={row.id}>
              <TableCell>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >
                  {row.distributorName || ''}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >
                  {row.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >
                  {row.fantasyName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >
                  {row.personInChargeName}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', lineHeight: '19.6px' }}>
                  {row.brands.map((brand, index) => (
                    <Typography
                      key={index}
                      variant="subtitle1"
                      sx={{ fontSize: '14px', lineHeight: '19.6px' }}
                    >
                      {brand.name}
                    </Typography>
                  ))}
                </Typography>
              </TableCell>
              <TableCell>
                <IconButton onClick={() => navigate(`/account-visualize/${row.id}`)}>
                  <IoEyeOutline color={colors.dark1} style={{ fontSize: '24px' }} />
                </IconButton>
              </TableCell>
              {userRole === 'ADMINISTRATOR' && (
                <>
                  <TableCell>
                    <IconButton onClick={() => navigate(`/account-edit/${row.id}`)}>
                      <FiEdit color={colors.dark1} style={{ fontSize: '22px' }} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton className="delete-icon" onClick={() => handleDeleteClick(row.id)}>
                      <DeleteConfirmationModal itemId={row.id} />
                    </IconButton>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!rows?.length && !!emptyMessage && (
        <GDMTypography
          sx={{
            textAlign: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}
    </TableContainer>
  );
}

function ListMobile({ emptyMessage, rows }: { emptyMessage: string; rows: any[] }) {
  const [open, setOpen] = useState(null);
  const { user } = UserProfileStore.getState();
  const userRole = user?.role;
  const { getLabelTranslation } = useLanguageSwitcher();
  const navigate = useNavigate();
  const handleClick = id => {
    setOpen(open === id ? null : id);
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader" disablePadding>
      {rows.map(item => (
        <Fragment key={item.id}>
          <ListItemButton onClick={() => handleClick(item.id)}>
            <ListItem>
              <Typography variant="body2">{getLabelTranslation('name')} </Typography>
              <Typography
                variant="body1"
                ml={1}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '20ch',
                }}
              >{` ${item.name || ''}`}</Typography>
            </ListItem>
            <IconButton
              sx={{
                color: colors.black,
                width: '32px',
                height: '32px',
                backgroundColor: open === item.id ? '#43ACE3' : '#CFEFFF',
                '&:hover': {
                  backgroundColor: '#43ACE3',
                },
                borderRadius: '50%',
              }}
            >
              {open === item.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListItemButton>
          <Collapse in={open === item.id} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2">
                  {getLabelTranslation('brandeselector_distributor')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  ml={1}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >{` ${item.distributorName}`}</Typography>
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2">
                  {getLabelTranslation('formfields_business_name')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  ml={1}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >{` ${item.fantasyName}`}</Typography>
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2">
                  {getLabelTranslation('formfields_responsible')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  ml={1}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >{` ${item.personInChargeName}`}</Typography>
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2">{getLabelTranslation('formfields_brand')} </Typography>
                {item.brands.map((brand, index) => (
                  <Typography key={index} variant="body1" ml={1}>
                    {` ${brand.name}`}
                  </Typography>
                ))}
              </ListItem>
              <ListItem sx={{ pl: 4 }}>
                <Typography variant="body2">{getLabelTranslation('actions')}</Typography>
                <GDMContainer
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ListItemIcon
                    onClick={() => navigate(`/account-visualize/${item.id}`)}
                    sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                  >
                    <IoEyeOutline style={{ fontSize: '1.3rem' }} />
                  </ListItemIcon>
                  {userRole === 'ADMINISTRATOR' && (
                    <>
                      <ListItemIcon
                        onClick={() => navigate(`/account-edit/${item.id}`)}
                        sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                      >
                        <IconButton>
                          <FiEdit style={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemIcon
                        sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                      >
                        <IconButton>
                          <DeleteConfirmationModal itemId={item.id} />
                        </IconButton>
                      </ListItemIcon>
                    </>
                  )}
                </GDMContainer>
              </ListItem>
            </List>
          </Collapse>
          <Divider />
        </Fragment>
      ))}

      {!rows?.length && !!emptyMessage && (
        <GDMTypography
          sx={{
            textAlign: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}
    </List>
  );
}

function DeleteConfirmationModal({ itemId }: { itemId: string }) {
  const { getLabelTranslation } = useLanguageSwitcher();
  const { openModal, closeModal } = useModal();
  const [accountSelected, setAccountSelected] = useState(null);

  const handleClickOpen = async () => {
    try {
      const data = await AccountsService.getAccountId(itemId);
      setAccountSelected(data);

      const { canBeDeleted } = data;

      if (!canBeDeleted) {
        openModal({
          type: 'error',
          title: getLabelTranslation('modal_title_delete_register_error'),
          message: getLabelTranslation('modal_message_bloq_dependencies'),
          onClose: closeModal,
        });
      } else {
        openModal({
          type: 'delete',
          title: getLabelTranslation('modal_title_delete_register'),
          message: getLabelTranslation('modal_message_irreversible'),
          primaryAction: {
            label: getLabelTranslation('delete'),
            action: handleDeleteAccount,
          },
          secondAction: {
            label: getLabelTranslation('changePasswordModal_cancel'),
            action: closeModal,
          },
        });
      }
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_title_delete_register_error'),
        message: getLabelTranslation('modal_message_delete_register_error'),
        onClose: closeModal,
      });
    }
  };

  const handleCloseModalFeedback = () => {
    window.location.reload();
  };

  const handleDeleteAccount = async () => {
    try {
      await AccountsService.deleteAccountId(itemId);

      openModal({
        type: 'success',
        title: getLabelTranslation('modal_title_delete_register_success'),
        onClose: handleCloseModalFeedback,
      });
    } catch (error) {
      openModal({
        type: 'error',
        title: getLabelTranslation('modal_title_delete_register_error'),
        message: getLabelTranslation('modal_message_delete_register_error'),
        onClose: closeModal,
      });
    }
  };

  return <LuTrash2 onClick={handleClickOpen} color={colors.dark1} style={{ fontSize: '22px' }} />;
}
