import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const imageFileFieldContainerStyles: SxProps = {
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

export const imageFileFieldContentStyles: SxProps = {
  border: '1px dashed #A1A9B8',
  borderRadius: '6px',
  padding: '16px',
  position: 'relative',
  textAlign: 'center',
  height: '100%',
  minHeight: '148px', // Height without errors
  maxHeight: '171.37px', // Height with errors
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  background: '#fff',
};

export const secureImageStyles: CSSProperties = {
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
};

export const fileBoxTextStyles: SxProps = {
  color: '#A1A9B8',
  fontSize: '14px!important',
  fontWeight: 400,
  lineHeight: 'normal!important',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
};

export const fileBoxTextRequiredIndicatorStyles: CSSProperties = {
  color: '#FB2047',
};

export const inputFileStyles: CSSProperties = {
  display: 'none',
};

export const imageFileFieldErrorMessageStyles: SxProps = {
  color: '#FB2047',
  marginTop: '0px',
  paddingTop: '4px',
  height: '24px',
};
