import { SxProps } from '@mui/material';

export const pageCardHeaderStyles: SxProps = {
  padding: '0px',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
};

export const pageCardHeaderBreadcrumbsStyles: SxProps = {
  color: '#A1A9B8',
  fontSize: '14px!important',
  lineHeight: '19.6px!important',
  display: 'block',
  fontWeight: 400,
  textAlign: {
    xs: 'center',
    sm: 'center',
    md: 'left',
    lg: 'left',
    xl: 'left',
  },
};

export const pageCardHeaderTitleStyles: SxProps = {
  color: '#222222',
  fontWeight: 700,
  lineHeight: '35.2px!important',
  fontSize: '32px!important',
  textAlign: {
    xs: 'center',
    sm: 'center',
    md: 'left',
    lg: 'left',
    xl: 'left',
  },
};
