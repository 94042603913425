import {
  DynamicFormData,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dynamicFormValidateForm, getBrands, getCities, getGDMSellers, getStates } from 'utils';
import { errorHandler } from 'utils/form/errorHandler';
import { MultiplierRegisterPageComponent } from '../MultiplierRegisterPage.type';
import { createMultiplier } from '../utils';

const DEFAULT_MULTIPLIER: Partial<DynamicFormData> = {
  address: '',
  brandIds: undefined,
  cityId: '',
  contactEmail: '',
};

const MultiplierRegisterPage: MultiplierRegisterPageComponent = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const {
    user: { countryName, countryId },
  } = useStore(UserProfileStore);

  const [multiplier, setMultiplier] = useState<Partial<DynamicFormData>>(DEFAULT_MULTIPLIER);
  const [errors, setErrors] = useState({});
  const [brands, setBrands] = useState([]);
  const [sellersGDM, setSellersGDM] = useState([]);
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entityType, setEntityType] = useState(getLabelTranslation('brandeselector_multiplier'));
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    const loadData: Array<Promise<any>> = [loadBrands(), loadSellersGDM(), loadStates()];
    setMultiplier(prev => ({ ...prev, countryId }));
    Promise.all(loadData).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (multiplier.ufId) {
      loadCities();
    }
  }, [multiplier.ufId]);

  useEffect(() => {
    if (multiplier.sellerId) {
      const seller = sellersGDM.find(seller => seller.id === multiplier.sellerId);
      if (seller.brands.length === 1) {
        setMultiplier(prev => ({ ...prev, brandIds: seller.brands.map(brand => brand.id) }));
      }
    }
  }, [multiplier.sellerId]);

  useEffect(() => {
    setEntityType(getLabelTranslation('brandeselector_multiplier'));
  }, [currentLanguage]);

  useEffect(() => {
    if (multiplier.documentNumber) {
      setDocumentTypeId();
    }
  }, [multiplier.documentNumber]);

  useEffect(() => {
    console.error(errors);
  }, [errors]);

  const loadBrands = () => {
    return getBrands({
      countryId,
      onError: () => console.error('Error on load brands!'),
      onInit: () => setIsLoading(true),
      onSuccess: brands => setBrands(brands),
    });
  };
  const loadStates = () => {
    return getStates({
      countryId,
      onSuccess: states => setStates(states),
      onInit: () => setIsLoading(true),
      onError: () => console.error('Error on load states!'),
       source: "multiplier"
    });
  };
  const loadCities = () => {
    return getCities({
      stateId: multiplier.ufId,
      onError: () => console.error('Error on load cities!'),
      onSuccess: cities => setCities(cities),
    });
  };
  const loadSellersGDM = () => {
    return getGDMSellers({
      payload: {
        currentPag: 1,
        itemsPerPage: 999999999,
      },
      onSuccess: sellersGDM => setSellersGDM(sellersGDM.items),
    });
  };

  const handleValidate = multiplierFormData =>
    dynamicFormValidateForm({
      formData: multiplierFormData,
      countryName,
      entityType,
      getLabelTranslation,
    });

  const onValidate = errors => {
    if (Object.keys(errors)?.length) {
      setIsSubmiting(false);
    }
    setErrors(errors);
  };

  const handleChange = (e: any) => {
    const {
      target: { value, name },
    } = e;

    setMultiplier(multiplierData => ({ ...multiplierData, [name]: value }));
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      onClose: closeModal,
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const onError = errors => {
    errorHandler(errors, setErrors);

    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_register_error'),
      message: getLabelTranslation('modal_message_register_error'),
      onClose: closeModal,
    });
  };

  const onInit = () => {
    setIsSubmiting(true);
  };

  const onSuccess = () => {
    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_register_success'),
      onClose: () => navigate('/multiplier-list'),
      primaryAction: {
        label: 'Ok',
        action: () => {
          closeModal();
          navigate('/multiplier-list');
        },
      },
    });
  };

  const onFinally = () => {
    setIsSubmiting(false);
  };

  const handleSubmit = async () => {
    await createMultiplier({
      multiplier,
      onError,
      onInit,
      onFinally,
      onSuccess,
      handleValidate,
      onValidate,
    });
  };

  const setDocumentTypeId = () => {
    if (countryName === 'Argentina') {
      setMultiplier(prev => ({
        ...prev,
        documentTypeId: 'b85d6c71-e469-4268-a813-1bac98f596ae',
      }));
    }

    let onlyNumbers: number;

    if (multiplier.documentNumber) {
      onlyNumbers = multiplier.documentNumber.replace(/\D/g, '').length;
    }

    if (countryName === 'Brasil' && onlyNumbers === 11) {
      setMultiplier(prev => ({
        ...prev,
        documentTypeId: 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d',
        fantasyName: '',
      }));
    } else if (countryName === 'Brasil' && onlyNumbers === 14) {
      setMultiplier(prev => ({
        ...prev,
        documentTypeId: 'cc49f596-0841-4708-9ef8-6e372ea70ddf',
      }));
    }
  };

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'cadastrar_multiplicador',
      )}`}
      title={getLabelTranslation('cadastrar_multiplicador')}
    >
      <GDMConditionalRender canRender={isLoading}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>

      <GDMConditionalRender canRender={!isLoading}>
        <GDMDynamicFormFields
          {...{
            entityType,
            viewType: 'create',
            formData: multiplier,
            isSubmiting,
            errors,
            formDataEvents: {
              onCancel: handleCancel,
              onChange: handleChange,
              onSubmit: handleSubmit,
            },
            formDataOptions: {
              cities,
              gdmSellers: sellersGDM,
              brands,
              roles: [],
              states,
            },
          }}
        />
      </GDMConditionalRender>
    </GDMPageCard>
  );
};

export default MultiplierRegisterPage;
