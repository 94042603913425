import { useEffect, useState } from 'react';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { getDocumentLabel } from 'core/utils/getDocumentLabel/getDocumentLabel';
import { getDocumentMask } from 'core/utils/getDocumentMask/getDocumentMask';
import { useStore } from 'effector-react';

import { alpha, Grid, useMediaQuery, useTheme } from '@mui/material';

import {
  GDMBox,
  GDMButton,
  GDMConditionalRender,
  GDMContainer,
  GDMGrid,
  GDMLoading,
} from 'components';

import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { MaskTypes } from 'core/typing/types/common/mask.type';
import getMask from 'utils/get-mask/getMask';
import { DynamicFormFieldControl } from './components/dynamic-form-field-control';
import { buttonStyle, formContainerStyles } from './GDMDynamicFormFields.styles';
import { DynamicFormData, GDMDynamicFormFieldsComponent } from './GDMDynamicFormFields.type';

const GDMDynamicFormFields: GDMDynamicFormFieldsComponent = ({
  entityType,
  errors,
  isSubmiting,
  formData,
  viewType,
  formDataOptions,
  formDataEvents,
}) => {
  const { onCancel, onChange, onSubmit } = formDataEvents;

  const { states, brands, cities, regions, multipliers, gdmSellers } = formDataOptions;

  const {
    user: { countryName },
  } = useStore(UserProfileStore);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { getLabelTranslation } = useLanguageSwitcher();

  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isBrandsDisable, setIsBrandsDisable] = useState(false);

  const [enableFieldsByGDMSeller, setEnableFieldsByGDMSeller] = useState(false);
  const [enabledFieldsByMultiplier, setEnabledFieldsByMultipler] = useState(false);
  const [phoneMask, setPhoneMask] = useState<string>();
  const [cityInputValue, setCityInputValue] = useState(formData?.cityName);
  const [sellerGdmInputValue, serSellerGdmInputValue] = useState(formData?.sellerGdmName);
  const [multiplierInputValue, serMultiplierInputValue] = useState(formData?.sellerGdmName);
  const [brandsInputValue, setBrandsInputValue] = useState<Partial<DynamicFormData>>();

  useEffect(() => {
    switch (viewType) {
      case 'edit':
        setIsEdit(true);
        setIsCreate(false);
        setIsView(false);
        break;
      case 'view':
        setIsEdit(false);
        setIsCreate(false);
        setIsView(true);
        break;
      default:
        setIsEdit(false);
        setIsCreate(true);
        setIsView(false);
        break;
    }
  }, [viewType]);

  useEffect(() => {
    if (!formData.documentNumber) return;
    const documentNumber = formData.documentNumber.replace(/\D/g, '');
    setIsCompany(documentNumber.length > 11);
  }, [formData.documentNumber]);

  useEffect(() => {
    if (!formData.sellerId && !formData.multiplierId && entityType === 'Distribuidor') {
      setEnableFieldsByGDMSeller(false);
      setEnabledFieldsByMultipler(false);
    } else if (!formData.sellerId && entityType === 'Multiplicador') {
      setEnableFieldsByGDMSeller(false);
    } else {
      setEnableFieldsByGDMSeller(true);
      setEnabledFieldsByMultipler(true);
    }
  }, [entityType, formData.sellerId, formData.multiplierId]);

  useEffect(() => {
    const isBlockedEntities = [
      `${getLabelTranslation('brandeselector_multiplier')}`,
      `${getLabelTranslation('brandeselector_distributor')}`,
    ].includes(entityType);

    setIsBrandsDisable(!isCreate || isBlockedEntities);
  }, [isCreate, entityType]);

  useEffect(() => {
    const phoneMask: string = getMask({
      countryName: countryName as CountriesDomainEnum,
      type: MaskTypes.PHONE_NUMBER,
    });

    setPhoneMask(phoneMask);
  }, [countryName]);

  const countryOfUser = countryName;

  const handleBlur = document => {
    const maskedValue = applyMask(document);
    onChange({ target: { name: 'documentNumber', value: maskedValue } });
  };

  const applyMask = value => {
    if (!value) return '';
    return value.replace(/\D/g, '').length > 11
      ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const marginOfButtonsContainer = () => {
    if (entityType === 'Multiplicador' || entityType === 'Distribuidor') {
      return '24px';
    }

    if (entityType === 'Administrador') {
      return '316px';
    }

    if (entityType === 'Vendedor GDM') {
      return '124px';
    }

    return '0';
  };

  const viewCheckValue = (returnTrue, returnFalse) => {
    if (isView) {
      return returnTrue;
    }
    return returnFalse;
  };

  const verifyVisibility = (condition1, condition2, condition3) => {
    if (condition1 || condition2 || condition3) {
      return true;
    }
    return false;
  };

  const buttonContent = isSubmiting ? (
    <GDMLoading type="circular" size={24} />
  ) : (
    getLabelTranslation('formfields_save')
  );

  return (
    <GDMContainer
      component="form"
      sx={formContainerStyles}
      {...{ noValidate: true, autoComplete: 'off' }}
    >
      <GDMGrid container spacing={2}>
        <GDMConditionalRender canRender={entityType === 'Vendedor GDM'}>
          <Grid item xs={12} sm={12} md={3}>
            <DynamicFormFieldControl
              type="text-field"
              id="role-fixed"
              name="role-fixed"
              label={viewCheckValue(
                getLabelTranslation('customRolesInputs_profile'),
                getLabelTranslation('customRolesInputs_select_profile'),
              )}
              required={!isView}
              placeholder=""
              value="Vendedor GDM"
              disabled
            />
          </Grid>
        </GDMConditionalRender>

        <GDMConditionalRender
          canRender={entityType === 'Distribuidor' || entityType === 'Multiplicador'}
        >
          <Grid item xs={12} sm={12} md={3}>
            <DynamicFormFieldControl
              type={viewCheckValue('text-field', 'auto-complete')}
              id="gdm-seller"
              name="gdm-seller"
              label={getLabelTranslation('customRolesInputs_select_gdm_seller')}
              placeholder={viewCheckValue(
                '',
                getLabelTranslation('customRolesInputs_select_gdm_seller'),
              )}
              disabled={isView}
              options={gdmSellers}
              getOptionLabel={option => option.name}
              getOptionKey={option => option.id}
              defaultValue={() => {
                const seller = gdmSellers.find(seller => {
                  return seller.id === formData.sellerId;
                });
                return seller;
              }}
              value={viewCheckValue(
                formData.sellerGdmName,
                gdmSellers?.find(seller => seller.id === formData.sellerId),
              )}
              onChange={(_, newValue) => {
                serSellerGdmInputValue(newValue?.name ? newValue.name : '');
                onChange({ target: { value: newValue?.id ? newValue.id : '', name: 'sellerId' } });
              }}
              error={!!errors?.sellerId}
              helperText={errors?.sellerId}
              required={!formData.multiplierId}
              inputValue={sellerGdmInputValue}
              onChangeInput={e => serSellerGdmInputValue(e.target.value)}
            />
          </Grid>
        </GDMConditionalRender>

        <GDMConditionalRender canRender={entityType === 'Distribuidor'}>
          <Grid item xs={12} sm={12} md={3}>
            <DynamicFormFieldControl
              type={viewCheckValue('text-field', 'auto-complete')}
              id="multiplier"
              name="multiplier"
              label={getLabelTranslation('customRolesInputs_select_multiplier')}
              placeholder={viewCheckValue(
                '',
                getLabelTranslation('customRolesInputs_select_multiplier'),
              )}
              disabled={isView}
              options={multipliers}
              getOptionLabel={option => option.name}
              getOptionKey={option => option.id}
              value={viewCheckValue(
                formData.multiplierId,
                multipliers?.find(multiplier => multiplier.id === formData.multiplierId),
              )}
              onChange={(_, newValue) => {
                onChange({
                  target: { value: newValue?.id ? newValue.id : '', name: 'multiplierId' },
                });
                serMultiplierInputValue(newValue?.name ? newValue.name : '');
              }}
              error={!!errors?.multiplierId}
              helperText={errors?.multiplierId}
              required={!formData.sellerId}
              inputValue={multiplierInputValue}
              onChangeInput={e => serMultiplierInputValue(e.target.value)}
            />
          </Grid>
        </GDMConditionalRender>
      </GDMGrid>

      <GDMGrid container spacing={2}>
        <GDMGrid item xs={12} sm={12} md={6}>
          <DynamicFormFieldControl
            type="text-field"
            id="name"
            name="name"
            label={getLabelTranslation('formfields_full_name')}
            required={!isView}
            placeholder={getLabelTranslation('formfields_full_name')}
            disabled={verifyVisibility(
              isView,
              !enableFieldsByGDMSeller,
              !enabledFieldsByMultiplier,
            )}
            value={formData?.name}
            onChange={e => onChange({ target: { value: e.target.value, name: 'name' } })}
            error={!!errors?.name}
            helperText={errors?.name}
          />
        </GDMGrid>
        <GDMGrid item xs={12} sm={12} md={6}>
          <DynamicFormFieldControl
            type="text-field"
            id="contactEmail"
            name="contactEmail"
            label={getLabelTranslation('formfields_email')}
            required={!isView}
            placeholder={getLabelTranslation('formfields_email')}
            disabled={
              verifyVisibility(isView, !enableFieldsByGDMSeller, !enabledFieldsByMultiplier) ||
              (isEdit && entityType === 'Administrador') ||
              (isEdit && entityType === 'Vendedor GDM')
            }
            value={formData?.contactEmail}
            onChange={e => onChange({ target: { value: e.target.value, name: 'contactEmail' } })}
            error={!!errors?.contactEmail}
            helperText={errors?.contactEmail}
          />
        </GDMGrid>
        <GDMGrid item xs={12} sm={12} md={3}>
          <DynamicFormFieldControl
            type="text-field"
            id="country"
            name="country"
            label={getLabelTranslation('formfields_country')}
            required={!isView}
            placeholder={getLabelTranslation('formfields_country')}
            disabled
            value={countryOfUser}
          />
        </GDMGrid>
        <GDMGrid item xs={12} sm={12} md={3}>
          <DynamicFormFieldControl
            type="text-field-mask"
            id="phoneNumber"
            name="phoneNumber"
            label={getLabelTranslation('formfields_phone')}
            required={!isView}
            placeholder={getLabelTranslation('formfields_phone')}
            disabled={verifyVisibility(
              isView,
              !enableFieldsByGDMSeller,
              !enabledFieldsByMultiplier,
            )}
            value={formData?.phoneNumber}
            onChange={e => onChange({ target: { value: e.target.value, name: 'phoneNumber' } })}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber}
            mask={phoneMask}
          />
        </GDMGrid>
        <GDMGrid item xs={12} sm={12} md={3}>
          <DynamicFormFieldControl
            type="text-field-mask"
            id="documentNumber"
            name="documentNumber"
            label={getDocumentLabel({ entityType, countryOfUser })}
            required={!isView}
            placeholder={getDocumentLabel({ entityType, countryOfUser })}
            disabled={verifyVisibility(
              isView,
              !enableFieldsByGDMSeller,
              !enabledFieldsByMultiplier,
            )}
            value={formData.documentNumber}
            onChange={e => onChange({ target: { value: e.target.value, name: 'documentNumber' } })}
            error={!!errors?.documentNumber}
            helperText={errors?.documentNumber}
            mask={getDocumentMask({ entityType, countryOfUser })}
            maskChar={null}
            onBlur={
              countryOfUser === 'Brasil' ? () => handleBlur(formData.documentNumber) : undefined
            }
          />
        </GDMGrid>
        <GDMConditionalRender canRender={entityType !== 'Administrador'}>
          <GDMGrid item xs={12} sm={12} md={3}>
            <DynamicFormFieldControl
              type="text-field"
              id="fantasyName"
              name="fantasyName"
              label={getLabelTranslation('formfields_business_name')}
              required={!isView && isCompany}
              placeholder={getLabelTranslation('formfields_business_name')}
              disabled={
                verifyVisibility(isView, !enableFieldsByGDMSeller, !enabledFieldsByMultiplier) ||
                !isCompany
              }
              value={formData?.fantasyName}
              onChange={e => onChange({ target: { value: e.target.value, name: 'fantasyName' } })}
              error={!!errors?.fantasyName}
              helperText={errors?.fantasyName}
            />
          </GDMGrid>
        </GDMConditionalRender>

        <GDMGrid
          container
          sx={{
            marginTop: '0px!important',
            marginLeft: '0px!important',
          }}
          spacing={2}
          xs={12}
          sm={12}
          md={entityType === 'Vendedor GDM' ? 6 : 9}
        >
          <GDMConditionalRender canRender={entityType !== 'Administrador'}>
            <GDMGrid item xs={12} sm={12} md={6}>
              <DynamicFormFieldControl
                type="text-field"
                id="personInChargeName"
                name="personInChargeName"
                label={getLabelTranslation('formfields_responsible')}
                required={!isView}
                placeholder={getLabelTranslation('formfields_responsible')}
                disabled={verifyVisibility(
                  isView,
                  !enableFieldsByGDMSeller,
                  !enabledFieldsByMultiplier,
                )}
                value={formData?.personInChargeName}
                onChange={e =>
                  onChange({ target: { value: e.target.value, name: 'personInChargeName' } })
                }
                error={!!errors?.personInChargeName}
                helperText={errors?.personInChargeName}
              />
            </GDMGrid>
          </GDMConditionalRender>

          <GDMConditionalRender
            canRender={entityType !== 'Administrador' && entityType !== 'Vendedor GDM'}
          >
            <GDMGrid item xs={12} sm={12} md={6}>
              <DynamicFormFieldControl
                type="text-field"
                id="address"
                name="address"
                label={getLabelTranslation('formfields_adress')}
                required={!isView}
                placeholder={getLabelTranslation('formfields_adress')}
                disabled={verifyVisibility(
                  isView,
                  !enableFieldsByGDMSeller,
                  !enabledFieldsByMultiplier,
                )}
                value={formData?.address}
                onChange={e => onChange({ target: { value: e.target.value, name: 'address' } })}
                error={!!errors?.address}
                helperText={errors?.address}
              />
            </GDMGrid>

            <GDMGrid item xs={12} sm={12} md={4}>
              <DynamicFormFieldControl
                type={viewCheckValue('text-field', 'select')}
                id="state"
                name="state"
                label={getLabelTranslation('formfields_state')}
                required={!isView}
                placeholder={getLabelTranslation('formfields_state')}
                disabled={
                  verifyVisibility(isView, !enableFieldsByGDMSeller, !enabledFieldsByMultiplier) ||
                  !formData?.countryId
                }
                defaultValue={states.find(uf => uf.id === formData?.ufId)?.id}
                value={viewCheckValue(
                  formData.stateName,
                  states.find(uf => uf.id === formData?.ufId)?.id,
                )}
                onChange={e => {
                  onChange({ target: { value: e.target.value, name: 'ufId' } });
                  setCityInputValue('');
                }}
                options={states}
                error={!!errors?.ufId}
                helperText={errors?.ufId}
              />
            </GDMGrid>

            <GDMGrid item xs={12} sm={12} md={4}>
              <DynamicFormFieldControl
                type={viewCheckValue('text-field', 'auto-complete')}
                id="city"
                name="city"
                label={getLabelTranslation('formfields_label_city')}
                required={!isView}
                placeholder={getLabelTranslation('formfields_label_city')}
                disabled={
                  verifyVisibility(isView, !enableFieldsByGDMSeller, !enabledFieldsByMultiplier) ||
                  !formData?.ufId
                }
                onChange={(_, newValue) => {
                  onChange({ target: { value: newValue?.id ? newValue.id : '', name: 'cityId' } });
                  setCityInputValue(newValue?.name ? newValue.name : '');
                }}
                options={cities}
                getOptionKey={option => option.id}
                getOptionLabel={option => option.name}
                defaultValue={cities.find(city => city.id === formData.cityId)}
                value={viewCheckValue(
                  formData.cityName,
                  cities?.find(city => city.id === formData.cityId),
                )}
                error={!!errors?.cityId}
                helperText={errors?.cityId}
                inputValue={cityInputValue}
                onChangeInput={e => setCityInputValue(e.target.value)}
              />
            </GDMGrid>
          </GDMConditionalRender>

          <GDMConditionalRender canRender={entityType !== 'Administrador'}>
            <GDMGrid item xs={12} sm={12} md={entityType === 'Vendedor GDM' ? 6 : 4}>
              <DynamicFormFieldControl
                type="auto-complete"
                id="brand"
                name="brand"
                label={getLabelTranslation('formfields_brand')}
                placeholder={
                  brandsInputValue?.brandIds?.length > 0
                    ? ''
                    : getLabelTranslation('formfields_brand')
                }
                required={!isView}
                options={brands?.filter(brand => brand.isAdministratorBrand === false)}
                value={
                  brands.filter(
                    brand =>
                      brandsInputValue?.brandIds.includes(brand.id) ||
                      formData?.brandIds?.includes(brand.id),
                  ) || null
                }
                getOptionLabel={option => option.name}
                defaultValue={brands.filter(brand => formData?.brandIds?.includes(brand.id))}
                onChange={(_, newValue) => {
                  const updatedBrandIds = newValue ? newValue.map(brand => brand.id) : [];

                  setBrandsInputValue(prev => ({
                    ...prev,
                    brandIds: updatedBrandIds,
                  }));

                  onChange({
                    target: {
                      value: updatedBrandIds,
                      name: 'brandIds',
                    },
                  });
                }}
                getOptionKey={option => option.id}
                disabled={isBrandsDisable}
                readOnly={[
                  `${getLabelTranslation('brandeselector_multiplier')}`,
                  `${getLabelTranslation('brandeselector_distributor')}`,
                ].includes(entityType)}
                error={!!errors.brandIds}
                helperText={errors.brandIds}
                filterSelectedOptions
                multiple
              />
            </GDMGrid>
          </GDMConditionalRender>
        </GDMGrid>

        <GDMConditionalRender canRender={entityType === 'Vendedor GDM'}>
          <GDMGrid item xs={12} sm={12} md={3}>
            <DynamicFormFieldControl
              type="select"
              id="region"
              name="region"
              label={getLabelTranslation('formfields_region')}
              required={!isView}
              placeholder={getLabelTranslation('formfields_region')}
              disabled={isView}
              value={formData?.regionId}
              onChange={e => onChange({ target: { value: e.target.value, name: 'regionId' } })}
              options={regions}
              error={!!errors?.regionId}
              helperText={errors?.regionId}
            />
          </GDMGrid>
        </GDMConditionalRender>

        <GDMConditionalRender
          canRender={entityType !== 'Administrador' && entityType !== 'Vendedor GDM'}
        >
          <GDMGrid
            sx={{
              marginTop: '0px!important',
              marginLeft: '0px!important',
            }}
            container
            spacing={2}
            xs={12}
            sm={12}
            md={3}
          >
            <GDMGrid item xs={12} sm={12} md={12}>
              <DynamicFormFieldControl
                type="image-file"
                id="logoFile"
                name="logoFile"
                label={getLabelTranslation('formfields_company_logo')}
                required={!isView}
                placeholder={getLabelTranslation('formfields_upload_logo')}
                value={formData?.logoFile}
                disabled={verifyVisibility(
                  isView,
                  !enableFieldsByGDMSeller,
                  !enabledFieldsByMultiplier,
                )}
                defaultValue={formData?.logoFile}
                onChange={e => {
                  onChange({ target: { value: e, name: 'logoFile' } });
                }}
                error={!!errors.logoFile}
                helperText={errors.logoFile}
              />
            </GDMGrid>
          </GDMGrid>
        </GDMConditionalRender>

        <GDMConditionalRender canRender={!isDesktop && !isView}>
          <GDMContainer
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '12px',
              marginTop: '32px',
              marginLeft: '14px',
            }}
          >
            <GDMButton
              onClick={onCancel}
              disabled={isSubmiting}
              sx={{
                height: '51px',
                width: '100%',
                boxShadow: 'none',
                color: '#222222',
              }}
              color="secondary"
              variant="contained"
            >
              {getLabelTranslation('formfields_cancel')}
            </GDMButton>
            <GDMButton
              variant="contained"
              onClick={onSubmit}
              sx={{
                height: '51px',
                width: '100%',
                boxShadow: 'none',
              }}
              disabled={isSubmiting}
            >
              {isSubmiting ? (
                <GDMLoading type="circular" {...{ size: 24 }} />
              ) : (
                getLabelTranslation('formfields_save')
              )}
            </GDMButton>
          </GDMContainer>
        </GDMConditionalRender>
      </GDMGrid>
      <GDMConditionalRender canRender={isDesktop && !isView}>
        <GDMBox
          sx={{
            display: 'flex',
            justifyContent: 'end',
            gap: '24px',
            mt: marginOfButtonsContainer(),
          }}
        >
          <GDMButton
            onClick={onCancel}
            disabled={isSubmiting}
            sx={{
              background: theme.palette.secondary.main,
              padding: '14px 64px',
              width: '187px',
              ':hover': {
                background: theme.palette.primary.light,
              },
              '&.Mui-disabled': {
                background: theme.palette.action.disabled,
                color: 'white',
              },
            }}
          >
            {getLabelTranslation('formfields_cancel')}
          </GDMButton>
          <GDMButton onClick={onSubmit} sx={buttonStyle({ theme })} disabled={isSubmiting}>
            {buttonContent}
          </GDMButton>
        </GDMBox>
      </GDMConditionalRender>
    </GDMContainer>
  );
};

export default GDMDynamicFormFields;
