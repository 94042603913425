/* eslint-disable no-lonely-if */
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';

import { GDMButton } from 'components/gdm-button';
import { GDMContainer } from 'components/gdm-container';
import { GDMCustomInputLabel } from 'components/gdm-custom-input-label';
import { GDMDrawerMap } from 'components/gdm-drawer-map';
import { GDMFormControl } from 'components/gdm-form-control';
import { GDMFormHelperText } from 'components/gdm-form-helper-text';
import { GDMGrid } from 'components/gdm-grid';
import { GDMMapTypes } from 'components/gdm-maps/GDMMaps.type';
import { GDMMenuItem } from 'components/gdm-menu-item';
import { GDMSelect } from 'components/gdm-select';
import { GDMTextField } from 'components/gdm-text-field';
import { GDMTypography } from 'components/gdm-typography';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useEffect, useState } from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { IoIosArrowDown } from 'react-icons/io';
import { PiMapPinAreaLight } from 'react-icons/pi';

const GDMFarmRegisterForm = ({
  formData,
  errors,
  handleChangeValues,
  handleStateChange,
  states,
  cities,
  handleCityChange,
  setCoordinates,
  latLong = undefined,
  disabledFields = false,
  openModalState = false,
  setSaveLatLng = undefined,
  viewType = 'create',
  farmerName = '',
  handleGetStarterCoordinations = undefined,
  typeIcon,
  citySearchText = '',
  hasInvalidLatLong = false,
}) => {
  const theme = useTheme();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [showButton, setShowButton] = useState(true);
  const [showLatLngField, setShowLatLngField] = useState(false);

  const [farmCoordinates, setFarmCoordinates] = useState('');
  const [isAPILoaded, setIsAPILoaded] = useState(false)

  const [initialCoordinates, setInitialCoordinates] = useState({
    lat: Number(latLong?.lat || '0'),
    lng: Number(latLong?.lng || '0'),
  });

  const setOpenModal = (v: boolean) => {
    setSaveLatLng(prev => ({ ...prev, openModalFarm: v }));
  };

  const handleSaveLatLng = () => {
    setShowButton(false);
    setShowLatLngField(true);
    setOpenModal(false);
    setCoordinates({ lat: initialCoordinates.lat, lng: initialCoordinates.lng });

    if (setSaveLatLng) {
      setSaveLatLng(prev => ({ ...prev, farm: true }));
    }
  };

  const handleResetState = () => {
    if (viewType === 'view') {
      setShowLatLngField(true);
      setShowButton(false);
    }

    const invalidLatLongValues = [undefined, 'undefined', 0, '0'];

    const isValidLat = !!latLong && !invalidLatLongValues.includes(latLong?.lat);
    const isValidLng = !!latLong && !invalidLatLongValues.includes(latLong?.lng);

    if (isValidLat && isValidLng) {
      setInitialCoordinates({ lat: Number(latLong?.lat), lng: Number(latLong?.lng) });
      setFarmCoordinates(`${latLong.lat},${latLong.lng}`);
    }
  };

  useEffect(() => {
    const isInvalidLat = !initialCoordinates?.lat;
    const isInvalidLng = !initialCoordinates?.lng;

    if (isInvalidLat && isInvalidLng && !!citySearchText && hasInvalidLatLong) {
      getCityCoordinates(citySearchText);
    }
  }, [citySearchText, initialCoordinates, farmCoordinates, hasInvalidLatLong]);

  const getCityCoordinates = async citySearch => {
    try {
      if (citySearch) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: citySearch }, (results, status) => {
          if (status === 'OK') {
            const { lat, lng } = results[0].geometry.location;
            setInitialCoordinates({ lat: lat(), lng: lng()});
            setFarmCoordinates(`${lat()},${lng()}`);

          } else {
            console.error('Erro in geocodification: ', status);
          }})
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => setIsAPILoaded(true);
    document.head.appendChild(script);
    setIsAPILoaded(true);
  }, []);

  useEffect(() => {
    handleResetState();
  }, [viewType, latLong]);

  return (
    <>
      <GDMGrid container spacing={2} sx={{ marginLeft: 0, width: '100%', marginTop: '12px' }}>
        <GDMGrid xs={12} sm={12} md={6} sx={{ textAlign: 'start' }}>
          <GDMCustomInputLabel
            label={getLabelTranslation('farm_name')}
            required={viewType !== 'view'}
            fontSize="16px"
            color="#222222"
          />
          <GDMFormControl fullWidth>
            <GDMTextField
              focused={false}
              id="farmName"
              variant="outlined"
              placeholder={getLabelTranslation('farm_name')}
              fullWidth
              value={formData.farmName}
              name="farmName"
              onChange={handleChangeValues}
              disabled={disabledFields}
              sx={{ background: disabledFields && '#F2F4F9' }}
            />
            {!!errors.farmName && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.farmName}</GDMFormHelperText>
            )}
          </GDMFormControl>
        </GDMGrid>

        {/* ESTADO */}
        <GDMGrid
          xs={12}
          sm={12}
          md={3}
          sx={{
            textAlign: 'start',
            paddingTop: 0,
            paddingLeft: '16px',
            '@media (max-width:1200px)': {
              paddingLeft: 0,
              paddingTop: '16px',
            },
          }}
        >
          <GDMCustomInputLabel
            label={getLabelTranslation('formfields_state')}
            required={viewType !== 'view'}
            fontSize="16px"
            color="#222222"
          />
          <GDMFormControl fullWidth>
            <GDMSelect
              sx={{
                fontSize: '14px',
                marginTop: '5px',
                height: '48px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19px',
                background: disabledFields && '#F2F4F9',
                color: '#A1A9B8',
              }}
              IconComponent={IoIosArrowDown}
              value={formData.stateId}
              onChange={handleStateChange}
              variant="outlined"
              fullWidth
              disabled={disabledFields}
            >
              <GDMMenuItem value="0">{getLabelTranslation('formfields_state')}</GDMMenuItem>
              {states.map(state => (
                <GDMMenuItem key={state.id} value={state.id}>
                  {state.name}
                </GDMMenuItem>
              ))}
            </GDMSelect>
            {!!errors.stateId && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.stateId}</GDMFormHelperText>
            )}
          </GDMFormControl>
        </GDMGrid>

        {/* CIDADE */}
        <GDMGrid
          xs={12}
          sm={12}
          md={3}
          sx={{
            textAlign: 'start',
            paddingTop: 0,
            paddingLeft: '16px',
            '@media (max-width:1200px)': {
              paddingLeft: 0,
              paddingTop: '16px',
            },
          }}
        >
          <GDMCustomInputLabel
            label={getLabelTranslation('city_name')}
            required={viewType !== 'view'}
            fontSize="16px"
            color="#222222"
          />

          <GDMSelect
            sx={{
              fontSize: '14px',
              background: disabledFields && '#F2F4F9',
              marginTop: '5px',
              height: '48px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '19px',
              color: '#A1A9B8',
            }}
            IconComponent={IoIosArrowDown}
            value={cities ? formData.cityId : ''}
            onChange={e => {
              handleCityChange(e);
              handleGetStarterCoordinations(e.target.value, formData.stateId);
            }}
            variant="outlined"
            disabled={disabledFields}
            fullWidth
          >
            <GDMMenuItem value="0">{getLabelTranslation('formfields_correction_city')}</GDMMenuItem>
            {cities.map(city => (
              <GDMMenuItem key={city.id} value={city.id}>
                {city.name}
              </GDMMenuItem>
            ))}
          </GDMSelect>

          {errors.cityId && (
            <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.cityId}</GDMFormHelperText>
          )}
        </GDMGrid>

        {/* AREA TOTAL */}
        <GDMGrid xs={12} sm={12} md={3} sx={{ textAlign: 'start', paddingTop: '16px' }}>
          <GDMCustomInputLabel
            label={getLabelTranslation('total_area')}
            fontSize="16px"
            color="#222222"
          />
          <GDMFormControl fullWidth>
            <GDMTextField
              type="number"
              required
              focused={false}
              id="totalArea"
              variant="outlined"
              placeholder={getLabelTranslation('total_area')}
              fullWidth
              disabled={disabledFields}
              value={formData.totalArea}
              onChange={handleChangeValues}
              name="totalArea"
              sx={{ background: disabledFields && '#F2F4F9' }}
              inputProps={{ min: 0 }}
              onKeyDown={e => {
                if (e.key === '-' || e.key === 'e') {
                  e.preventDefault();
                }
              }}
            />

            {!!errors.totalArea && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>{errors.totalArea}</GDMFormHelperText>
            )}
          </GDMFormControl>
        </GDMGrid>

        {showLatLngField && (
          <GDMGrid
            xs={12}
            sm={12}
            md={3}
            xl={4}
            sx={{
              textAlign: 'start',
              paddingTop: '16px',
              paddingLeft: '16px',
              '@media (max-width:1200px)': {
                paddingLeft: 0,
              },
            }}
          >
            <GDMCustomInputLabel
              label={getLabelTranslation('lat_long')}
              required={viewType !== 'view'}
              fontSize="16px"
              color="#222222"
            />
            <GDMFormControl
              fullWidth
              sx={{
                background: '#F2F4F9',
                border: '1px solid #A1A9B8',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                paddingLeft: '10px',
                ...(hasInvalidLatLong ? { borderColor: '#FB2047' } : {}),
              }}
            >
              <a
                style={{ color: '#0063F7' }}
                target="_blank"
                href={`https://www.google.com/maps?q=${farmCoordinates}`}
                rel="noreferrer noopener"
              >
                {typeIcon === 'create' ? <GoLinkExternal /> : <PiMapPinAreaLight />}
              </a>

              <GDMTextField
                focused={false}
                id="lat_long"
                placeholder={getLabelTranslation('lat_long')}
                fullWidth
                value={farmCoordinates}
                disabled
                name="lat_long"
                sx={{
                  paddingLeft: 0,
                  fieldset: { border: 'none', paddingLeft: 0 },
                }}
              />
            </GDMFormControl>
            {hasInvalidLatLong && (
              <Tooltip
                title={getLabelTranslation(
                  'inconsistent_location_saved_farm_error_tooltip_message',
                )}
                arrow
                placement="top"
              >
                <span>
                  <GDMFormHelperText sx={{ color: '#FB2047' }}>
                    <span
                      style={{
                        fontWeight: 'bold',
                        display: 'inline-flex',
                        fontSize: '16px',
                        paddingTop: '2px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '18px',
                        height: '18px',
                        borderRadius: '50%',
                      }}
                    >
                      ⓘ
                    </span>{' '}
                    {getLabelTranslation('inconsistent_location_saved_farm_error_message')}
                  </GDMFormHelperText>
                </span>
              </Tooltip>
            )}
          </GDMGrid>
        )}

        {/* SELECIONAR LATITUDE/LONGITUDE */}
        {showButton && (
          <GDMGrid
            sx={{
              paddingTop: '50px',
              paddingLeft: '16px',
              width: 'auto',
              paddingBottom: '12px',

              '@media (max-width:1200px)': {
                paddingTop: '32px',
                paddingLeft: 0,
                width: '100%',
                paddingBottom: 0,
              },
            }}
          >
            <GDMButton
              disabled={formData.cityId ==='0'}
              onClick={() => {
                console.group('L');
                setOpenModal(true);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '51px',
                width: 'auto',

                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
                '&:disabled':{
                  background: 'white',
                  border: '1px solid #C2EAD4'
                },
                '@media (max-width:1200px)': {
                  height: '40px',
                  width: '100%',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_one_latitude_longitude')}
            </GDMButton>
            {!!errors.longitude && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>
                {errors.longitude}/{errors.latitude}
              </GDMFormHelperText>
            )}
          </GDMGrid>
        )}
        {!showButton && !isDesktop && (
          <GDMGrid
            sx={{
              paddingTop: '50px',
              paddingLeft: '16px',
              width: 'auto',
              paddingBottom: '12px',

              '@media (max-width:1200px)': {
                paddingTop: '16px',
                paddingLeft: 0,
                width: '100%',
                paddingBottom: 0,
              },
            }}
          >
            <GDMButton
              onClick={() => {
                console.group('L');
                setOpenModal(true);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '51px',
                width: 'auto',
                color: theme.palette.secondary.dark,
                background: 'transparent',
                boxShadow: 'none',
                border: '1px solid #00843D',
                ':hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },

                '@media (max-width:1200px)': {
                  height: '40px',
                  width: '100%',
                },
              }}
              variant="contained"
            >
              {getLabelTranslation('select_latitude_longitude')}
            </GDMButton>
            {!!errors.longitude && (
              <GDMFormHelperText sx={{ color: '#FB2047' }}>
                {errors.longitude}/{errors.latitude}
              </GDMFormHelperText>
            )}
          </GDMGrid>
        )}
      </GDMGrid>

      <GDMDrawerMap
        isOpen={openModalState}
        onClose={() => {
          handleResetState();
          setOpenModal(false);
        }}
        title={getLabelTranslation('indicate_location')}
        InitialCoordinates={{
          latitude: initialCoordinates.lat.toString(),
          longitude: initialCoordinates.lng.toString(),
        }}
        handleMapClick={event => {
          setFarmCoordinates(`${event.latitude}, ${event.longitude}`);
          setInitialCoordinates({
            lat: Number(event.latitude),
            lng: Number(event.longitude),
          });
        }}
        type={GDMMapTypes.farm}
        handleMarkerDelete={() => {
          setFarmCoordinates(`0, 0`);
        }}
        subtitle={
          <GDMTypography sx={{ fontSize: '20px', marginBottom: '12px' }}>
            {getLabelTranslation('indicate_location_of')} <strong> {formData.farmName} </strong>
            {getLabelTranslation('of_farmer')} <strong> {farmerName}</strong>
          </GDMTypography>
        }
        actions={
          <GDMContainer
            sx={{
              display: 'flex',
              alignItems: {
                xs: 'start',
                sm: 'start',
                md: 'center',
                lg: 'center',
                xl: 'center',
              },
              gap: '12px',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <GDMTypography sx={{ fontSize: '16px' }}>
              {getLabelTranslation('lat_long')}
            </GDMTypography>
            <a
              style={{
                display: 'flex',
                padding: '8px 30px',
                background: '#F2F4F9',
                border: '1px solid #A1A9B8',
                color: '#0063F7',
                alignItems: 'center',
                gap: '8px',
                fontSize: '16px',
                justifyContent: 'start',
                borderRadius: '8px',
                maxWidth: '500px',
              }}
              target="_blank"
              href={`https://www.google.com/maps?q=${farmCoordinates}`}
              rel="noreferrer noopener"
            >
              <GoLinkExternal />
              {farmCoordinates}
            </a>
            <GDMContainer
              sx={{
                display: 'flex',
                gap: '12px',
                flexDirection: 'row',
                width: !isDesktop && '100%',
                '@media (max-width:1200px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <GDMButton
                variant="contained"
                onClick={() => {
                  handleResetState();
                  setOpenModal(false);
                }}
                sx={{
                  minWidth: '187px',
                  height: '51px',
                  background: theme.palette.secondary.main,
                  color: '#313131',
                  '@media (max-width:1200px)': {
                    minWidth: '100%',
                  },
                  ':hover': {
                    borderStyle: 'none',
                    background: theme.palette.primary.light,
                  },
                }}
              >
                {getLabelTranslation('cancel')}
              </GDMButton>
              <GDMButton
                variant="contained"
                disabled={
                  !farmCoordinates ||
                  farmCoordinates === '0, 0' ||
                  !initialCoordinates ||
                  (initialCoordinates.lat === 0 && initialCoordinates.lng === 0)
                }
                onClick={() => {
                  handleSaveLatLng();
                }}
                sx={{
                  minWidth: '187px',
                  height: '51px',
                  background: theme.palette.primary.main,
                  color: 'white',
                  '@media (max-width:1200px)': {
                    minWidth: '100%',
                  },
                  ':hover': {
                    background: theme.palette.primary.dark,
                  },
                  '&.Mui-disabled': {
                    background: theme.palette.action.disabled,
                    color: 'white',
                  },
                }}
              >
                {getLabelTranslation('ReusableFormKey_Save')}
              </GDMButton>
            </GDMContainer>
          </GDMContainer>
        }
      />
    </>
  );
};

export default GDMFarmRegisterForm;
