import {
  DynamicFormData,
  GDMBox,
  GDMButton,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBrands, getRegions } from 'utils';
import { SellerGDMService } from 'core/api/services/sellerGDM';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useMediaQuery, useTheme } from '@mui/material';
import { SellerGdmRegisterPageComponent } from '../SellerGdmRegisterPage.type';

const DEFAULT_ADMIN: Partial<DynamicFormData> = {
  name: '',
  contactEmail: '',
  phoneNumber: '',
  documentNumber: '',
};

const SellerGdmViewPage: SellerGdmRegisterPageComponent = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const {
    user: { countryName, countryId },
  } = useStore(UserProfileStore);

  const navigate = useNavigate()

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [sellerGdm, setSellerGdm] = useState<Partial<DynamicFormData>>(DEFAULT_ADMIN);
  const [errors] = useState({});

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [entityType, setEntityType] = useState('Vendedor GDM');
  const { sellerGdmId } = useParams();

  useEffect(() => {
    const loadData: Array<Promise<any>> = [loadBrands(), loadRegions()];
    Promise.all(loadData).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsSubmiting(true);
    setEntityType('Vendedor GDM');

    SellerGDMService.getSellerGDMId(sellerGdmId)
      .then(response => {
        const data = response;

        setSellerGdm({
          ...data,
          id: sellerGdmId,
          contactEmail: data.email,
          brandIds: data.brands.map(item => item.id),
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmiting(false);
        setIsLoading(false);
      });
  }, [currentLanguage]);

  useEffect(() => {
    if (sellerGdm.documentNumber) {
      setDocumentTypeId();
    }

    if (sellerGdm.countryId) {
      setCountryId();
    }
  }, [sellerGdm.documentNumber, sellerGdm.countryId]);

  useEffect(() => {
    if (countryName) {
      setCountryId();
    }
  }, [countryName]);

  const loadBrands = () => {
    return getBrands({
      countryId,
      onError: () => console.error('Error on load brands!'),
      onInit: () => setIsLoading(true),
      onSuccess: brands => setBrands(brands),
    });
  };

  const loadRegions = () => {
    return getRegions({
      countryId,
      onError: () => console.error('Error on load regions!'),
      onInit: () => setIsLoading(true),
      onSuccess: regions => setRegions(regions),
    });
  };

  const setDocumentTypeId = () => {
    if (countryName === 'Argentina') {
      setSellerGdm(prev => ({
        ...prev,
        documentTypeId: 'b85d6c71-e469-4268-a813-1bac98f596ae',
      }));
    }

    let onlyNumbers: number;

    if (sellerGdm.documentNumber) {
      onlyNumbers = sellerGdm.documentNumber.replace(/\D/g, '').length;
    }

    if (countryName === 'Brasil' && onlyNumbers === 11) {
      setSellerGdm(prev => ({
        ...prev,
        documentTypeId: 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d',
        fantasyName: '',
      }));
    } else if (countryName === 'Brasil' && onlyNumbers > 14) {
      setSellerGdm(prev => ({
        ...prev,
        documentTypeId: 'cc49f596-0841-4708-9ef8-6e372ea70ddf',
      }));
    }
  };

  const setCountryId = () => {
    if (countryName === 'Argentina') {
      setSellerGdm(prev => ({
        ...prev,
        countryId: '628ff4ec-0435-460a-b4ca-bcea09bf9f5b',
      }));
    }

    if (countryName === 'Brasil') {
      setSellerGdm(prev => ({
        ...prev,
        countryId: '41981f5c-0e28-44f5-ab84-d6259659abee',
      }));
    }
  };

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > Vendedor GDM`}
      title={`${getLabelTranslation('reusableForm_ViewRegistrationOf_')} Vendedor GDM`}
    >
      <GDMConditionalRender canRender={isLoading}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>

      <GDMConditionalRender canRender={!isLoading}>
        <GDMDynamicFormFields
          {...{
            entityType,
            viewType: 'view',
            formData: sellerGdm,
            isSubmiting,
            errors,
            formDataEvents: {
              onCancel: null,
              onChange: null,
              onSubmit: null,
            },
            formDataOptions: {
              roles: [],
              brands,
              cities: [],
              states: [],
              regions,
              gdmSellers: [],
              multipliers: [],
            },
          }}
        />
      </GDMConditionalRender>
      <GDMBox sx={{ display: 'flex', justifyContent: 'start', gap: '24px', marginTop: 'auto' }}>
        <GDMButton
          variant="outlined"
          onClick={() => navigate(-1)}
          sx={{
            color: theme.palette.secondary.dark,
            width: isDesktop ? '187px' : '100% !important',
          }}
        >
          <FaArrowLeftLong style={{ marginRight: '8px' }} />
          {getLabelTranslation('ReusableFormKey_Back')}
        </GDMButton>
      </GDMBox>
    </GDMPageCard>
  );
};

export default SellerGdmViewPage;
