import { APIProvider, Map, MapMouseEvent, Marker } from '@vis.gl/react-google-maps';
import { GDMContainer } from 'components';
import { useEffect, useState } from 'react';
import { GDMMapTypes, GDMMapsComponent } from './GDMMaps.type';

const GDMMaps: GDMMapsComponent = props => {
  const {
    InitialCoordinates,
    type,
    handleMapClick,
    handleMarkerDelete,
    mapPinListLot = [],
  } = props;

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const [onMousePressed, setOnMousePressed] = useState(false);

  const [lastClickedCoordinates, setLastClickedCoordinates] = useState(
    InitialCoordinates ?? {
      latitude: '0',
      longitude: '0',
    },
  );

  const [center, setCenter] = useState(
    InitialCoordinates ?? {
      latitude: '0',
      longitude: '0',
    },
  );

  const pinList = mapPinListLot?.filter(x => !!Number(x.latitude) && !!Number(x.longitude)) || [];

  const innerHandleMapClick = (event: MapMouseEvent) => {
    const { lat, lng } = event.detail.latLng;
    if (!Number(lat) && !Number(lng)) return;
    const data = { latitude: lat.toString(), longitude: lng.toString() };
    setLastClickedCoordinates(data);
    handleMapClick(data);
  };

  const handleDeleteMarker = (params: { latitude: string; longitude: string }) => {
    setLastClickedCoordinates({ latitude: '0', longitude: '0' });
    if (handleMarkerDelete && type === GDMMapTypes.farm) {
      handleMarkerDelete(params);
    }
    if (handleMarkerDelete && type === GDMMapTypes.field) {
      const isLastMarker = pinList?.length === 1 && !!Number(pinList[0]?.latitude);
      if (!isLastMarker) handleMarkerDelete(params);
    }
  };

  useEffect(() => {
    if (InitialCoordinates && InitialCoordinates !== center) {
      setCenter(InitialCoordinates);
    }
  }, [InitialCoordinates]);

  return (
    <GDMContainer
      className="map"
      sx={{
        width: '100%',
        borderRadius: '16px',
        minWidth: {
          xs: '100%',
          sm: '100%',
          md: '40vw',
          lg: '40vw',
          xl: '40vw',
        },
      }}
    >
      <APIProvider apiKey={apiKey} authReferrerPolicy='origin'>
        <Map
          style={{ width: '100%', height: '100%', borderRadius: '16px' }}
          center={
            !onMousePressed ? { lat: Number(center.latitude), lng: Number(center.longitude) } : null
          }
          defaultZoom={17}
          onMousemove={() => setOnMousePressed(true)}
          onMouseover={() => setOnMousePressed(false)}
          gestureHandling="cooperative"
          mapTypeId="hybrid"
          streetViewControl={false}
          onClick={e => {
            e.domEvent.preventDefault();
            innerHandleMapClick(e);
          }}
        >
          {type === GDMMapTypes.farm && !!Number(lastClickedCoordinates.latitude) && (
            <Marker
              onClick={() => handleDeleteMarker(lastClickedCoordinates)}
              position={{
                lat: Number(lastClickedCoordinates.latitude),
                lng: Number(lastClickedCoordinates.longitude),
              }}
            />
          )}

          {type === GDMMapTypes.field &&
            pinList.map(coordinate => {
              return (
                <Marker
                  key={`${coordinate.latitude}${coordinate.longitude}marker`}
                  onClick={() => handleDeleteMarker(coordinate)}
                  position={{
                    lat: Number(coordinate.latitude),
                    lng: Number(coordinate.longitude),
                  }}
                />
              );
            })}
        </Map>
      </APIProvider>
    </GDMContainer>
  );
};

export default GDMMaps;
