import {
  Divider,
  IconButton,
  ListItemIcon,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  GDMButton,
  GDMCollapse,
  GDMContainer,
  GDMIconButton,
  GDMList,
  GDMListItem,
  GDMListItemButton,
  GDMSecureImage,
  GDMTypography,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { IoImageOutline } from 'react-icons/io5';
import { LuTrash2 } from 'react-icons/lu';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { stringTruncate } from 'utils/formaters';

const GDMLotList = ({
  lots,
  type,
  openDeleteModal,
  orderColumn,
  setOrderColumn,
}: {
  lots: any[];
  type: 'farm' | 'farmer';
  setOrderColumn: (any) => void;
  orderColumn: any;
  openDeleteModal: (string, boolean) => void;
}) => {
  const theme = useTheme();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [modalInfos, setModalInfos] = useState({ img: '', lotName: '' });
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const columnTitle: { name: string; title: any; type: string }[] = [
    {
      name: 'FarmName',
      title: getLabelTranslation('farm_name'),
      type: 'text',
    },
    {
      name: 'Name',
      title: type === 'farmer' ? getLabelTranslation('lot_name') : getLabelTranslation('name'),
      type: 'text',
    },
    {
      name: 'StateName',
      title: getLabelTranslation('state_name'),
      type: 'text',
    },
    {
      name: 'CityName',
      title: getLabelTranslation('city_name'),
      type: 'text',
    },
    {
      name: 'TotalArea',
      title: getLabelTranslation('total_area'),
      type: 'text',
    },
  ];

  const handleClick = id => {
    setOpen(open === id ? null : id);
  };

  const changeOrderColumn = column => {
    setOrderColumn({
      column,
      flap: 'lot',
      orderDesc: !orderColumn.orderDesc,
      entityName: 'FarmEntity',
    });
  };
  const handleOpenModal = (img, lotName) => {
    setModalInfos({ img, lotName });
    setOpenModal(true);
  };
  if (type === 'farm') {
    columnTitle.shift();
  }
  return (
    <GDMContainer sx={{ paddingLeft: type === 'farm' ? 0 : '16px', width: '100%' }}>
      <TableContainer>
        <Table>
          {isDesktop && (
            <TableHead>
              <TableRow sx={{ background: '#F2F4F9' }}>
                {columnTitle.map((column, index) => {
                  const key = `col-${index}-${column.name}`;

                  return (
                    <TableCell
                      key={key}
                      sx={{
                        border: 'none',
                      }}
                    >
                      <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMTypography
                          variant="subtitle2"
                          sx={{
                            color: '#777D88',
                          }}
                        >
                          {column.title}
                        </GDMTypography>
                        <IconButton
                          onClick={() => {
                            changeOrderColumn(column.name);
                          }}
                        >
                          <CgArrowsExchangeAltV />
                        </IconButton>
                      </GDMContainer>
                    </TableCell>
                  );
                })}
                <TableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('image')}
                    </GDMTypography>
                  </GDMContainer>
                </TableCell>
                <TableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('to_edit')}
                    </GDMTypography>
                  </GDMContainer>
                </TableCell>
                <TableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('to_delete')}
                    </GDMTypography>
                  </GDMContainer>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {lots &&
              isDesktop &&
              lots.map(row => (
                <TableRow>
                  {type === 'farmer' && (
                    <TableCell>
                      <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                        {stringTruncate(row.farmName, 25)}
                      </GDMTypography>
                    </TableCell>
                  )}

                  <TableCell>
                    <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                      {stringTruncate(row.name, 25)}
                    </GDMTypography>
                  </TableCell>
                  <TableCell>
                    <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                      {row.stateName}
                    </GDMTypography>
                  </TableCell>
                  <TableCell>
                    <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                      {row.cityName}
                    </GDMTypography>
                  </TableCell>
                  <TableCell>
                    <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                      {row.totalArea === null ? 0 : stringTruncate(row.totalArea, 10)}
                    </GDMTypography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleOpenModal(row.lotImageUrl, row.name)}
                      sx={{ marginLeft: '25px' }}
                    >
                      <IoImageOutline />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        navigate(`/farmer-lot-register/${row.id}/edit`);
                      }}
                      sx={{ marginLeft: '20px' }}
                    >
                      <FiEdit />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        openDeleteModal(row.id, row.canBeDeleted);
                      }}
                      sx={{ marginLeft: '20px' }}
                    >
                      <LuTrash2 />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {lots &&
              !isDesktop &&
              lots.map(item => (
                <GDMContainer key={item.id} sx={{ background: 'transparent' }}>
                  <GDMListItemButton onClick={() => handleClick(item.id)} sx={{ pl: 0, pr: 0 }}>
                    <GDMListItem sx={{ pl: 0 }}>
                      <GDMTypography variant="body2">{getLabelTranslation('name')} </GDMTypography>
                      <GDMTypography variant="body1" ml={1}>{` ${item.name}`}</GDMTypography>
                    </GDMListItem>
                    <GDMIconButton
                      sx={{
                        color: '#222',
                        width: '32px',
                        height: '32px',
                        backgroundColor: open === item.id ? '#43ACE3' : '#CFEFFF',
                        borderRadius: '50%',
                      }}
                    >
                      {open === item.id ? <MdExpandLess /> : <MdExpandMore />}
                    </GDMIconButton>
                  </GDMListItemButton>
                  <GDMCollapse
                    in={open === item.id}
                    timeout="auto"
                    unmountOnExit
                    sx={{ background: 'transparent' }}
                  >
                    <GDMList component="div" disablePadding>
                      <GDMListItem sx={{ pl: 0 }}>
                        <GDMTypography variant="body2">
                          {getLabelTranslation('state_name')}{' '}
                        </GDMTypography>
                        <GDMTypography variant="body1" ml={1}>{` ${item.stateName}`}</GDMTypography>
                      </GDMListItem>
                      <GDMListItem sx={{ pl: 0 }}>
                        <GDMTypography variant="body2">
                          {getLabelTranslation('city_name')}{' '}
                        </GDMTypography>
                        <GDMTypography variant="body1" ml={1}>{` ${item.cityName}`}</GDMTypography>
                      </GDMListItem>
                      <GDMListItem sx={{ pl: 0 }}>
                        <GDMTypography variant="body2">
                          {getLabelTranslation('total_planted_area')}{' '}
                        </GDMTypography>
                        <GDMTypography variant="body1" ml={1}>
                          {item.totalArea}
                        </GDMTypography>
                      </GDMListItem>
                      <GDMListItem
                        sx={{ pl: 0, pr: 0, display: 'flex', justifyContent: 'space-between' }}
                      >
                        <GDMTypography variant="body2">
                          {getLabelTranslation('actions')}
                        </GDMTypography>
                        <GDMContainer
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <IconButton onClick={() => handleOpenModal(item.lotImageUrl, item.name)}>
                            <IoImageOutline />
                          </IconButton>

                          <ListItemIcon
                            onClick={() => {
                              navigate(`/farmer-lot-register/${item.id}/edit`);
                            }}
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              fontSize: '20px',
                            }}
                          >
                            <FiEdit />
                          </ListItemIcon>
                          <ListItemIcon
                            sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                          >
                            <GDMIconButton
                              onClick={() => {
                                openDeleteModal(item.id, item.canBeDeleted);
                              }}
                            >
                              <LuTrash2 style={{ fontSize: '24px' }} />
                            </GDMIconButton>
                          </ListItemIcon>
                        </GDMContainer>
                      </GDMListItem>
                    </GDMList>
                  </GDMCollapse>
                  <Divider />
                </GDMContainer>
              ))}
          </TableBody>
        </Table>
        {lots && lots.length === 0 && (
          <GDMTypography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '32px',
              paddingBottom: '32px',
              color: '#222',
            }}
          >
            {getLabelTranslation('no_register_lots')}
          </GDMTypography>
        )}
      </TableContainer>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <GDMContainer
          sx={{
            width: isDesktop ? '40vw' : '95%',
            height: '83vh',
            background: '#fff',
            margin: '10vh auto',
            borderRadius: '24px',
            display: 'flex',
            flexDirection: 'column',
            padding: isDesktop ? '42px 41px' : '24px 16px',
            gap: '24px',
          }}
        >
          <GDMContainer
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <GDMContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflow: 'hidden',
                direction: 'ltr',
                width: '200px',
                textOverflow: 'ellipsis',
              }}
            >
              <IoImageOutline style={{ fontSize: '32px', marginRight: '8px' }} />
              <GDMTypography
                variant="inherit"
                sx={{
                  fontWeight: 700,
                  color: '#222',
                  fontSize: '20px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {`${getLabelTranslation('image_lot')} ${modalInfos.lotName}`}
                {/* {stringTruncate(modalInfos.lotName, 30)} */}
              </GDMTypography>
            </GDMContainer>
            <IconButton
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <IoMdClose color="#222" />
            </IconButton>
          </GDMContainer>
          <Divider sx={{ borderColor: '#CBCDD9' }} />
          {modalInfos.img && (
            <GDMContainer sx={{ height: '80%' }}>
              <GDMSecureImage
                src={modalInfos.img}
                alt="img_lot"
                style={{ width: '100%', height: '100%', borderRadius: '12px' }}
              />
            </GDMContainer>
          )}
          <GDMButton variant="contained" onClick={() => setOpenModal(false)}>
            {getLabelTranslation('close')}
          </GDMButton>
        </GDMContainer>
      </Modal>
    </GDMContainer>
  );
};

export default GDMLotList;
