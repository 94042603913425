/* eslint-disable no-else-return */
import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import cuitValidator from 'cuit-validator';
import { DynamicFormValidateDocumentFunction } from './dynamicFormValidateDocument.type';

const dynamicFormValidateDocument: DynamicFormValidateDocumentFunction = ({
  documentNumber,
  countryName,
}) => {
  const sanitizedDocumentNumber = documentNumber.replace(/\D/g, '');

  if (!sanitizedDocumentNumber) return false;

  if (countryName === CountriesDomainEnum.Brasil) {
    if (sanitizedDocumentNumber.length === 11) {
      return cpf.isValid(sanitizedDocumentNumber);
    } else if (sanitizedDocumentNumber.length === 14) {
      return cnpj.isValid(sanitizedDocumentNumber);
    }

    return false;
  } else if (countryName === CountriesDomainEnum.Argentina) {
    return cuitValidator(sanitizedDocumentNumber);
  }

  return false;
};

export default dynamicFormValidateDocument;
