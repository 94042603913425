// themes.ts
import { createTheme } from '@mui/material/styles';

import baseThemeOptions from './baseTheme';

export const themeDonMarioAr = createTheme({
  ...baseThemeOptions,
  palette: {
    ...baseThemeOptions.palette,
    primary: {
      main: '#00843D',
      dark: '#006C32',
      light: '#1FCA6E',
      contrastText: '#FAFAFA',
      // medium: '#1FCA6E',
      '100': '#C2EAD4',
    },
    secondary: {
      main: '#C2EAD4',
      dark: '#222222',
      contrastText: '#FAFAFA',
    },
    action: {
      disabled: '#C2EAD4',
    },
  },
});

export const themeDonMarioBr = createTheme({
  ...baseThemeOptions,
  palette: {
    ...baseThemeOptions.palette,
    primary: {
      main: '#41877A',
      dark: '#284F48',
      light: '#4AA192',
      contrastText: '#FAFAFA',
      // medium: '#41877A',
      '100': '#D0EAE5',
    },
    secondary: {
      main: '#D0EAE5',
      dark: '#222222',
      light: '#69D955',
      contrastText: '#FAFAFA',
    },
    action: {
      disabled: '#D0EAE5',
    },
  },
});

export const themeGDMAdministradores = createTheme({
  ...baseThemeOptions,
  palette: {
    ...baseThemeOptions.palette,
    primary: {
      main: '#0A3C56',
      dark: '#092A3B',
      light: '#43ACE3',
      contrastText: '#FAFAFA',
      // medium: '#43ACE3',
      '100': '#CFEFFF',
    },
    secondary: {
      main: '#CFEFFF',
      dark: '#222222',
      light: '#cfeff8',
      contrastText: '#FAFAFA',
    },
    action: {
      disabled: '#A1A9B8',
    },
  },
});
