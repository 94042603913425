/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  ClickAwayListener,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Zoom,
} from '@mui/material';
import { GDMBox, GDMTypography } from 'components';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { Genotypes, TransformedLine } from 'pages/varieties-to-compare/VarietiesToCompare.types';
import { useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

interface ContentLinesProps {
  lines: TransformedLine[];
  genotypes: Genotypes[];
  index: number;
  group: string;
}

export default function ListMobile({ lines, genotypes, index, group }: ContentLinesProps) {
  const [openCollapse, setOpenCollapse] = useState(null);
  const { theme } = useThemeSwitcher();
  const [open, setOpen] = useState(null);

  const handleTooltipClose = event => {
    if (open && !event.currentTarget.contains(event.relatedTarget)) {
      setOpen(null);
    }
  };

  const handleTooltipOpen = id => {
    setOpen(id);
  };

  const handleOpenCollapse = id => {
    setOpenCollapse(openCollapse === id ? null : id);
  };

  return (
    <GDMBox>
      <ListItemButton
        onClick={() => handleOpenCollapse(group)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 0 10px 0',
          ...(index !== group.length - 1 &&
            openCollapse !== group && {
              borderStyle: 'solid',
              borderBottomWidth: '2px',
              borderBottomColor: '#E7E9F1',
              marginBottom: '10px',
              paddingBottom: '10',
            }),
        }}
      >
        <GDMBox
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
              padding: '10px 0',
            }}
          >
            <GDMTypography
              sx={{
                fontSize: { xs: '16px' },
                lineHeight: { xs: '22.4px' },
                fontWeight: 700,
                color: theme.palette.primary.main,
              }}
            >
              {group}
            </GDMTypography>
          </ListItem>
          <IconButton
            sx={{
              color: '#222',
              width: '32px',
              height: '32px',
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '50%',
            }}
          >
            {openCollapse === group ? <MdExpandLess /> : <MdExpandMore />}
          </IconButton>
        </GDMBox>
      </ListItemButton>
      <Collapse in={openCollapse === group} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <ListItem
            sx={{
              width: '100%',
              padding: '0 0 8px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            {genotypes.map((genotype, index) => {
              return (
                <GDMBox sx={{ width: '100%' }}>
                  <GDMTypography
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: '14px' },
                      lineHeight: { xs: '19.6px' },
                      marginBottom: '8px',
                    }}
                  >
                    {genotype.genotype}
                  </GDMTypography>
                  {lines.map(caracteristc => {
                    const legendKey = `legend_${genotype.genotype}`;
                    const legendContent = caracteristc.values[legendKey];
                    return (
                      <GDMBox
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: '8px',
                        }}
                      >
                        <GDMBox
                          sx={{
                            display: 'flex',
                            gap: '8px',
                            paddingRight: '16px',
                          }}
                        >
                          <GDMTypography
                            sx={{
                              alignSelf: 'initial',
                              fontWeight: 700,
                              fontSize: { xs: '14px' },
                              lineHeight: { xs: '19.6px' },
                            }}
                          >
                            {caracteristc.characteristic}
                          </GDMTypography>
                          {legendContent && (
                            <Tooltip
                              title={legendContent}
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={
                                open === caracteristc.characteristic + index + genotype.genotype
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                              placement="top"
                              TransitionComponent={Zoom}
                            >
                              <button
                                type="button"
                                style={{ border: 'none', background: 'none' }}
                                onClick={() =>
                                  handleTooltipOpen(
                                    caracteristc.characteristic + index + genotype.genotype,
                                  )
                                }
                                onBlur={handleTooltipClose}
                              >
                                <FaCircleInfo color="#A1A9B8" size={16} />
                              </button>
                            </Tooltip>
                          )}
                        </GDMBox>
                        <GDMTypography
                          sx={{
                            minWidth: '180px',
                            maxWidth: '180px',
                            display: 'flex',
                            flexDirection:
                              Array.isArray(
                                caracteristc.values[
                                  Object.keys(caracteristc.values).find(
                                    key => key === `characteristicValue_${genotype.genotype}`,
                                  )
                                ],
                              ) &&
                              caracteristc.values[
                                Object.keys(caracteristc.values).find(
                                  key => key === `characteristicValue_${genotype.genotype}`,
                                )
                              ].length > 1
                                ? 'column'
                                : 'row',
                            justifyContent: 'flex-start',
                            width: '100%',
                            alignSelf: 'center',
                            fontSize: { xs: '14px' },
                            lineHeight: { xs: '19.6px' },
                          }}
                        >
                          {(() => {
                            const value =
                              caracteristc.values[
                                Object.keys(caracteristc.values).find(
                                  key => key === `characteristicValue_${genotype.genotype}`,
                                )
                              ];

                            const toolTipKey = `toolTip_${genotype.genotype}`;

                            if (Array.isArray(value) && value.length > 1) {
                              return value.map(val => (
                                <GDMBox
                                  sx={{
                                    display: 'flex',
                                    gap: '8px',
                                  }}
                                >
                                  <GDMTypography
                                    sx={{
                                      minWidth: '180px',
                                      maxWidth: '180px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-end',
                                      alignSelf: 'initial',
                                      fontSize: { xs: '14px' },
                                      lineHeight: { xs: '19.6px' },
                                    }}
                                  >
                                    • {val}
                                    <br />
                                  </GDMTypography>
                                  {caracteristc.values[toolTipKey] && (
                                    <Tooltip
                                      title={caracteristc.values[toolTipKey]}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={
                                        open ===
                                        caracteristc.characteristic +
                                          ' ' +
                                          index +
                                          genotype.genotype
                                      }
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      arrow
                                      placement="top"
                                      TransitionComponent={Zoom}
                                    >
                                      <button
                                        type="button"
                                        style={{ border: 'none', background: 'none' }}
                                        onClick={() =>
                                          handleTooltipOpen(
                                            caracteristc.characteristic +
                                              ' ' +
                                              index +
                                              genotype.genotype,
                                          )
                                        }
                                        onBlur={handleTooltipClose}
                                      >
                                        <FaCircleInfo color="#A1A9B8" size={16} />
                                      </button>
                                    </Tooltip>
                                  )}
                                </GDMBox>
                              ));
                            }
                            if (Array.isArray(value) && value.length === 1) {
                              return (
                                <GDMBox
                                  sx={{
                                    display: 'flex',
                                    gap: '8px',
                                  }}
                                >
                                  <GDMTypography
                                    sx={{
                                      display: 'flex',
                                      alignSelf: 'center',
                                      fontSize: { xs: '14px' },
                                      lineHeight: { xs: '19.6px' },
                                    }}
                                  >
                                    {value || '-'}
                                  </GDMTypography>
                                  {caracteristc.values[toolTipKey] && (
                                    <Tooltip
                                      title={caracteristc.values[toolTipKey]}
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={handleTooltipClose}
                                      open={
                                        open ===
                                        caracteristc.characteristic +
                                          ' ' +
                                          index +
                                          genotype.genotype
                                      }
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      arrow
                                      placement="top"
                                      TransitionComponent={Zoom}
                                    >
                                      <button
                                        type="button"
                                        style={{ border: 'none', background: 'none' }}
                                        onClick={() =>
                                          handleTooltipOpen(
                                            caracteristc.characteristic +
                                              ' ' +
                                              index +
                                              genotype.genotype,
                                          )
                                        }
                                        onBlur={handleTooltipClose}
                                      >
                                        <FaCircleInfo color="#A1A9B8" size={16} />
                                      </button>
                                    </Tooltip>
                                  )}
                                </GDMBox>
                              );
                            }

                            return '-';
                          })()}
                        </GDMTypography>
                      </GDMBox>
                    );
                  })}
                  {index !== genotypes.length - 1 && (
                    <Divider sx={{ margin: '16px 0 16px 0', width: '100%' }} />
                  )}
                </GDMBox>
              );
            })}
          </ListItem>
          <Divider sx={{ margin: '10px 0 20px 0' }} />
        </List>
      </Collapse>
    </GDMBox>
  );
}
