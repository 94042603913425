import { GDMFormControl } from 'components/gdm-form-control';
import { GDMFormHelperText } from 'components/gdm-form-helper-text';
import { GDMMenuItem } from 'components/gdm-menu-item';
import { GDMSelect } from 'components/gdm-select';
import { useLanguageContext } from 'core/store/contexts/languageContext';
import { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import {
  dynamicFormSelectDefaultValueStyles,
  dynamicFormSelectStyles,
  selectFieldErrorMessageStyles,
} from './DynamicFormSelect.styles';
import { DynamicFormSelectComponent } from './DynamicFormSelect.type';

const DynamicFormSelect: DynamicFormSelectComponent = ({
  id,
  name,
  value,
  defaultValue,
  disabled,
  onChange,
  onBlur,
  placeholder,
  options,
  error,
  helperText,
}) => {
  const { getLabelTranslation, currentLanguage } = useLanguageContext();
  const [optionSelected, setOptionSelected] = useState('default-select-item');
  const [textProps, setTextProps] = useState({
    placeholder: getLabelTranslation(placeholder),
    helperText: getLabelTranslation(helperText as string),
  });

  useEffect(() => {
    setTextProps({
      placeholder: getLabelTranslation(placeholder),
      helperText: getLabelTranslation(helperText as string),
    });
  }, [currentLanguage, placeholder, helperText]);

  const showErrorMessage = !!(error && helperText);

  const isComponentErrorMessage = !!helperText && typeof helperText !== 'string';

  return (
    <GDMFormControl error={error} fullWidth>
      <GDMSelect
        {...{
          id,
          name,
          defaultValue,
          disabled,
          onChange,
          onBlur,
          placeholder: textProps.placeholder,
          error,
          value: defaultValue ? value : value || 'default-select-item',
        }}
        sx={dynamicFormSelectStyles({ value })}
        IconComponent={IoIosArrowDown}
        variant="outlined"
        fullWidth
      >
        <GDMMenuItem
          sx={dynamicFormSelectDefaultValueStyles}
          value="default-select-item"
          selected
          disabled
        >
          {textProps.placeholder}
        </GDMMenuItem>

        {options.map(option => (
          <GDMMenuItem
            key={option.key}
            value={option.id}
            onClick={() => setOptionSelected(option.id)}
          >
            {option.name}
          </GDMMenuItem>
        ))}
      </GDMSelect>

      {showErrorMessage &&
        (isComponentErrorMessage ? (
          helperText
        ) : (
          <GDMFormHelperText sx={selectFieldErrorMessageStyles}>
            {textProps.helperText}
          </GDMFormHelperText>
        ))}
    </GDMFormControl>
  );
};

export default DynamicFormSelect;
