import { GDMComponent } from 'components/GDMComponent.type';

export enum GDMMapTypes {
  field = 'field',
  farm = 'farm',
}

export type GDMMapsProps = {
  type?: GDMMapTypes | `${GDMMapTypes}`;
  InitialCoordinates?: { latitude: string; longitude: string };
  handleMapClick?: (event: { latitude: string; longitude: string }) => void;
  handleMarkerDelete?: (params: { latitude: string; longitude: string }) => void;
  handleClear?: () => void;
  mapPinListLot?: { latitude: string; longitude: string }[];
};

export type GDMMapsComponent = GDMComponent<GDMMapsProps>;
