import Api from 'core/api/clients/api/Api';
import {
  ChangeUserPasswordDomain,
  EditUserProfileDomain,
  UserOnboardingStatusDomain,
  UserProfileDomain,
} from 'core/typing/domains/UserProfileDomain/UserProfileDomain';

const getUserProfile = async (): Promise<{ data: UserProfileDomain }> => {
  return Api.get({
    url: `/UserProfile`,
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const editUserProfile = async (body: EditUserProfileDomain): Promise<void> => {
  return Api.put({
    url: `/UserProfile`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const changeLanguage = async (languageId: string): Promise<void> => {
  return Api.put({
    url: `/UserProfile/Language`,
    body: {
      languageId,
    },
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => {
      return response;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const deleteUserPhotoProfile = async (): Promise<void> => {
  return Api.put({
    url: `/UserProfile/DeleteProfileImage`,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const changeUserPassword = async (body: ChangeUserPasswordDomain): Promise<void> => {
  return Api.put({
    url: `/UserProfile/Password`,
    body,
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const sendOnboardingStatus = async ({
  onboardingStatus: status,
}: UserOnboardingStatusDomain): Promise<void> => {
  return Api.put({
    url: `/UserProfile/Onboarding`,
    body: {
      onboardingStatus: status,
    },
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const termsAcceptance = async () => {
  return Api.put({
    url: '/UserProfile/TermsAcceptance',
    body: {
      termsAccepted: true,
    },
    config: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};
const userTerms = async countryId => {
  if (countryId) {
    return Api.get({
      url: `/TermsOfUse/${countryId}`,
    })
      .then(response => response.data)
      .catch(err => {
        throw err;
      });
  }
  return undefined;
};
export const UserProfileService = {
  getUserProfile,
  editUserProfile,
  deleteUserPhotoProfile,
  sendOnboardingStatus,
  changeUserPassword,
  termsAcceptance,
  userTerms,
  changeLanguage,
};
