import { GDMContainer, GDMLoading, GDMTypography } from 'components';
import { DashboardService } from 'core/api/services/dashboard';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { UserRole } from 'core/typing/domains/UserProfileDomain/UserProfileDomain';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import {
  DashboardFilters,
  DashboardMapCard,
  DashboardProfileData,
  IDashboardFilters,
} from './components';
import {
  dashboardContainerStyles,
  dashboardLoadingContainerStyles,
  internalContainerDashboardStyles,
  titleContainerStyles,
  welcomeTitleStyles,
} from './DashboardPage.styles';

export interface IRecommendation {
  id: string;
  name: string;
  genotype: string;
  cultureName: string;
  technology: string;
  stateId: string;
  recommendationStatusId: string;
  recommendationStatusName: string;
  countryId: string;
  position: google.maps.LatLngLiteral;
  userName: string;
}
dayjs.extend(utc);

export default function DashboardPage() {
  const { theme } = useThemeSwitcher();
  const { getLabelTranslation } = useLanguageSwitcher();
  const { user } = useStore(UserProfileStore);
  const [recomendationList, setRecomendationList] = useState<IRecommendation[]>([]);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [statusData, setStatusData] = useState([]);
  const [dashboardFilters, setDashboardFilters] = useState<IDashboardFilters>({
    startDate: dayjs().startOf('year').toISOString(),
    endDate: dayjs().endOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [period, setPeriod] = useState({
    startDate: dayjs().utc().startOf('year').format('DD/MM/YYYY'),
    finalDate: dayjs().utc().endOf('year').format('DD/MM/YYYY'),
  });

  const getDashboardData = async (filters?: IDashboardFilters) => {
    try {
      setIsLoading(true);
      const body = filters && Object.entries(filters).length > 0 ? filters : null;

      // filter out the values that are all selected in filters to don't send to api
      const newBody =
        !!filters && !!dashboardData?.filter
          ? Object.fromEntries(
              Object.entries(filters)
                .map(([key, value]) => {
                  const verificationValue = dashboardData?.filter[key];

                  if (Array.isArray(verificationValue)) {
                    if (
                      Array.isArray(value) &&
                      Array.isArray(verificationValue) &&
                      value.length === verificationValue.length
                    ) {
                      return [key, undefined];
                    }
                  }
                  return [key, value];
                })
                .filter(([_, value]) => value !== null && value !== '' && value !== undefined), // Filter out unwanted values
            )
          : {};

      const data = await DashboardService.getDashboard(newBody ?? body);

      const recommendations = data.recommendations.map(recommendation => ({
        ...recommendation,
        position: {
          lat: Number(recommendation.latitude),
          lng: Number(recommendation.longitude),
        },
      }));

      const dataUpdated = { ...data, recommendations };

      const status = [
        {
          id: data?.filter?.status?.find(x => x.name === 'Sold')?.id ?? '',
          name: 'WaitingPlanting',
          value: data.waitingPlanting,
          percent: data.waitingPlantingPercentage,
        },
        {
          id: data?.filter?.status?.find(x => x.name === 'Planting')?.id,
          name: 'InPlanting',
          value: data.inPlanting,
          percent: data.inPlantingPercentage,
        },
        {
          id: data?.filter?.status?.find(x => x.name === 'Planted')?.id,
          name: 'Planted',
          value: data.planted,
          percent: data.plantedPercentage,
        },
        {
          id: data?.filter?.status?.find(x => x.name === 'InHarvest')?.id,
          name: 'InHarvest',
          value: data.inHarvest,
          percent: data.inHarvestPercentage,
        },
        {
          id: data?.filter?.status?.find(x => x.name === 'Harvested')?.id,
          name: 'Harvested',
          value: data.harvested,
          percent: data.harvestedPercentage,
        },
        {
          id: data?.filter?.status?.find(x => x.name === 'Interrupted')?.id,
          name: 'Interrupted',
          value: data.interrupted,
          percent: data.interruptedPercentage,
        },
      ];

      setStatusData(status);
      setRecomendationList(recommendations);
      setDashboardData(dataUpdated);
      setPeriod({
        startDate: dayjs(filters?.startDate || dayjs().startOf('year')).format('DD/MM/YYYY'),
        finalDate: dayjs(filters?.endDate || dayjs().endOf('year')).format('DD/MM/YYYY'),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const handleUpdateDashboard = async (filters?: IDashboardFilters): Promise<void> => {
    await getDashboardData(filters);
  };

  return (
    <>
      {isLoading && (
        <GDMContainer sx={dashboardLoadingContainerStyles}>
          <GDMLoading />
        </GDMContainer>
      )}
      <GDMContainer sx={dashboardContainerStyles}>
        <GDMContainer sx={internalContainerDashboardStyles}>
          <GDMContainer sx={titleContainerStyles}>
            <GDMTypography sx={welcomeTitleStyles(theme)}>
              {getLabelTranslation(`dash_welcome_title`)} <b>{user?.name || ''}</b>
            </GDMTypography>
          </GDMContainer>

          {dashboardData?.filter && (
            <DashboardFilters
              dashboardFilters={dashboardFilters}
              setDashboardFilters={setDashboardFilters}
              filters={dashboardData?.filter}
              isAdmin={user.role === UserRole.ADMINISTRATOR}
              onUpdateDashboard={handleUpdateDashboard}
            />
          )}

          <DashboardProfileData dashboardData={dashboardData} />

          <DashboardMapCard
            period={period}
            statusData={statusData}
            dashboardData={dashboardData}
            dashboardFilters={dashboardFilters}
            setDashboardFilters={setDashboardFilters}
            handleUpdateDashboard={handleUpdateDashboard}
          />
        </GDMContainer>
      </GDMContainer>
    </>
  );
}
