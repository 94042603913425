import { GDMInputLabel } from 'components/gdm-input-label';
import { useLanguageContext } from 'core/store/contexts/languageContext';
import { useEffect, useState } from 'react';
import {
  dynamicFormLabelRequiredIndicatorStyles,
  dynamicFormLabelStyles,
} from './DynamicFormLabel.styles';
import { DynamicFormLabelComponent } from './DynamicFormLabel.type';

const DynamicFormLabel: DynamicFormLabelComponent = ({ required, id, label }) => {
  const { getLabelTranslation, currentLanguage } = useLanguageContext();

  const [labelText, setLabelText] = useState(getLabelTranslation(label));

  useEffect(() => {
    setLabelText(getLabelTranslation(label));
  }, [currentLanguage, label]);

  return (
    <GDMInputLabel id={id} sx={dynamicFormLabelStyles}>
      {labelText} {!!required && <span style={dynamicFormLabelRequiredIndicatorStyles}>*</span>}
    </GDMInputLabel>
  );
};

export default DynamicFormLabel;
