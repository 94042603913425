/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
import { Resolver, useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';

import LoadChangeUserPasswordUseCase from 'core/api/useCases/UserProfileUseCase/ChangeUserPasswordUseCase';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, IconButton, Typography, useTheme } from '@mui/material';

import useModal from 'hooks/useModal/useModal';

import { GDMBox } from 'components';
import { actionButtonsStyle, modalContainerStyle } from './ChangePasswordModal.style';
import { PasswordFormProps, PasswordModalProps } from './ChangePasswordModal.types';
import PasswordInput from './PasswordInput/PasswordInput';
import { validationSchema } from './validationSchema ';

export const ChangePasswordModal: React.FC<PasswordModalProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  const { openModal } = useModal();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<PasswordFormProps>({
    resolver: yupResolver(validationSchema) as unknown as Resolver<PasswordFormProps>,
  });

  const onSubmit = (data: PasswordFormProps) => {
    if (Object.keys(errors).length > 0) {
      console.error('Há erros no formulário:', errors);
    } else {
      try {
        LoadChangeUserPasswordUseCase.execute(data);
        openModal({
          type: 'success',
          title: getLabelTranslation('password_message'),
          onClose: () => true,
        });
        reset();
      } catch (error) {
        console.error(error);
        openModal({
          type: 'error',
          title: getLabelTranslation('modal_tittle_register_error'),
          message: getLabelTranslation('modal_message_register_error'),
          onClose: () => true,
        });
      }
    }

    handleClose();
  };

  const handleCancelChangePassword = () => {
    handleClose();
    reset();
  };

  const { getLabelTranslation } = useLanguageSwitcher();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: { xs: '16px', md: '32px' },
          maxWidth: '613px',
          maxHeight: '100%',
          overflow: 'hidden',
        },
      }}
    >
      <GDMBox
        sx={{
          overflowY: 'auto',
          padding: {
            xs: '32px 16px',
            md: '32px 52px',
          },
        }}
      >
        <form
          id="change-password-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={modalContainerStyle}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              width: '70px',
              justifySelf: 'flex-end',
              ':hover': {
                background: 'none',
              },
            }}
          >
            <IoClose color="#222222" width={28} height={28} />
          </IconButton>
          <Typography
            gap={26}
            color="#222"
            fontWeight={700}
            sx={{
              justifySelf: 'center',
              fontSize: { xs: '24px', md: '24px' },
              textAlign: 'center',
            }}
          >
            {getLabelTranslation('change_your_password')}
          </Typography>
          <Typography
            fontSize={16}
            gap={26}
            width="100%"
            color="#777D88"
            fontWeight={400}
            sx={{ textAlign: 'center' }}
          >
            {getLabelTranslation('modal_password_subtitle')}
          </Typography>
          <PasswordInput
            label={getLabelTranslation('changePasswordModal_actual_password')}
            name="currentPassword"
            error={!!errors.currentPassword}
            errors={errors}
            register={register}
            control={control}
          />
          <PasswordInput
            label={getLabelTranslation('changePasswordModal_new_password')}
            name="newPassword"
            error={!!errors.newPassword}
            errors={errors}
            register={register}
            control={control}
          />
          <PasswordInput
            label={getLabelTranslation('changePasswordModal_repeat_password')}
            name="confirmPassword"
            error={!!errors.confirmPassword}
            errors={errors}
            register={register}
            control={control}
          />
          <Typography
            fontSize={16}
            gap={26}
            width="100%"
            color="#777D88"
            fontWeight={400}
            sx={{ textAlign: 'center' }}
          >
            {getLabelTranslation('changePasswordModal_validation_message')}
          </Typography>
          <Box sx={actionButtonsStyle}>
            <Button
              type="button"
              variant="contained"
              sx={{
                boxShadow: 'none',
                color: theme.palette.secondary.dark,
                background: theme.palette.secondary.main,
                ':hover': {
                  boxShadow: 'none',
                  background: theme.palette.secondary.light,
                },
                marginRight: '24px',
              }}
              onClick={handleCancelChangePassword}
            >
              {getLabelTranslation('changePasswordModal_cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: 'none',
                color: theme.palette.secondary.contrastText,
                background: theme.palette.primary.main,
                ':hover': {
                  boxShadow: 'none',
                  background: theme.palette.primary.light,
                },
              }}
              type="submit"
            >
              {getLabelTranslation('changePasswordModal_save')}
            </Button>
          </Box>
        </form>
      </GDMBox>
    </Dialog>
  );
};
