import { GDMContainer } from 'components';
import { pageCardStyles } from './GDMPageCard.styles';
import { GDMPageCardComponent } from './GDMPageCard.type';
import { GDMPageCardFooter, GDMPageCardHeader } from './components';

const GDMPageCard: GDMPageCardComponent = ({
  title,
  breadcrumbs,
  footer,
  header,
  children,
  footerActions = [],
  sx = {},
}) => {
  const styles = { ...pageCardStyles, ...sx };

  return (
    <GDMContainer sx={styles}>
      {!!header && header}
      {!header && (!!title || !!breadcrumbs) && <GDMPageCardHeader {...{ breadcrumbs, title }} />}

      {children}

      {!footer && !!footerActions.length && <GDMPageCardFooter {...{ footerActions }} />}
      {!!footer && footer}
    </GDMContainer>
  );
};

export default GDMPageCard;
