import { LocationsService } from 'core/api/services/locations';
import { GetStatesFunction } from './getStates.type';

const getStates: GetStatesFunction = async ({
  countryId,
  onInit,
  onSuccess,
  onError,
  onFinally,
   source
}) => {
  if (!!onInit) onInit();
  try {
    const states = await LocationsService.getStates(countryId, source);

    if (!!onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (!!onError) onError(error);

    return [];
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default getStates;
