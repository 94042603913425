import { BrandsServices } from 'core/api/services/brands';
import { LoadBrandsFunction } from './loadBrands.type';

const loadBrands: LoadBrandsFunction = async ({ onInit, onSuccess, onError, onFinally }) => {
  if (onInit) onInit();

  try {
    const states = await BrandsServices.getBrands();

    if (onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (onError) onError(error);

    return [];
  } finally {
    if (onFinally) onFinally();
  }
};

export default loadBrands;
