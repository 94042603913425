import { SellerGDMService } from 'core/api/services/sellerGDM';
import { LoadGDMSellersFunction } from './loadGDMSellers.type';

const loadGDMSellers: LoadGDMSellersFunction = async ({
  payload,
  onInit,
  onSuccess,
  onError,
  onFinally,
}) => {
  if (onInit) onInit();

  try {
    const states = await SellerGDMService.getSellerGDM(payload);

    if (onSuccess) onSuccess(states);

    return states;
  } catch (error) {
    console.error(error);

    if (onError) onError(error);

    return [];
  } finally {
    if (onFinally) onFinally();
  }
};

export default loadGDMSellers;
