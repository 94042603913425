/* eslint-disable react/no-array-index-key */
import { LiaUserLockSolid } from 'react-icons/lia';
import { PiFlowerTulipLight, PiNotebookDuotone } from 'react-icons/pi';
import { RiHome4Line, RiListView, RiPlantLine } from 'react-icons/ri';

import { CollapsibleMenu } from 'core/components/CollapsibleMenu';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { GDMDrawer, GDMList, GDMListItem, GDMListItemIcon, GDMListItemText } from 'components';

import colors from '../../design-system/style-guide/colors.json';
import { drawerStyle, paperStyle } from './SideBar.styles';
import { MenuItem, SideBarProps } from './SideBar.types';

export function SideBar({ handleNavigation, handleToggle, isOpen }: SideBarProps) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const {
    user: { role },
  } = useStore(UserProfileStore);

  const { getLabelTranslation } = useLanguageSwitcher();

  const permissionsMap = {
    ADMINISTRATOR: [
      'admin',
      'seller-gdm',
      'multiplier',
      'distributor',
      'account',
      'list',
      'farmer',
    ],
    SELLER_GDM: ['seller-gdm', 'list'],
    MULTIPLIER_MANAGER: ['multiplier', 'list'],
    DISTRIBUTOR_MANAGER: ['distributor', 'list'],
    MULTIPLIER_SELLER: ['seller-gdm', 'multiplier', 'list'],
    DISTRIBUTOR_SELLER: ['seller-gdm', 'distributor', 'list'],
    Agricultor: ['farmer', 'list'],
  };

  const itemsMenuToRender: MenuItem[] = [
    {
      title: getLabelTranslation('home'),
      icon: <RiHome4Line style={{ color: colors.white }} />,
      link: '/',
      permissions: [
        'admin',
        'seller-gdm',
        'multiplier',
        'distributor',
        'account',
        'list',
        'farmer',
      ],
    },
    ...(role !== 'MULTIPLIER_SELLER' && role !== 'DISTRIBUTOR_SELLER'
      ? [
          {
            title: getLabelTranslation('administrativo'),
            icon: <LiaUserLockSolid />,
            permissions: [
              'admin',
              'seller-gdm',
              'multiplier',
              'distributor',
              'account',
              'list',
              'farmer',
            ],
            subitems: [
              {
                pageKey: 'admin-register',
                title: getLabelTranslation('cadastrar_administrador'),
                link: '/admins/new',
                permissions: ['admin'],
              },
              {
                pageKey: 'seller-gdm',
                title: getLabelTranslation('cadastrar_vendedor_gdm'),
                link: '/account-seller-gdm/new',
                permissions: ['admin'],
              },
              {
                pageKey: 'multiplier-register',
                title: getLabelTranslation('cadastrar_multiplicador'),
                link: '/multipliers/new',
                permissions: ['admin'],
              },
              {
                pageKey: 'distributor-register',
                title: getLabelTranslation('cadastrar_distribuidor'),
                link: '/distributor/new',
                permissions: ['admin'],
              },
              {
                pageKey: 'account-register',
                title: getLabelTranslation('cadastrar_conta'),
                link: '/accounts-register',
                permissions: ['admin'],
              },
              {
                pageKey: 'list-menu',
                title: getLabelTranslation('ver_cadastros'),
                link: '/list-menu',
                permissions: [
                  'admin',
                  'seller-gdm',
                  'multiplier',
                  'distributor',
                  'account',
                  'list',
                  'farmer',
                ],
              },
            ],
          },
        ]
      : []),
    {
      title: getLabelTranslation('agricultor'),
      icon: <RiPlantLine />,
      permissions: [
        'admin',
        'seller-gdm',
        'multiplier',
        'distributor',
        'account',
        'list',
        'farmer',
      ],
      subitems: [
        {
          title: getLabelTranslation('cadastrar_agricultor'),
          link: '/farmer-register',
          permissions: [
            'admin',
            'seller-gdm',
            'multiplier',
            'distributor',
            'account',
            'list',
            'farmer',
          ],
        },
        {
          title: getLabelTranslation('ver_cadastros_agricultor'),
          link: '/list-menu-farmer',
          permissions: [
            'admin',
            'seller-gdm',
            'multiplier',
            'distributor',
            'account',
            'list',
            'farmer',
          ],
        },
      ],
    },
    {
      title: getLabelTranslation('catalogo_de_produtos'),
      link: '/',
      icon: <RiListView style={{ color: colors.white }} />,
      permissions: [
        'admin',
        'seller-gdm',
        'multiplier',
        'distributor',
        'account',
        'list',
        'farmer',
      ],
      subitems: [
        {
          title: getLabelTranslation('visualize_products'),
          link: '/catalog',
          permissions: [
            'admin',
            'seller-gdm',
            'multiplier',
            'distributor',
            'account',
            'list',
            'farmer',
          ],
        },
      ],
    },
    {
      title: getLabelTranslation('recomendacao'),
      icon: <PiFlowerTulipLight />,
      permissions: [
        'admin',
        'seller-gdm',
        'multiplier',
        'distributor',
        'account',
        'list',
        'farmer',
      ],
      subitems: [
        {
          title: getLabelTranslation('gera_recomendacao'),
          link: '/recommendation',
          permissions: [
            'admin',
            'seller-gdm',
            'multiplier',
            'distributor',
            'account',
            'list',
            'farmer',
          ],
        },
        {
          title: getLabelTranslation('ver_recomendacoes'),
          link: '/recommendation-list',
          permissions: [
            'admin',
            'seller-gdm',
            'multiplier',
            'distributor',
            'account',
            'list',
            'farmer',
          ],
        },
      ],
    },
  ];

  const filterMenuByPermission = (userProfile, itemsMenu) => {
    const userPermissions = permissionsMap[userProfile] || [];

    const hasPermission = requiredPermissions => {
      return requiredPermissions.some(permission => userPermissions.includes(permission));
    };

    return itemsMenu
      .filter(item => hasPermission(item.permissions))
      .map(item => ({
        ...item,
        subitems: item.subitems?.filter(subItem => hasPermission(subItem.permissions)),
      }));
  };

  const filteredItemsMenuToRender = filterMenuByPermission(role, itemsMenuToRender);

  return (
    <GDMDrawer
      variant={isDesktop ? 'permanent' : 'temporary'}
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        sx: paperStyle,
      }}
      sx={drawerStyle}
      open={isOpen}
      onClose={handleToggle}
    >
      <GDMList style={{ width: '291px', marginTop: '20px' }}>
        {filteredItemsMenuToRender.map((item, index) => {
          const itemKey = `collapse-menu-item-${index}-[${item.title}]`;

          if (item.subitems) {
            return (
              <CollapsibleMenu key={itemKey} icon={item.icon} title={item.title}>
                {item.subitems.map((subItem, subIndex) => {
                  const subItemKey = `collapse-menu-subitem-${index}-${subIndex}-[${subItem.title}]`;

                  return (
                    <GDMListItem
                      key={subItemKey}
                      onClick={() => {
                        handleNavigation(subItem.link);
                      }}
                    >
                      <GDMListItemText
                        sx={{
                          ':hover': {
                            background: 'primary.dark',
                          },
                          cursor: 'pointer',
                        }}
                        primaryTypographyProps={{
                          variant: 'body1',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '19.6px',
                        }}
                        primary={subItem.title}
                      />
                    </GDMListItem>
                  );
                })}
              </CollapsibleMenu>
            );
          }
          return (
            <GDMListItem key={index} onClick={() => handleNavigation(item.link)}>
              <GDMListItemIcon style={{ minWidth: '2.275rem' }}>{item.icon}</GDMListItemIcon>
              <GDMListItemText
                primaryTypographyProps={{ variant: 'body2', fontSize: '16px' }}
                primary={item.title}
              />
            </GDMListItem>
          );
        })}
      </GDMList>
      <GDMListItem
        onClick={() => window.open(`${getLabelTranslation(`form_report_link`)}`, '_blank')}
        sx={{ paddingTop: '0' }}
      >
        <PiNotebookDuotone style={{ color: colors.white }} />
        <GDMListItemText
          sx={{ marginLeft: '16px' }}
          primaryTypographyProps={{ variant: 'body2', fontSize: '16px' }}
          primary={getLabelTranslation(`errors_and_suggestions`)}
        />
      </GDMListItem>
    </GDMDrawer>
  );
}
