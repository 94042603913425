import {
  Divider,
  IconButton,
  ListItemIcon,
  Table,
  TableBody,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  GDMCollapse,
  GDMContainer,
  GDMIconButton,
  GDMList,
  GDMListItem,
  GDMListItemButton,
  GDMTableCell,
  GDMTableContainer,
  GDMTableHead,
  GDMTableRow,
  GDMTypography,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { FiEdit } from 'react-icons/fi';
import { IoAddOutline, IoEyeOutline } from 'react-icons/io5';
import { LuTrash2 } from 'react-icons/lu';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { PiMapPinAreaLight } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { stringTruncate } from 'utils/formaters';

export const GDMFarmList = ({
  farms,
  openModal,
  setOrderColumn,
  orderColumn,
}: {
  farms: any;
  openModal: (string, boolean) => void;
  setOrderColumn: (any) => void;
  orderColumn: any;
}) => {
  const theme = useTheme();
  const { getLabelTranslation } = useLanguageSwitcher();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [rows] = useState(farms);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const columnTitle: { name: string; title: any; type: string }[] = [
    {
      name: 'Name',
      title: getLabelTranslation('name'),
      type: 'text',
    },
    {
      name: 'StateName',
      title: getLabelTranslation('state_name'),
      type: 'text',
    },
    {
      name: 'CityName',
      title: getLabelTranslation('city_name'),
      type: 'text',
    },
    {
      name: 'TotalArea',
      title: getLabelTranslation('total_area'),
      type: 'text',
    },
  ];

  const changeOrderColumn = column => {
    setOrderColumn({
      column,
      flap: 'farm',
      orderDesc: !orderColumn.orderDesc,
      entityName: 'FarmEntity',
    });
  };
  const handleClick = id => {
    setOpen(open === id ? null : id);
  };
  return (
    <GDMContainer sx={{ paddingLeft: '16px' }}>
      <GDMTableContainer>
        <Table>
          {isDesktop && (
            <GDMTableHead>
              <TableRow sx={{ background: '#F2F4F9' }}>
                {columnTitle.map(column => (
                  <GDMTableCell
                    sx={{
                      border: 'none',
                    }}
                  >
                    <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                      <GDMTypography
                        variant="subtitle2"
                        sx={{
                          color: '#777D88',
                        }}
                      >
                        {column.title}
                      </GDMTypography>
                      <IconButton
                        onClick={() => {
                          changeOrderColumn(column.name);
                        }}
                      >
                        <CgArrowsExchangeAltV />
                      </IconButton>
                    </GDMContainer>
                  </GDMTableCell>
                ))}
                <GDMTableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('map')}
                    </GDMTypography>
                  </GDMContainer>
                </GDMTableCell>
                <GDMTableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('lot')}
                    </GDMTypography>
                  </GDMContainer>
                </GDMTableCell>
                <GDMTableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('to_edit')}
                    </GDMTypography>
                  </GDMContainer>
                </GDMTableCell>
                <GDMTableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('to_delete')}
                    </GDMTypography>
                  </GDMContainer>
                </GDMTableCell>
              </TableRow>
            </GDMTableHead>
          )}
          <TableBody>
            {rows?.length > 0 &&
              isDesktop &&
              rows.map(row => (
                <GDMTableRow>
                  <GDMTableCell>
                    <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                      {stringTruncate(row.name, 35)}
                    </GDMTypography>
                  </GDMTableCell>
                  <GDMTableCell>
                    <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                      {row.stateName}
                    </GDMTypography>
                  </GDMTableCell>
                  <GDMTableCell>
                    <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                      {row.cityName}
                    </GDMTypography>
                  </GDMTableCell>
                  <GDMTableCell>
                    <GDMTypography sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}>
                      {row.totalArea === 'null' ? 0 : stringTruncate(row.totalArea, 10)}
                    </GDMTypography>
                  </GDMTableCell>
                  <GDMTableCell>
                    <a
                      aria-label="redirect"
                      target="_blank"
                      href={`https://www.google.com/maps?q=${row.latitude},${row.longitude}`}
                      rel="noreferrer noopener"
                      style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
                    >
                      <PiMapPinAreaLight style={{ color: '#777D88', fontSize: '28px' }} />
                    </a>
                  </GDMTableCell>
                  <GDMTableCell>
                    <GDMContainer
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '24px',
                      }}
                    >
                      <GDMTypography>{row.lotQuantity}</GDMTypography>
                      <GDMIconButton
                        onClick={() => {
                          navigate(`/farmer-register/${row.id}/lot-step`);
                        }}
                        sx={{
                          background: '#0A3C56',
                          borderRadius: '4px',
                          color: '#fff',
                          height: '24px',
                          width: '24px',
                          padding: 0,
                          '&:hover': { background: 'rgba(10, 60, 86, 0.8)' },
                        }}
                      >
                        <IoAddOutline color="#fff" />
                      </GDMIconButton>
                    </GDMContainer>
                  </GDMTableCell>
                  <GDMTableCell sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <GDMIconButton onClick={() => navigate(`/farmer-farm-register/${row.id}/edit`)}>
                      <FiEdit />
                    </GDMIconButton>
                  </GDMTableCell>
                  <GDMTableCell>
                    <GDMIconButton
                      sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
                      onClick={() => {
                        openModal(row.id, row.canBeDeleted);
                      }}
                    >
                      <LuTrash2 style={{ fontSize: '24px' }} />
                    </GDMIconButton>
                  </GDMTableCell>
                </GDMTableRow>
              ))}

            {rows?.length > 0 &&
              !isDesktop &&
              rows.map(item => (
                <GDMContainer key={item.id} sx={{ background: 'transparent' }}>
                  <GDMListItemButton onClick={() => handleClick(item.id)} sx={{ pl: 0, pr: 0 }}>
                    <GDMListItem sx={{ pl: 0 }}>
                      <GDMTypography variant="body2">{getLabelTranslation('name')} </GDMTypography>
                      <GDMTypography variant="body1" ml={1}>{` ${item.name}`}</GDMTypography>
                    </GDMListItem>
                    <GDMIconButton
                      sx={{
                        color: '#222',
                        width: '32px',
                        height: '32px',
                        backgroundColor: open === item.id ? '#43ACE3' : '#CFEFFF',
                        borderRadius: '50%',
                      }}
                    >
                      {open === item.id ? <MdExpandLess /> : <MdExpandMore />}
                    </GDMIconButton>
                  </GDMListItemButton>
                  <GDMCollapse
                    in={open === item.id}
                    timeout="auto"
                    unmountOnExit
                    sx={{ background: 'transparent' }}
                  >
                    <GDMList component="div" disablePadding>
                      <GDMListItem sx={{ pl: 0 }}>
                        <GDMTypography variant="body2">
                          {getLabelTranslation('state_name')}{' '}
                        </GDMTypography>
                        <GDMTypography variant="body1" ml={1}>{` ${item.stateName}`}</GDMTypography>
                      </GDMListItem>
                      <GDMListItem sx={{ pl: 0 }}>
                        <GDMTypography variant="body2">
                          {getLabelTranslation('city_name')}{' '}
                        </GDMTypography>
                        <GDMTypography variant="body1" ml={1}>{` ${item.cityName}`}</GDMTypography>
                      </GDMListItem>
                      <GDMListItem sx={{ pl: 0, display: 'flex', gap: '8px' }}>
                        <GDMTypography variant="body2">{getLabelTranslation('lot')}</GDMTypography>
                        <GDMTypography
                          onClick={() => {
                            navigate(`/farmer-form-register/${item.id}/lot-flap`);
                          }}
                          variant="body1"
                          ml={1}
                          sx={{
                            fontSize: '16px',
                            lineHeight: '19.6px',
                            color: '#0063F7',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                          }}
                        >{` ${item.lotQuantity ? item.lotQuantity : 0}`}</GDMTypography>
                        <button
                          onClick={() => {
                            navigate(`/farmer-register/${item.id}/lot-step`);
                          }}
                          aria-label="farm"
                          type="button"
                          style={{
                            background: '#0A3C56',
                            borderRadius: '4px',
                            color: '#fff',
                            border: 'none',
                            fontSize: '22px',
                            display: 'flex',
                          }}
                        >
                          <IoAddOutline color="#fff" />
                        </button>
                      </GDMListItem>
                      <GDMListItem sx={{ pl: 0 }}>
                        <GDMTypography variant="body2">
                          {getLabelTranslation('total_planted_area')}{' '}
                        </GDMTypography>
                        <GDMTypography variant="body1" ml={1}>
                          {item.totalArea}
                        </GDMTypography>
                      </GDMListItem>
                      <GDMListItem
                        sx={{ pl: 0, pr: 0, display: 'flex', justifyContent: 'space-between' }}
                      >
                        <GDMTypography variant="body2">
                          {getLabelTranslation('actions')}
                        </GDMTypography>
                        <GDMContainer
                          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                          <ListItemIcon
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              fontSize: '28px',
                            }}
                          >
                            <a
                              aria-label="redirect"
                              target="_blank"
                              href={`https://www.google.com/maps?q=${item.latitude},${item.longitude}`}
                              rel="noreferrer noopener"
                            >
                              <PiMapPinAreaLight style={{ color: '#777D88', fontSize: '28px' }} />
                            </a>
                          </ListItemIcon>

                          <ListItemIcon
                            onClick={() => navigate(`/farmer-farm-register/${item.id}`)}
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              fontSize: '24px',
                            }}
                          >
                            <IoEyeOutline />
                          </ListItemIcon>
                          <ListItemIcon
                            onClick={() => navigate(`/farmer-farm-register/${item.id}/edit`)}
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              display: 'flex',
                              fontSize: '20px',
                            }}
                          >
                            <FiEdit />
                          </ListItemIcon>
                          <ListItemIcon
                            sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                          >
                            <GDMIconButton
                              onClick={() => {
                                openModal(item.id, item.canBeDeleted);
                              }}
                            >
                              <LuTrash2 style={{ fontSize: '24px' }} />
                            </GDMIconButton>
                          </ListItemIcon>
                        </GDMContainer>
                      </GDMListItem>
                    </GDMList>
                  </GDMCollapse>
                  <Divider />
                </GDMContainer>
              ))}
          </TableBody>
        </Table>
        {rows && rows.length === 0 && (
          <GDMTypography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '32px',
              paddingBottom: '32px',
              color: '#222',
            }}
          >
            {getLabelTranslation('farmer_no_farms')}
          </GDMTypography>
        )}
      </GDMTableContainer>
    </GDMContainer>
  );
};
