import { GDMContainer } from 'components';
import { GDMTypography } from 'components/gdm-typography';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { BsCloudRain } from 'react-icons/bs';
import { TbAtom, TbChartPie2, TbDna2, TbDroplet, TbTemperature } from 'react-icons/tb';

const GDMLineInfo = ({
  id,
  name,
  value,
  type,
  legend,
}: {
  id: string;
  name: string;
  type: string | number;
  value: string;
  legend?: string;
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();

  let icon;

  if (id === 'clay') {
    icon = <TbChartPie2 color="#A1A9B8" size={24} />;
  } else if (id === 'prec') {
    icon = <BsCloudRain color="#A1A9B8" size={24} />;
  } else if (id === 'temp') {
    icon = <TbTemperature color="#A1A9B8" size={24} />;
  } else if (id === 'heterodera') {
    icon = <TbDna2 color="#A1A9B8" size={24} />;
  } else if (id === 'water') {
    icon = <TbDroplet color="#A1A9B8" size={24} />;
  } else if (id === 'melo') {
    icon = <TbAtom color="#A1A9B8" size={24} />;
  } else if (id === 'om') {
    icon = <TbDroplet color="#A1A9B8" size={24} />;
  } else {
    icon = <TbChartPie2 color="#A1A9B8" size={24} />;
  }

  return (
    <GDMContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {icon}
        <GDMTypography sx={{ color: '#222222', fontSize: '18px', fontWeight: 700 }}>
          {name === "productivity" ? getLabelTranslation("recommendation_variety_productivity") : getLabelTranslation(`recommendation_params_field_${name}`)}
        </GDMTypography>
      </GDMContainer>
      <GDMContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          width: '20%',
          justifyContent: 'flex-end',
        }}
      >
        {type === 'number' ? (
          <>
            <GDMTypography
              sx={{
                background: '#F2F4F9',
                fontWeight: 700,
                padding: '3px 8px',
                fontSize: '16px',
                borderRadius: '8px',
              }}
            >
              {value}
            </GDMTypography>
            {legend && (
              <GDMTypography sx={{ color: '#222222', fontSize: '18px', fontWeight: 700 }}>
                {legend}
              </GDMTypography>
            )}
          </>
        ) : (
          <GDMTypography sx={{ color: '#777D88', fontSize: '18px' }}>
            {Number.isNaN(Number(value)) ? getLabelTranslation(value) : value}
          </GDMTypography>
        )}
      </GDMContainer>
    </GDMContainer>
  );
};

export default GDMLineInfo;
