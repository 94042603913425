import { SxProps } from '@mui/material';

export const autoCompleteStyles: SxProps = {
  input: {
    border: 'none',
  },
  '& .Mui-disabled': {
    background: '#F2F4F9',
  },
};

export const autoCompleteInputStyles: SxProps = {
  boxSizing: 'border-box',
  minHeight: '48px',
  '& ::placeholder': {
    color: '#A1A9B8',
    opacity: 0.95,
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '4px!important',
    paddingTop: '4px!important',
    paddingBottom: '4px!important',
    alignItems: 'center',
  },
  '& .MuiInputBase-root, & .MuiInputBase-root input': {
    height: '100%',
    minHeight: '40px',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '19.6px',
    border: 'none',
  },
  '& .Mui-disabled': {
    background: '#F2F4F9',
  },
  '& .MuiChip-label': {
    fontSize: '14px',
    lineHeight: 'normal',
  },
  '& .MuiInputBase-root input': {
    paddingLeft: '16px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A1A9B8',
  },

  '& .MuiButtonBase-root svg': {
    fontSize: '20px',
  },
  '& .MuiFormHelperText-root': {
    background: 'transparent!important',
    marginTop: '0px',
    paddingTop: '4px',
    height: '24px',
  },
  '& .Mui-error': {
    color: '#FB2047',
  },
};
