export enum UserRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  SELLER_GDM = 'SELLER_GDM',
  MULTIPLIER_MANAGER = 'MULTIPLIER_MANAGER',
  DISTRIBUTOR_MANAGER = 'DISTRIBUTOR_MANAGER',
  MULTIPLIER_SELLER = 'MULTIPLIER_SELLER',
  DISTRIBUTOR_SELLER = 'DISTRIBUTOR_SELLER',
}

export type UserProfileDomain = {
  id: string;
  role: UserRole;
  name: string;
  email: string;
  phoneNumber: string;
  documentTypeId: string;
  documentTypeAcronym: string;
  documentNumber: string;
  countryId: string;
  countryName: string;
  languageId: string;
  languageIso: string;
  imageCdnUrl?: any;
  fantasyName?: any;
  regionId?: any;
  brands: any[];
  onboardingStatus: boolean;
  termsAccepted: boolean;
  isLogged?: boolean;
  emailReceiptAccepted: boolean;
  hasNotifications: boolean;
  totalPendingPlanting: number;
  totalPendingHarvest: number;
  multiplierName?: string;
  distributorName?: string;
};

export type EditUserProfileDomain = {
  imageFile?: string;
  name: string;
  email?: string;
  phoneNumber: string;
  documentTypeId: string;
  documentNumber: string;
  countryId: string;
  fantasyName?: any;
  regionId?: any;
  emailReceiptAccepted: boolean;
};
export type ChangeUserPasswordDomain = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type UserOnboardingStatusDomain = {
  onboardingStatus: boolean;
};
