import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Drawer,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { GDMButton, GDMContainer, GDMTextField, GDMTypography } from 'components';
import GDMInputCheckbox from 'components/gdm-input-checkbox/GDMInputCheckbox';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { CiFilter } from 'react-icons/ci';
import { FaCircle } from 'react-icons/fa6';
import { IoIosArrowDown } from 'react-icons/io';
import { IoClose, IoSearchOutline } from 'react-icons/io5';

const CatalogFilter = ({ filters, setFilter, filter }) => {
  const { theme } = useThemeSwitcher();
  const isDesktop = useMediaQuery('(min-width:960px)');
  const { getLabelTranslation } = useLanguageSwitcher();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [searchInputs, setSearchInputs] = useState({
    genotypeInput: '',
  });
  const [searchFilters, setSearchFilters] = useState({
    genotypes: [],
  });
  const [filtersApplied, setFiltersApplied] = useState([]);

  const clearFilter = () => {
    setFiltersApplied([]);
    setFilter(prev => ({
      ...prev,
      brand: [],
      culture: [],
      technology: [],
      genotype: [],
    }));
  };

  const handleFilterSearch = ({ query }: { query: string }) => {
    if (query) {
      const newArray = filters.genotype.filter(obj =>
        obj.name.toLowerCase().includes(query.toLowerCase()),
      );
      setSearchInputs(prev => ({ ...prev, genotypeInput: query }));
      setSearchFilters(prev => ({ ...prev, genotypes: newArray }));
    }
    if (!query) {
      setSearchInputs(prev => ({ ...prev, genotypeInput: '' }));
      setSearchFilters(prev => ({ ...prev, genotypes: filters.genotype }));
    }
  };

  const handleApplyFilters = () => {
    filtersApplied.forEach(filterLocked => {
      switch (filterLocked.type) {
        case 'brand':
          setFilter(prev => ({ ...prev, brand: [...prev.brand, filterLocked.id] }));
          break;
        case 'culture':
          setFilter(prev => ({ ...prev, culture: [...prev.culture, filterLocked.id] }));
          break;
        case 'genotype':
          setFilter(prev => ({ ...prev, genotype: [...prev.genotype, filterLocked.id] }));
          break;
        case 'technology':
          setFilter(prev => ({ ...prev, technology: [...prev.technology, filterLocked.id] }));
          break;
        default:
          console.error('option not listed');
      }
    });
    setOpenFilterDrawer(false);
  };
  const handleCheck = ({ obj, type }: { obj: any; type: string }) => {
    if (!filtersApplied.some(item => JSON.stringify(item.id) === JSON.stringify(obj.id))) {
      setFiltersApplied(prev => [...prev, { id: obj.id, name: obj.name, type }]);
    } else {
      setFiltersApplied(filtersApplied.filter(filter => filter.id !== obj.id));

      switch (type) {
        case 'brand':
          setFilter(prev => ({
            ...prev,
            brand: filter.brand.filter(brandId => brandId !== obj.id),
          }));
          break;
        case 'culture':
          setFilter(prev => ({
            ...prev,
            culture: filter.culture.filter(cultureId => cultureId !== obj.id),
          }));
          break;
        case 'genotype':
          setFilter(prev => ({
            ...prev,
            genotype: filter.genotype.filter(genotypeId => genotypeId !== obj.id),
          }));
          break;
        case 'technology':
          setFilter(prev => ({
            ...prev,
            technology: filter.technology.filter(technologyId => technologyId !== obj.id),
          }));
          break;
        default:
          console.error('option not listed');
      }
    }
  };

  useEffect(() => {
    if (filters) {
      setSearchFilters({
        genotypes: filters.genotype,
      });
    }
  }, [filters]);

  return (
    <>
      <GDMContainer
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          gap: '24px',
          flexDirection: isDesktop ? 'row' : 'column',
        }}
      >
        <GDMButton
          sx={{
            padding: '15.5px 24px 15.5px 14px',
            color: '#222',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            border: '1px solid #A1A9B8',
            borderRadius: '6px',
            height: '58px',
            justifyContent: isDesktop ? 'center' : 'space-between',
            backgroundColor: '#FAFAFA',
          }}
          onClick={() => setOpenFilterDrawer(true)}
        >
          {filtersApplied.length > 0 && (
            <FaCircle
              size={10}
              color="red"
              style={{ position: 'absolute', right: '20px', top: '18px' }}
            />
          )}
          {getLabelTranslation('filter_by')} <CiFilter />
        </GDMButton>
      </GDMContainer>

      <Drawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        anchor="right"
        sx={{
          '& .MuiDrawer-paper': {
            marginRight: isDesktop && '40px',
            padding: '42px 36px',
            height: isDesktop ? '85vh' : '90%',
            top: '12vh',
            borderRadius: '12px',
            width: isDesktop ? '400px' : '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              paddingLeft: '8px',
              width: '8px',
              background: '#fafafa',
              border: '1px solid #cbcdd9',
              borderRadius: '24px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#cbcdd9',
              borderRadius: '24px',
            },
          },
        }}
      >
        <GDMContainer>
          <GDMContainer sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
            <IconButton onClick={() => setOpenFilterDrawer(false)}>
              <CgClose color="#222" />
            </IconButton>
          </GDMContainer>
          <GDMContainer
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <GDMTypography sx={{ fontSize: '16px', color: '#222', fontWeight: 700 }}>
              {getLabelTranslation('filters')}
            </GDMTypography>
            <GDMButton
              sx={{ fontSize: '12px', color: '#222', textDecoration: 'underline' }}
              onClick={clearFilter}
            >
              {getLabelTranslation('clear_all_filters')}
            </GDMButton>
          </GDMContainer>
          <GDMContainer
            sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginBottom: '16px' }}
          >
            {filtersApplied.length > 0 &&
              filtersApplied.map(item => (
                <Chip
                  label={getLabelTranslation(item.name)}
                  onDelete={() => handleCheck({ obj: item, type: item.type })}
                  deleteIcon={<CgClose color="#222" size={12} />}
                  variant="outlined"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16.8px',
                    background: theme.palette.primary.light,
                    color: '#222',
                    border: 'none',
                    padding: '8px 4px',
                  }}
                />
              ))}
          </GDMContainer>
          <GDMContainer
            sx={{
              overflowY: 'auto',
              height: '55vh',
              '&::-webkit-scrollbar': {
                width: '8px',
                background: '#fafafa',
                border: '1px solid #cbcdd9',
                borderRadius: '24px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#cbcdd9',
                borderRadius: '24px',
              },
            }}
          >
            {filters !== null && (
              <GDMContainer sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMTypography sx={{ fontSize: '16px', color: '#222', fontWeight: 700 }}>
                  {getLabelTranslation('by_field')}
                </GDMTypography>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<IoIosArrowDown />} aria-controls="test" id="test">
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('brand')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {filters.brand.map(b => (
                      <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          checked={filtersApplied.some(
                            item => JSON.stringify(item.id) === JSON.stringify(b.id),
                          )}
                          onClick={() => handleCheck({ obj: b, type: 'brand' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{b.name}</GDMTypography>
                      </GDMContainer>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<IoIosArrowDown />} aria-controls="test" id="test">
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('culture')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {filters.culture.map(c => (
                      <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          checked={filtersApplied.some(
                            item => JSON.stringify(item.id) === JSON.stringify(c.id),
                          )}
                          onClick={() => handleCheck({ obj: c, type: 'culture' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>
                          {getLabelTranslation(c.name)}
                        </GDMTypography>
                      </GDMContainer>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<IoIosArrowDown />} aria-controls="test" id="test">
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('technology')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    {filters.technology.map(t => (
                      <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          checked={filtersApplied.some(item => item.id === t)}
                          onClick={() =>
                            handleCheck({ obj: { id: t, name: t }, type: 'technology' })
                          }
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{t}</GDMTypography>
                      </GDMContainer>
                    ))}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    boxShadow: 'none',
                    '&::before': {
                      content: 'none',
                    },
                  }}
                >
                  <AccordionSummary expandIcon={<IoIosArrowDown />} aria-controls="test" id="test">
                    <GDMTypography sx={{ fontSize: '14px', color: '#222' }}>
                      {getLabelTranslation('variety')}
                    </GDMTypography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <GDMTextField
                      placeholder={getLabelTranslation('search_desired_variety')}
                      value={searchInputs.genotypeInput}
                      onChange={({ target }) => {
                        handleFilterSearch({ query: target.value });
                      }}
                      InputProps={
                        searchInputs.genotypeInput
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => handleFilterSearch({ query: '' })}>
                                    <IoClose />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IoSearchOutline />
                                </InputAdornment>
                              ),
                            }
                      }
                      sx={{ flex: 1 }}
                    />
                    {searchFilters.genotypes.map(g => (
                      <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMInputCheckbox
                          checked={filtersApplied.some(
                            item => JSON.stringify(item.id) === JSON.stringify(g.id),
                          )}
                          onClick={() => handleCheck({ obj: g, type: 'genotype' })}
                        />
                        <GDMTypography sx={{ fontSize: '14px' }}>{g.name}</GDMTypography>
                      </GDMContainer>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </GDMContainer>
            )}
          </GDMContainer>
        </GDMContainer>

        <GDMButton
          disabled={filtersApplied.length === 0}
          onClick={handleApplyFilters}
          sx={{
            marginTop: '24px',
            background: filtersApplied.length === 0 ? '#A1A9B8' : theme.palette.primary.main,
            color: '#fafafa',
            '&:hover': { background: '#0A3C56' },
            justifySelf: 'end',
            '&.Mui-disabled': {
              color: '#fafafa',
            },
          }}
        >
          {getLabelTranslation('apply')}
        </GDMButton>
      </Drawer>
    </>
  );
};

export default CatalogFilter;
