/* eslint-disable no-console */
import Api from 'core/api/clients/api/Api';

const getMultipliers = async ({
  currentPage,
  itemsPerPage,
  search = '',
  searchColumn = null,
  orderColumn = null,
  orderDesc = false,
}): Promise<any> => {
  let url = `/Multipliers?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}`;

  if (orderDesc) {
    url += `&orderDesc=${orderDesc}`;
  }
  if (orderColumn) {
    url += `&orderColum=${orderColumn}`;
  }
  if (searchColumn && searchColumn !== 'none' && searchColumn !== 'all') {
    url += `&SearchColumn=${searchColumn}`;
  }

  return Api.get({
    url,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const upSertMultiplier = async (method, body: any, id = ''): Promise<any> => {
  return Api[method]({
    url: `/Multipliers${id ? `/${id}` : ''}`,
    body,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};
const deleteMultiplierById = async (id: string): Promise<any> => {
  return Api.delete({
    url: `/Multipliers/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};

const getMultiById = async (id: string | number): Promise<any> => {
  return Api.get({
    url: `/Multipliers/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      console.error(err);
      throw err;
    });
};
const getMultipliersStatistcsById = async (id: string): Promise<any> => {
  return Api.get({
    url: `/Multipliers/GetMultiplierStatistics/${id}`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

const getMultiplierAccountById = async ({
  id,
  page,
  orderDesc,
  orderColum,
}: {
  id: string;
  orderDesc: boolean;
  orderColum: string;
  page: number;
}): Promise<any> => {
  return Api.get({
    url: `/Multipliers/accounts/multiplier/${id}?Page=${page}&ItemsPerPage=4&OrderDesc=${orderDesc}${
      orderColum && `&OrderColum=${orderColum}`
    }`,
  })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

export const MultiplierService = {
  getMultipliers,
  getMultiById,
  upSertMultiplier,
  deleteMultiplierById,
  getMultipliersStatistcsById,
  getMultiplierAccountById,
};
