import { GDMAutocomplete } from 'components/gdm-autocomplete';
import { GDMTextField } from 'components/gdm-text-field';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { autoCompleteInputStyles, autoCompleteStyles } from './DynamicFormAutoComplete.styles';
import { DynamicFormAutoCompleteComponent } from './DynamicFormAutoComplete.type';

const DynamicFormAutoComplete: DynamicFormAutoCompleteComponent = ({
  id,
  name,
  required,
  placeholder,
  disabled,
  value,
  defaultValue,
  onChange,
  onBlur,
  error,
  helperText,
  options,
  getOptionKey,
  getOptionLabel,
  inputValue,
  onChangeInput,
  filterSelectedOptions,
  multiple,
  readOnly,
  noOptionsText = 'auto_complete_field_no_option_message',
}) => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();

  const [hasError, setHasError] = useState(!!error);

  const [textProps, setTextProps] = useState({
    placeholder: getLabelTranslation(placeholder),
    helperText: getLabelTranslation(helperText as string),
    noOptionsText: getLabelTranslation(noOptionsText),
  });

  useEffect(() => {
    setTextProps({
      placeholder: getLabelTranslation(placeholder),
      helperText: getLabelTranslation(helperText as string),
      noOptionsText: getLabelTranslation(noOptionsText),
    });
  }, [currentLanguage, placeholder, helperText, noOptionsText]);

  useEffect(() => {
    setHasError(!!error);
  }, [error]);

  const hasValue = !!value?.length;
  const isDisabled = disabled || readOnly;
  const showPlaceholder = !isDisabled || !hasValue;
  return (
    <GDMAutocomplete
      {...{
        options,
        disabled,
        defaultValue,
        value,
        inputValue,
        onChange,
        onBlur,
        filterSelectedOptions,
        multiple,
        readOnly,
        noOptionsText: textProps.noOptionsText,
        ...(getOptionLabel ? { getOptionLabel } : {}),
        ...(getOptionKey ? { getOptionKey } : {}),
      }}
      sx={autoCompleteStyles}
      renderInput={params => (
        <GDMTextField
          {...{
            id,
            name,
            required,
            helperText: textProps.helperText,
            error: hasError,
            ...(showPlaceholder ? { placeholder: textProps.placeholder } : {}),
            ...(onChangeInput ? { onChange: onChangeInput } : {}),
            ...(inputValue ? { value: inputValue } : {}),
            ...params,
          }}
          sx={autoCompleteInputStyles}
          focused={false}
        />
      )}
      popupIcon={<IoIosArrowDown />}
      fullWidth
    />
  );
};

export default DynamicFormAutoComplete;
