import { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { BrandsServices } from 'core/api/services/brands';
import { CulturesServices } from 'core/api/services/cultures';
import { FarmService } from 'core/api/services/farm';
import { FarmerService } from 'core/api/services/farmer';
import { LotsService } from 'core/api/services/lots';
import { RecommendationService } from 'core/api/services/recommendation';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { UserRole } from 'core/typing/domains/UserProfileDomain/UserProfileDomain';
import { useStore } from 'effector-react';

import { Autocomplete, Avatar, Skeleton, Typography, useMediaQuery } from '@mui/material';

import {
  GDMButton,
  GDMContainer,
  GDMCustomInputLabel,
  GDMDivider,
  GDMFormHelperText,
  GDMGrid,
  GDMMenuItem,
  GDMSecureImage,
  GDMSelect,
  GDMTextField,
  GDMTypography,
} from 'components';

import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import useModal from 'hooks/useModal/useModal';

import { useRecommendationContext } from 'core/store/contexts/recommendationContext';
import { IFarmerData, IPageFormData, IRecommendationForm } from './RecommendationPageForm.type';

export default function RecommendationPageForm({
  setFormLoaded,
  isFormLoaded,
  setFieldErrors,
  fieldErrors,
  recommendation,
  setRecommendation,
  setIsLoading,
  isReadOnly = false,
  isPageReloading = false,
}: IRecommendationForm) {
  const { theme } = useThemeSwitcher();
  const { setRecommendationContext, setEditedFields, setVarietyValue } = useRecommendationContext();
  const { user } = useStore(UserProfileStore);
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [brandList, setBrandList] = useState([]);
  const [cultureList, setCultureList] = useState([]);
  const [farmerList, setFarmerList] = useState([]);
  const [currentFarmer, setCurrentFarmer] = useState<IFarmerData | null>(null);
  const [currentBrand, setCurrentBrand] = useState('');
  const [inputFarmerValue, setInputFarmerValue] = useState('');
  const [farmList, setFarmList] = useState([]);
  const [currentFarm, setCurrentFarm] = useState('');
  const [currentCulture, setCurrentCulture] = useState('');
  const [lotList, setLotList] = useState([]);
  const [currentLot, setCurrentLot] = useState('');
  const [disableInputs, setDisableInputs] = useState({ farmer: true, farm: true, lots: true });
  const [formData, setFormData] = useState<IPageFormData>({
    brandId: '',
    cultureId: '',
    lotId: '',
  });

  const cssResetTextfield = {
    input: {
      background: 'initial',
      border: 'none',
    },
  };

  useEffect(() => {
    if (isReadOnly && recommendation) {
      fillRecommendationData();
    }
  }, [recommendation]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isReadOnly) setIsLoading(true);
        const [brands, cultures, farmers] = await Promise.all([
          BrandsServices.getBrands(),
          CulturesServices.getCultures(),
          FarmerService.getFarmers(),
        ]);

        const brandsToUse = user.role === UserRole.ADMINISTRATOR ? brands : user.brands;
        setBrandList(brandsToUse);

        if (brandsToUse.length === 1) {
          setCurrentBrand(brandsToUse[0].id);
        }

        setCultureList(cultures);
        setFarmerList(
          farmers.map((f: { id: string; ownerName: string; ownerRole: string; name: string }) => ({
            id: f.id,
            label: `[${f.ownerName} - ${f.ownerRole}] ${f.name}`,
          })),
        );

        if (!isReadOnly) {
          if (farmers.length === 0) {
            setFieldErrors({ ...fieldErrors, farmer: true });
          } else {
            setFieldErrors({ ...fieldErrors, farmer: false });
          }

          setDisableInputs({
            ...disableInputs,
            farmer: farmers.length === 0,
          });
        }
        if (!isReadOnly) setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        openModal({
          type: 'error',
          title: getLabelTranslation('recommendation_pageform_error_title'),
          message: getLabelTranslation('recommendation_pageform_error_info'),
          primaryAction: {
            label: getLabelTranslation('OK'),
            action: () => {
              closeModal();
            },
          },
        });
      }
    };
    if (!isFormLoaded) fetchData();
  }, [user]);

  const fillRecommendationData = async () => {
    setIsLoading(true);
    setBrandList([{ id: recommendation?.brandId, name: recommendation?.brandName }]);
    setCultureList([{ id: recommendation?.cultureId, name: recommendation?.cultureName }]);
    setFarmerList([{ id: recommendation?.farmerId, name: recommendation?.farmerName }]);
    setFarmList([{ id: recommendation?.farmId, name: recommendation?.farmName }]);
    setLotList([{ id: recommendation?.lotId, name: recommendation?.lotName }]);

    setCurrentBrand(recommendation?.brandId);
    setCurrentCulture(recommendation?.cultureId);
    setCurrentFarmer({ id: recommendation?.farmerId, label: recommendation?.farmerName });
    setCurrentFarm(recommendation?.farmId);
    setCurrentLot(recommendation?.lotId);
    if (isReadOnly) setIsLoading(false);
  };

  useEffect(() => {
    setFormData({ brandId: currentBrand, cultureId: currentCulture, lotId: currentLot });
    setIsSubmitDisabled(!currentBrand || !currentCulture || !currentLot);
  }, [currentBrand, currentCulture, currentLot]);

  const getFarms = async selectedFarmer => {
    if (selectedFarmer && selectedFarmer?.id) {
      if (!isReadOnly) setIsLoading(true);
      try {
        const farms = await FarmService.getFarmListByFarmer(selectedFarmer?.id);

        if (!isReadOnly) {
          if (farms.length === 0) {
            setFieldErrors({ ...fieldErrors, farm: true });
          } else {
            setFieldErrors({ ...fieldErrors, farm: false });
          }

          setDisableInputs({
            ...disableInputs,
            farm: farms.length === 0,
          });
        }

        setFarmList(farms);
        if (!isReadOnly) setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  const getLots = async selectedFarm => {
    if (selectedFarm) {
      try {
        if (!isReadOnly) setIsLoading(true);
        const lots = await LotsService.getLotsListByFarm(selectedFarm);

        setCurrentLot(null);

        if (!isReadOnly) {
          if (lots.length === 0) {
            setFieldErrors({ ...fieldErrors, lot: true });
          } else {
            setFieldErrors({ ...fieldErrors, lot: false });
          }

          setDisableInputs({
            ...disableInputs,
            lots: lots.length === 0,
          });
        }

        setLotList(lots);
        if (!isReadOnly) setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  const handleChangeFarmer = async (_event, selectedValues) => {
    if (selectedValues) {
      setCurrentFarmer(selectedValues);
      await getFarms(selectedValues);
    } else {
      setCurrentFarmer(null);
      setCurrentFarm(null);
      setCurrentLot(null);
      setDisableInputs({
        ...disableInputs,
        farm: true,
        lots: true,
      });
      setFieldErrors({ ...fieldErrors, farm: false, lot: false });
    }
  };

  const handleChangeFarms = async (_event, selectedValues) => {
    if (selectedValues.props) {
      setCurrentFarm(selectedValues.props.value);
      await getLots(selectedValues.props.value);
    }
  };

  const handleFarmerInput = (_, val) => {
    setInputFarmerValue(val);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      setIsSubmitDisabled(true);
      const data = await RecommendationService.upSertRecommendation(formData);
      setRecommendation(data);
      setVarietyValue(data.recommendationVariety[0]?.id);
      setFormLoaded(true);
      setIsLoading(false);
      setRecommendationContext(data);
      setEditedFields({});
      sessionStorage.setItem('recommendationId', data?.id);
      sessionStorage.setItem('variety', data.recommendationVariety[0]?.id);
      navigate(`/recommendation/to-save`);
    } catch (error) {
      setIsLoading(false);
      setIsSubmitDisabled(false);
      openModal({
        type: 'error',
        title: getLabelTranslation('recommendation_pageform_submit_error_title'),
        message: getLabelTranslation('recommendation_pageform_submit_error_info'),
        primaryAction: {
          label: getLabelTranslation('OK'),
          action: () => {
            closeModal();
          },
        },
      });
    }
  };

  useEffect(() => {
    if (isReadOnly) setIsLoading(false);
  }, []);

  return (
    <GDMContainer
      sx={{
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: isFormLoaded && isDesktop ? 'row' : 'column',
        padding: isFormLoaded
          ? '0'
          : {
              xs: '0 6px',
              sm: '0 8px',
              md: '0 44px',
              lg: '0 54px',
              xl: '0 64px',
            },
        marginBottom: '32px',
        position: 'relative',
      }}
    >
      <GDMDivider
        sx={{
          my: {
            xs: '8px',
            sm: '12px',
            md: '16px',
            lg: '20px',
            xl: '24px',
          },
        }}
      />

      <GDMContainer
        component="form"
        onSubmit={onSubmit}
        sx={{
          gap: 1,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          width: isFormLoaded && isDesktop ? '50%' : '100%',
          justifyContent: 'flex-start',
        }}
      >
        {isFormLoaded && (
          <GDMTypography
            fontWeight={700}
            color="#222222"
            mb={isDesktop ? 1 : 0}
            mt={isDesktop ? 0 : 2}
            sx={{
              textAlign: isDesktop ? 'left' : 'center',
              fontSize: {
                xs: '20px',
                sm: '20px',
                md: '24px',
                lg: '24px',
                xl: '24px',
              },
            }}
          >
            {getLabelTranslation('recommendation_pageform_selected_data_label')}
          </GDMTypography>
        )}
        <GDMGrid
          container
          xs={isFormLoaded ? 12 : isDesktop ? 6 : 12}
          gap={1}
          flexWrap={isDesktop ? 'nowrap' : 'wrap'}
        >
          <GDMGrid item mt={1} xs={12} md={6}>
            <GDMCustomInputLabel
              label={getLabelTranslation('recommendation_pageform_brands_label')}
              required={!isFormLoaded}
              fontSize="16px"
              color="#222222"
            />
            {isPageReloading && (
              <>
                <Skeleton width="100%" height={56} variant="rounded" />
              </>
            )}
            {!isPageReloading && (
              <GDMSelect
                sx={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '19px',
                  background: '#fff',
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                  },
                  '& svg': isFormLoaded && {
                    display: 'none',
                  },
                }}
                IconComponent={IoIosArrowDown}
                placeholder={getLabelTranslation('recommendation_pageform_brands_label')}
                onChange={event => {
                  if (event.target.value) {
                    setCurrentBrand(event.target.value.toString());
                  }
                }}
                value={currentBrand}
                variant="outlined"
                fullWidth
                disabled={isFormLoaded || brandList.length === 1}
              >
                <GDMMenuItem value="" style={{ display: 'none' }} selected disabled>
                  {getLabelTranslation('recommendation_pageform_brands_label')}
                </GDMMenuItem>
                {brandList.map(brand => (
                  <GDMMenuItem key={brand.id} value={brand.id}>
                    {brand.name}
                  </GDMMenuItem>
                ))}
              </GDMSelect>
            )}
          </GDMGrid>
          <GDMGrid item mt={1} xs={12} md={6}>
            <GDMCustomInputLabel
              label={getLabelTranslation('recommendation_pageform_culture_label')}
              required={!isFormLoaded}
              fontSize="16px"
              color="#222222"
            />
            {isPageReloading && (
              <>
                <Skeleton width="100%" height={56} variant="rounded" />
              </>
            )}
            {!isPageReloading && (
              <GDMSelect
                sx={{
                  background: '#fff',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19px',
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                  },
                  '& svg': isFormLoaded && {
                    display: 'none',
                  },
                }}
                IconComponent={IoIosArrowDown}
                placeholder={getLabelTranslation('recommendation_pageform_culture_label')}
                onChange={event => {
                  if (event.target.value) {
                    setCurrentCulture(event.target.value.toString());
                  }
                }}
                variant="outlined"
                fullWidth
                value={currentCulture}
                disabled={isFormLoaded}
              >
                <GDMMenuItem value="" style={{ display: 'none' }} selected disabled>
                  {getLabelTranslation('recommendation_pageform_culture_label')}
                </GDMMenuItem>
                {cultureList.map(culture => (
                  <GDMMenuItem key={culture.id} value={culture.id}>
                    {culture.name}
                  </GDMMenuItem>
                ))}
              </GDMSelect>
            )}
          </GDMGrid>
        </GDMGrid>
        {isFormLoaded && (
          <GDMGrid item mt={1} xs={isFormLoaded ? 12 : isDesktop ? 6 : 12}>
            <GDMCustomInputLabel
              label={getLabelTranslation('recommendation_pageform_farmer_label')}
              required={!isFormLoaded}
              fontSize="16px"
              color="#222222"
              labelId="recommendation_farmer_label"
            />
            {isPageReloading && (
              <>
                <Skeleton width="100%" height={56} variant="rounded" />
              </>
            )}
            {!isPageReloading && (
              <Autocomplete
                popupIcon={<IoIosArrowDown />}
                id="farmer-input"
                sx={{
                  background: '#fff',
                  input: {
                    background: '#fff',
                    border: 'none',
                  },
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                  },
                  '& svg': isFormLoaded && {
                    display: 'none',
                  },
                }}
                options={farmerList}
                value={currentFarmer}
                onChange={handleChangeFarmer}
                inputValue={inputFarmerValue}
                onInputChange={handleFarmerInput}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionKey={option => `farmer-select-${option.id}`}
                disabled={disableInputs.farmer || isFormLoaded}
                renderInput={params => (
                  <GDMTextField
                    {...params}
                    focused={false}
                    sx={cssResetTextfield}
                    placeholder={getLabelTranslation('recommendation_pageform_farmer_placeholder')}
                    variant="outlined"
                  />
                )}
              />
            )}
            {fieldErrors?.farmer && (
              <>
                <GDMFormHelperText sx={{ color: '#777D88', fontSize: '14px', lineHeight: '16px' }}>
                  {getLabelTranslation('recommendation_info_data_not_found')}
                </GDMFormHelperText>
                <GDMButton
                  variant="text"
                  onClick={() => navigate(`/farmer-register`)}
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#0063F7',
                    height: 'auto',
                    padding: 0,
                    textDecoration: 'underline',
                    ':hover': {
                      background: 'transparent',
                      textDecoration: 'underline',
                    }
                  }}
                >
                  {getLabelTranslation('recommendation_info_register_farmer')}
                </GDMButton>
              </>
            )}
          </GDMGrid>
        )}
        <GDMGrid container xs={12} gap={1} flexWrap={isDesktop ? 'nowrap' : 'wrap'}>
          {!isFormLoaded && (
            <GDMGrid item mt={1} xs={isFormLoaded ? 12 : isDesktop ? 6 : 12}>
              <GDMCustomInputLabel
                label={getLabelTranslation('recommendation_pageform_farmer_label')}
                required={!isFormLoaded}
                fontSize="16px"
                color="#222222"
                labelId="recommendation_farmer_label"
              />
              {isPageReloading && (
                <>
                  <Skeleton width="100%" height={56} variant="rounded" />
                </>
              )}
              {!isPageReloading && (
                <Autocomplete
                  popupIcon={<IoIosArrowDown />}
                  id="farmer-input"
                  sx={{
                    background: '#fff',
                    input: {
                      background: '#fff',
                      border: 'none',
                    },
                    '.Mui-disabled': {
                      background: '#F2F4F9',
                    },
                    '& svg': isFormLoaded && {
                      display: 'none',
                    },
                  }}
                  options={farmerList}
                  value={currentFarmer}
                  onChange={handleChangeFarmer}
                  inputValue={inputFarmerValue}
                  onInputChange={handleFarmerInput}
                  getOptionLabel={option => option.label}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionKey={option => `farmer-select-${option.id}`}
                  disabled={disableInputs.farmer || isFormLoaded}
                  renderInput={params => (
                    <GDMTextField
                      {...params}
                      focused={false}
                      sx={cssResetTextfield}
                      placeholder={getLabelTranslation(
                        'recommendation_pageform_farmer_placeholder',
                      )}
                      variant="outlined"
                    />
                  )}
                />
              )}
              {fieldErrors?.farmer && (
                <>
                  <GDMFormHelperText
                    sx={{ color: '#777D88', fontSize: '14px', lineHeight: '16px' }}
                  >
                    {getLabelTranslation('recommendation_info_data_not_found')}
                  </GDMFormHelperText>
                  <GDMButton
                    variant="text"
                    onClick={() => navigate(`/farmer-register`)}
                    sx={{
                      fontSize: '14px',
                      lineHeight: '19px',
                      color: '#0063F7',
                      height: 'auto',
                      padding: 0,
                      textDecoration: 'underline',
                      ':hover': {
                        background: 'transparent',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {getLabelTranslation('recommendation_info_register_farmer')}
                  </GDMButton>
                </>
              )}
            </GDMGrid>
          )}
          <GDMGrid item mt={1} xs={isDesktop ? (isFormLoaded ? 6 : 3) : 12}>
            <GDMCustomInputLabel
              label={getLabelTranslation('recommendation_pageform_farm_label')}
              required={!isFormLoaded}
              fontSize="16px"
              color="#222222"
            />
            {isPageReloading && (
              <>
                <Skeleton width="100%" height={56} variant="rounded" />
              </>
            )}
            {!isPageReloading && (
              <GDMSelect
                sx={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  backgroundColor: '#fff',
                  lineHeight: '19px',
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                  },
                  '& svg': isFormLoaded && {
                    display: 'none',
                  },
                }}
                IconComponent={IoIosArrowDown}
                placeholder={getLabelTranslation('recommendation_pageform_farm_label')}
                onChange={handleChangeFarms}
                value={currentFarm || null}
                variant="outlined"
                fullWidth
                disabled={disableInputs.farm || isFormLoaded}
              >
                <GDMMenuItem value="" style={{ display: 'none' }} selected disabled>
                  {getLabelTranslation('recommendation_pageform_farm_label')}
                </GDMMenuItem>
                {farmList?.map(frm => (
                  <GDMMenuItem key={frm.id} value={frm.id}>
                    {frm.name}
                  </GDMMenuItem>
                ))}
              </GDMSelect>
            )}
            {fieldErrors?.farm && (
              <>
                <GDMFormHelperText sx={{ color: '#777D88', fontSize: '14px', lineHeight: '16px' }}>
                  {getLabelTranslation('recommendation_pageform_data_not_found')}
                </GDMFormHelperText>
                <GDMButton
                  variant="text"
                  onClick={() => navigate(`/farmer-register/${currentFarmer.id}/farm-step`)}
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#0063F7',
                    height: 'auto',
                    padding: 0,
                    textDecoration: 'underline',
                    ':hover': {
                      background: 'transparent',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {getLabelTranslation('recommendation_pageform_info_register_farm')}
                </GDMButton>
              </>
            )}
          </GDMGrid>
          <GDMGrid item mt={1} xs={isDesktop ? (isFormLoaded ? 6 : 3) : 12}>
            <GDMCustomInputLabel
              label={getLabelTranslation('recommendation_pageform_lot_label')}
              required={!isFormLoaded}
              fontSize="16px"
              color="#222222"
            />
            {isPageReloading && (
              <>
                <Skeleton width="100%" height={56} variant="rounded" />
              </>
            )}
            {!isPageReloading && (
              <GDMSelect
                sx={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '19px',
                  background: '#fff',
                  '.Mui-disabled': {
                    background: '#F2F4F9',
                  },
                  '& svg': isFormLoaded && {
                    display: 'none',
                  },
                }}
                IconComponent={IoIosArrowDown}
                placeholder={getLabelTranslation('recommendation_pageform_lot_placeholder')}
                onChange={event => {
                  if (event.target.value) {
                    setCurrentLot(event.target.value.toString());
                  }
                }}
                value={currentLot || null}
                variant="outlined"
                disabled={disableInputs.lots || isFormLoaded}
                fullWidth
              >
                <GDMMenuItem value="" style={{ display: 'none' }} selected disabled>
                  {getLabelTranslation('recommendation_pageform_lot_label')}
                </GDMMenuItem>
                {lotList?.map(lt => (
                  <GDMMenuItem key={lt.id} value={lt.id}>
                    {lt.name}
                  </GDMMenuItem>
                ))}
              </GDMSelect>
            )}
            {fieldErrors?.lot && (
              <>
                <GDMFormHelperText sx={{ color: '#777D88', fontSize: '14px', lineHeight: '16px' }}>
                  {getLabelTranslation('recommendation_pageform_data_not_found')}
                </GDMFormHelperText>
                <GDMButton
                  variant="text"
                  onClick={() =>
                    currentFarm && navigate(`/farmer-register/${currentFarm}/lot-step`)
                  }
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19px',
                    color: '#0063F7',
                    height: 'auto',
                    padding: 0,
                    textDecoration: 'underline',
                    ':hover': {
                      background: 'transparent',
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {getLabelTranslation('recommendation_pageform_info_register_lot')}
                </GDMButton>
              </>
            )}
          </GDMGrid>
        </GDMGrid>
        <GDMContainer
          mt={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <GDMButton
            sx={{
              height: '51px',
              width: isDesktop ? '220px' : '100%',
              fontSize: '14px',
              fontWeight: 700,
              display: isFormLoaded ? 'none' : 'block',
              '&:disabled': {
                color: "white",
                background: theme.palette.secondary.main
              }
            }}
            color="primary"
            variant="contained"
            fullWidth={false}
            type="submit"
            disabled={isSubmitDisabled}
          >
            {getLabelTranslation('recommendation_pageform_send')}
          </GDMButton>
        </GDMContainer>
      </GDMContainer>
      <GDMContainer
        sx={{
          display: isFormLoaded ? 'flex' : 'none',
          flexDirection: 'column',
          height: '100%',
          width: isDesktop ? '50%' : '100%',
          paddingLeft: isDesktop && '24px',
        }}
      >
        <GDMTypography
          variant="h6"
          fontWeight={700}
          component="div"
          color="#222222"
          fontSize={isDesktop ? '24px' : '20px'}
          textAlign={isDesktop ? 'left' : 'center'}
        >
          {getLabelTranslation('recommendation_lot_map_label')}
        </GDMTypography>
        {isPageReloading && (
          <Skeleton
            sx={{
              marginTop: '28px',
              borderRadius: '24px',
            }}
            width="100%"
            height={302}
            variant="rounded"
          />
        )}
        {!isPageReloading && (
          <GDMSecureImage
            useAvatar
            src={recommendation?.lotImageUrl}
            alt="lot map image"
            sx={{
              marginTop: '28px',
              width: '100%',
              height: 'auto',
              borderRadius: '24px',
              maxHeight: '320px',
            }}
          />
        )}
      </GDMContainer>
      {!isFormLoaded && isDesktop && (
        <Typography
          sx={{
            fontSize: '14px',
            color: '#777D88',
            marginTop: '32px',
          }}
        >
          {getLabelTranslation('recommendation_page_disclaimer')}
        </Typography>
      )}
    </GDMContainer>
  );
}
