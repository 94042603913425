/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { Language } from 'core/store/contexts/languageContext.type';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  List,
  ListItem,
  ListItemButton,
  Popover,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { GDMContainer, GDMFormControl, GDMMenuItem, GDMSelect, GDMTypography } from 'components';

import FlagArgentina from 'assets/images/argentina-flag.png';
import FlagBrasil from 'assets/images/brasil-flag.png';

import LoadChangeLanguageUseCase from 'core/api/useCases/UserProfileUseCase/LoadChangeLanguageUseCase';
import { GDMLanguageSwitcherComponent } from './GDMLanguageSwitcher.type';

const languages = [
  { languageIso: 'pt-BR', countryFlag: FlagBrasil, label: 'Bandeira do Brasil' },
  { languageIso: 'es-AR', countryFlag: FlagArgentina, label: 'Bandeira da Argentina' },
];

const GDMLanguageSwitcher: GDMLanguageSwitcherComponent = () => {
  const { currentLanguage, switchLanguage, getLabelTranslation } = useLanguageSwitcher();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [avatarEl, setAvatarEl] = useState<HTMLDivElement | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  useEffect(() => {
    switchLanguage(currentLanguage);
    setSelectedLanguage(currentLanguage);
  }, [currentLanguage]);

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const open = Boolean(avatarEl);

  const onClose = () => {
    setAvatarEl(null);
  };

  const handleSelectLanguage = async event => {
    switchLanguage(event.target.value);
    setSelectedLanguage(event.target.value as Language);

    const languageId = () => {
      if (event.target.value === 'pt-BR') {
        return '56d656e4-42e4-41c0-8b69-7f3eb0a894ab';
      }

      return '5ca0663b-026f-48c7-b83d-a88b61a77eb6';
    };

    LoadChangeLanguageUseCase.execute(languageId());
    onClose();
  };

  return (
    <GDMContainer display="flex" alignItems="center">
      {isDesktop ? (
        <GDMFormControl variant="outlined">
          <GDMSelect
            labelId="language-switcher-label"
            id="language-switcher"
            value={selectedLanguage}
            IconComponent={ExpandMoreIcon}
            onChange={handleSelectLanguage}
            sx={{
              width: '154px',
              height: '48px',
              '.MuiSvgIcon-root ': {
                fill: '#222',
              },
            }}
          >
            {languages.map(lang => (
              <GDMMenuItem key={lang.languageIso} value={lang.languageIso}>
                <GDMContainer display="flex" alignItems="center">
                  <img
                    src={lang.countryFlag}
                    alt={lang.label}
                    style={{ marginRight: 8, width: 24 }}
                  />
                  <GDMTypography sx={{ fontSize: '14px', fontWeight: 400, color: '#222' }}>
                    {lang.languageIso}
                  </GDMTypography>
                </GDMContainer>
              </GDMMenuItem>
            ))}
          </GDMSelect>
        </GDMFormControl>
      ) : (
        <>
          <GDMContainer
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              color: '#777D88',
              mt: 0.5,
            }}
            onClick={e => setAvatarEl(e.currentTarget)}
          >
            <GDMTypography fontSize="14px" fontWeight={400}>
              {getLabelTranslation('header_language')}
            </GDMTypography>
            <KeyboardArrowDown />
          </GDMContainer>
          <Popover
            open={open}
            anchorEl={avatarEl}
            onClose={handleAvatarClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <List disablePadding>
              {languages.map(option => {
                return (
                  <ListItem disablePadding key={option.languageIso}>
                    <ListItemButton
                      disableRipple
                      onClick={() =>
                        handleSelectLanguage({ target: { value: option.languageIso } })
                      }
                      sx={{
                        gap: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '14px 24px 14px 18px',
                        ':hover': { backgroundColor: theme.palette.secondary.main },
                      }}
                    >
                      <img
                        src={option.countryFlag}
                        alt={option.label}
                        style={{ marginRight: 8, width: 24 }}
                      />
                      <GDMTypography sx={{ fontSize: '14px', fontWeight: 400, color: '#222' }}>
                        {option.languageIso}
                      </GDMTypography>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        </>
      )}
    </GDMContainer>
  );
};

export default GDMLanguageSwitcher;
