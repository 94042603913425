import { useMediaQuery, useTheme } from '@mui/material';
import { GDMContainer, GDMTypography } from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { CiCalendar } from 'react-icons/ci';
import { PiPlantThin } from 'react-icons/pi';

const GDMGridInfo = ({ id, name, value }: { id: string; name: string; value: string }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();

  let icon;
  if (id === 'tech') {
    icon = <PiPlantThin color="#777d88" size={24} />;
  } else {
    icon = <CiCalendar color="#777d88" size={24} />;
  }
  return (
    <GDMContainer
      sx={{
        display: 'flex',
        flexDirection: isDesktop ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {icon}
        <GDMTypography sx={{ fontWeight: 700 }}>
          {getLabelTranslation(`recommendation_params_field_${name}`)}
        </GDMTypography>
      </GDMContainer>
      <GDMTypography sx={{ fontSize: '16px', color: '#777d88' }}>{value}</GDMTypography>
    </GDMContainer>
  );
};

export default GDMGridInfo;
