import ThemeSwitcher from 'core/design-system/styles/ThemeSwitcher';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { useEffect } from 'react';

import { Menu } from '@mui/icons-material';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';

import {
  GDMContainer,
  GDMLanguageSwitcher,
  GDMNotifications,
  GDMTypography,
  GDMUserProfileHeader,
} from 'components';

import LogoGDM from '../../assets/images/LogoGDM.svg';
import LogoGDMWhite from '../../assets/images/LogoGDMwhite.svg';
import {
  actionsContainer,
  actionsContainerMobile,
  appBarStyle,
  appBarStyleMobile,
} from './GDMHeader.styles';
import {
  GDMHeaderComponent,
  GDMHeaderDesktopComponent,
  GDMHeaderMobileComponent,
} from './GDMHeader.type';

const GDMHeaderDesktop: GDMHeaderDesktopComponent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const { user } = useStore(UserProfileStore);

  return (
    <GDMContainer sx={appBarStyle}>
      <GDMContainer sx={actionsContainer}>
        <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
          {['administrador', 'administrator'].includes(user?.role?.toLowerCase()) ? (
            <img
              src={LogoGDM}
              alt="Logo GDM"
              style={{
                maxWidth: '120px',
                maxHeight: '120px',
                objectFit: 'cover',
              }}
            />
          ) : (
            <>
              <GDMTypography fontSize={16} color="#000" fontWeight={400}>
                {getLabelTranslation('header_brand')}
              </GDMTypography>
              <ThemeSwitcher />
            </>
          )}
        </GDMContainer>
        <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
          <GDMTypography fontSize={isDesktop ? 16 : 12} color="#000" fontWeight={400}>
            {getLabelTranslation('header_language')}
          </GDMTypography>
          <GDMLanguageSwitcher />
          <GDMNotifications />
          <GDMUserProfileHeader />
        </GDMContainer>
      </GDMContainer>
    </GDMContainer>
  );
};

const GDMHeaderMobile: GDMHeaderMobileComponent = ({ handleToggle }) => {
  const { user } = useStore(UserProfileStore);
  return (
    <GDMContainer>
      <GDMContainer sx={appBarStyleMobile}>
        <IconButton style={{ maxWidth: 64 }} onClick={handleToggle}>
          <Menu style={{ color: '#777D88' }} />
        </IconButton>
        <GDMContainer sx={actionsContainerMobile}>
          <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
            <GDMLanguageSwitcher />
            <GDMNotifications />
            <GDMUserProfileHeader />
          </GDMContainer>
        </GDMContainer>
      </GDMContainer>
      <GDMContainer sx={{ bgcolor: 'primary.main', height: '64px' }}>
        <GDMContainer
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {['administrador', 'administrator'].includes(user?.role?.toLowerCase()) ? (
            <img
              src={LogoGDMWhite}
              alt="Logo GDM"
              style={{
                maxWidth: '120px',
                maxHeight: '120px',
                objectFit: 'cover',
              }}
            />
          ) : (
            <ThemeSwitcher />
          )}
        </GDMContainer>
      </GDMContainer>
    </GDMContainer>
  );
};

const GDMHeader: GDMHeaderComponent = ({ handleToggle }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  if (isDesktop) return <GDMHeaderDesktop />;

  return <GDMHeaderMobile handleToggle={handleToggle} />;
};

export default GDMHeader;
