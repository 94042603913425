import { Fragment, useState } from 'react';
import { FaRegUser } from 'react-icons/fa6';
import { IoLogOutOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { logout } from 'authConfig';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GDMSecureImage } from 'components/gdm-secure-image';

import { GDMUserProfileHeaderComponent } from './GDMUserProfileHeader.type';

const GDMUserProfileHeader: GDMUserProfileHeaderComponent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const { user } = useStore(UserProfileStore);
  const [avatarEl, setAvatarEl] = useState<HTMLButtonElement | null>(null);

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const open = Boolean(avatarEl);

  const handleGoToProfile = () => {
    navigate('/profile');
    setAvatarEl(null);
  };

  const { getLabelTranslation } = useLanguageSwitcher();

  const handleLogout = () => {
    navigate('/');
    logout();
  };

  const userListOptions = [
    {
      text: getLabelTranslation('userProfileHeader_my_profile'),
      icon: <FaRegUser color="#777D88" />,
      action: handleGoToProfile,
    },
    {
      text: getLabelTranslation('userProfileHeader_logout'),
      icon: <IoLogOutOutline color="#777D88" />,
      action: handleLogout,
    },
  ];

  return (
    <Box>
      <Button disableRipple sx={{ ':hover': { background: 'none' } }} onClick={handleAvatarClick}>
        {isDesktop ? (
          <>
            <GDMSecureImage
              useAvatar
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: 50,
                height: 50,
                fontSize: '24px',
                fontWeight: 700,
                color: '#FAFAFA',
              }}
              src={user?.imageCdnUrl}
              key={user?.imageCdnUrl}
            >
              {user?.imageCdnUrl ? '' : user?.name?.charAt(0)}
            </GDMSecureImage>
            <KeyboardArrowDownIcon sx={{ color: '#222' }} />
          </>
        ) : (
          <FaRegUser color="#777D88" fontSize="18px" />
        )}
      </Button>
      <Popover
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List disablePadding>
          {userListOptions.map((option, index) => {
            const optionKey = `option-key-profile-${index}-[${option.text}]`;

            return (
              <Fragment key={optionKey}>
                <ListItem disablePadding sx={{ width: '200px' }}>
                  <ListItemButton
                    disableRipple
                    onClick={option.action}
                    sx={{
                      gap: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '14px 24px 14px 18px',
                      ':hover': { backgroundColor: theme.palette.secondary.main },
                    }}
                  >
                    {option.icon}
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, color: '#222' }}>
                      {option.text}
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ width: '160px', margin: '0 auto' }} />
              </Fragment>
            );
          })}
        </List>
      </Popover>
    </Box>
  );
};

export default GDMUserProfileHeader;
