import { GDMTextField } from 'components/gdm-text-field';
import { useLanguageContext } from 'core/store/contexts/languageContext';
import { useEffect, useState } from 'react';
import { dynamicFormTextFieldStyles } from './DynamicFormTextField.styles';
import { DynamicFormTextFieldComponent } from './DynamicFormTextField.type';

const DynamicFormTextField: DynamicFormTextFieldComponent = ({
  id,
  name,
  required,
  placeholder,
  disabled,
  defaultValue,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  const { getLabelTranslation, currentLanguage } = useLanguageContext();

  const [textProps, setTextProps] = useState({
    placeholder: getLabelTranslation(placeholder),
    helperText: getLabelTranslation(helperText as string),
  });

  useEffect(() => {
    setTextProps({
      placeholder: getLabelTranslation(placeholder),
      helperText: getLabelTranslation(helperText as string),
    });
  }, [currentLanguage, placeholder, helperText]);

  return (
    <GDMTextField
      {...{
        id,
        name,
        required,
        disabled,
        defaultValue,
        value,
        onChange,
        onBlur,
        error,
        ...textProps,
      }}
      focused={false}
      fullWidth
      variant="outlined"
      sx={dynamicFormTextFieldStyles}
    />
  );
};

export default DynamicFormTextField;
