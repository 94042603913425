import {
  Box,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GDMButton } from 'components/gdm-button';
import { GDMTypography } from 'components/gdm-typography';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { IoAdd } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import colors from '../../core/design-system/style-guide/colors.json';
import textStyles from '../../core/design-system/style-guide/typography.json';

const GDMTableDesktop = ({
  columns,
  rows,
  changePage,
  pages,
  currentPage,
  changeOrder,
  setOrderColumn = undefined,
  isFarmerRegister = false,
  emptyMessage,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const columnTitleName = [];
  const navigate = useNavigate();

  columns.forEach(column => {
    columnTitleName.push({ title: column.column_title, name: column.column_name });
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TableContainer component={Box}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: '#F2F4F9' }}>
              {columnTitleName.map((column, index) => {
                const key = `col-${index}-${column.name}`;

                return (
                  <TableCell
                    key={key}
                    sx={{
                      border: 'none',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: '#777D88',
                        }}
                      >
                        {column.title}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setOrderColumn(column.name);
                          changeOrder();
                        }}
                      >
                        <CgArrowsExchangeAltV />
                      </IconButton>
                    </Box>
                  </TableCell>
                );
              })}
              <TableCell
                sx={{
                  border: 'none',
                }}
              >
                <Typography variant="subtitle2" sx={{ color: '#777D88', fontWeight: '700' }}>
                  {getLabelTranslation('to_see')}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                }}
              >
                <Typography variant="subtitle2" sx={{ color: '#777D88', fontWeight: '700' }}>
                  {getLabelTranslation('to_edit')}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                }}
              >
                <Typography variant="subtitle2" sx={{ color: '#777D88', fontWeight: '700' }}>
                  {getLabelTranslation('delete')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>

      {!rows?.length && !!emptyMessage && (
        <GDMTypography
          sx={{
            textAlign: 'center',
            paddingTop: '32px',
            paddingBottom: '32px',
            color: '#222',
            width: '100%',
          }}
        >
          {getLabelTranslation(emptyMessage)}
        </GDMTypography>
      )}

      {rows.length > 0 && (
        <Box
          sx={{
            ...(isFarmerRegister && {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '193px',
              mt: 10,
            }),
          }}
        >
          <Pagination
            count={pages}
            page={currentPage}
            onChange={(_, page) => changePage(page)}
            variant="outlined"
            shape="rounded"
            boundaryCount={isDesktop ? 1 : 0}
            sx={{
              ...(!isFarmerRegister && {
                mt: 2,
              }),
              justifyContent: 'center',
              display: 'flex',
              '& ul > li > button': {
                backgroundColor: colors.light2,
                width: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                height: {
                  xs: '35px',
                  sm: '40px',
                  md: '45px',
                  lg: '51px',
                  xl: '51px',
                },
                border: 'none',
                ...textStyles.table.pagination,
              },
              '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
                backgroundColor: colors.dark3,
              },
            }}
          />
        </Box>
      )}

      {isFarmerRegister && rows.length > 0 && (
        <GDMButton
          onClick={() => {
            navigate(`/farmer-register`);
          }}
          sx={{
            mr: 4,
            height: '51px',
            width: isDesktop ? '200px' : '100%',
            color: theme.palette.secondary.dark,
            background: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            alignSelf: 'end',
            gap: '8px',
            marginRight: 0,
            ':hover': {
              background: theme.palette.secondary.light,
              boxShadow: 'none',
            },
          }}
          variant="outlined"
        >
          <IoAdd style={{ fontSize: '24px' }} color="#222" />
          {getLabelTranslation('add_farmer_information')}
        </GDMButton>
      )}
    </Box>
  );
};

export default GDMTableDesktop;
