import { SxProps } from '@mui/material';

export const dynamicFormTextFieldStyles: SxProps = {
  borderRadius: '6px',
  minHeight: '48px',
  boxSizing: 'border-box',
  '& ::placeholder': {
    color: '#A1A9B8',
    opacity: 1,
  },
  '& .MuiInputBase-root, & .MuiInputBase-root input': {
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: '6px',
    fontSize: '14px',
    lineHeight: '19.6px',
  },
  '& .Mui-disabled': {
    background: '#F2F4F9',
  },
  '& .MuiInputBase-root input': {
    height: '48px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A1A9B8',
  },
  '& .MuiFormHelperText-root': {
    background: 'transparent',
    marginTop: '0px',
    paddingTop: '4px',
    height: '24px',
  },
  '& .Mui-error': {
    color: '#FB2047',
  },
};
