import { GDMContainer } from 'components/gdm-container';
import { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { DynamicFormAutoComplete } from '../dynamic-form-auto-complete';
import { DynamicFormImageFileField } from '../dynamic-form-image-file-field';
import { DynamicFormLabel } from '../dynamic-form-label';
import { DynamicFormSelect } from '../dynamic-form-select';
import { DynamicFormTextField } from '../dynamic-form-text-field';
import { DynamicFormTextFieldMask } from '../dynamic-form-text-field-mask';
import { fieldControlStyles } from './DynamicFormFieldControl.styles';
import { DynamicFormFieldControlComponent } from './DynamicFormFieldControl.type';

const DynamicFormFieldControl: DynamicFormFieldControlComponent = ({
  required,
  id,
  name,
  label,
  disabled,
  error,
  helperText,
  onChange,
  onBlur,
  placeholder,
  value,
  type,
  options,
  mask,
  maskChar,
  getOptionKey,
  getOptionLabel,
  inputValue,
  onChangeInput,
  defaultValue,
  filterSelectedOptions,
  multiple,
  readOnly,
  noOptionsText,
}) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [fieldOptions, setFieldOptions] = useState(options || []);
  const [fieldInputValue, setFieldInputValue] = useState(inputValue);
  let Component;
  let props;

  useEffect(() => {
    setFieldInputValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setFieldOptions(options);
  }, [options]);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  switch (type) {
    case 'select':
      Component = DynamicFormSelect;
      props = { options: fieldOptions, placeholder };
      break;
    case 'text-field-mask':
      Component = DynamicFormTextFieldMask;
      props = { mask, maskChar };
      break;
    case 'auto-complete':
      Component = DynamicFormAutoComplete;
      props = {
        getOptionKey,
        getOptionLabel,
        filterSelectedOptions,
        multiple,
        readOnly,
        noOptionsText,
        options: fieldOptions,
        inputValue: fieldInputValue,
        ...(!disabled ? { onChangeInput } : { onChangeInput: undefined }),
      };
      break;
    case 'image-file':
      Component = DynamicFormImageFileField;
      props = {};
      break;
    default:
      Component = DynamicFormTextField;
      props = {};
      break;
  }

  return (
    <GDMContainer
      sx={{ ...fieldControlStyles, ...(type === 'image-file' ? { height: '100%' } : {}) }}
    >
      <DynamicFormLabel
        {...{
          id,
          label,
          required,
        }}
      />

      <Component
        {...{
          id,
          name,
          disabled,
          error,
          helperText,
          onBlur,
          placeholder,
          required,
          defaultValue,
          value: fieldValue,
          ...(!disabled ? { onChange } : { onChange: undefined }),
          ...props,
        }}
        IconComponent={IoIosArrowDown}
      />
    </GDMContainer>
  );
};

export default DynamicFormFieldControl;
