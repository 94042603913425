import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';
import { DynamicFormFieldControlProps } from 'components/gdm-dynamic-form-fields/components/dynamic-form-field-control/DynamicFormFieldControl.type';
import { GetCommonFormFieldPropsFunction } from './getCommonFormFieldProps.type';

const phoneMasks = {
  [CountriesDomainEnum.Argentina]: '+99 9 999 999-9999',
  [CountriesDomainEnum.Brasil]: '+99 99 99999-9999',
};

const getCommonFormFieldProps: GetCommonFormFieldPropsFunction = ({
  formData = {},
  errors = {},
  sellers = [],
  multipliers = [],
  countries = [],
  roles = [],
  states = [],
  cities = [],
  brands = [],
  regions = [],
  userCountryName = CountriesDomainEnum.Brasil,
}) => {
  const gdmSellerField: Partial<DynamicFormFieldControlProps> = {
    type: 'auto-complete',
    id: 'gdm-seller',
    name: 'gdm-seller',
    label: 'customRolesInputs_select_gdm_seller',
    placeholder: 'customRolesInputs_select_gdm_seller',
    options: sellers,
    getOptionLabel: option => option.name,
    getOptionKey: option => option.id,
    value: sellers.find(seller => seller.id === formData.sellerId) || null,
    error: !!errors?.sellerId,
    helperText: errors?.sellerId,
  };

  const multiplierField: Partial<DynamicFormFieldControlProps> = {
    type: 'auto-complete',
    id: 'multiplier',
    name: 'multiplier',
    label: 'customRolesInputs_select_multiplier',
    placeholder: 'customRolesInputs_select_multiplier',
    options: multipliers,
    getOptionLabel: option => option.name,
    getOptionKey: option => option.id,
    value: multipliers.find(multiplier => multiplier.id === formData.multiplierId) || null,
    error: !!errors?.multiplierId,
    helperText: errors?.multiplierId,
  };

  const nameField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field',
    id: 'name',
    name: 'name',
    label: 'formfields_full_name',
    placeholder: 'formfields_full_name',
    value: formData.name,
    error: !!errors?.name,
    helperText: errors?.name,
  };

  const emailField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field',
    id: 'contactEmail',
    name: 'contactEmail',
    label: 'formfields_email',
    placeholder: 'formfields_email',
    value: formData.contactEmail,
    error: !!errors?.contactEmail,
    helperText: errors?.contactEmail,
  };

  const countryField: Partial<DynamicFormFieldControlProps> = {
    type: 'select',
    id: 'country',
    name: 'country',
    label: 'formfields_country',
    placeholder: 'formfields_country',
    value: formData.countryId,
    options: countries.map(country => ({
      key: `country-${country.id}`,
      value: country.id,
      label: country.name,
    })),
    disabled: true,
  };

  const roleField: Partial<DynamicFormFieldControlProps> = {
    type: 'select',
    id: 'role-fixed',
    name: 'role-fixed',
    label: 'customRolesInputs_select_profile',
    placeholder: 'customRolesInputs_profile',
    value: formData.roleId,
    options: roles.map(role => ({
      key: `role-${role.id}`,
      value: role.id,
      label: role.name,
    })),
  };

  const phoneField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field-mask',
    id: 'phoneNumber',
    name: 'phoneNumber',
    label: 'formfields_phone',
    placeholder: 'formfields_phone',
    value: formData.phoneNumber,
    error: !!errors.phoneNumber,
    helperText: errors.phoneNumber,
    mask: phoneMasks[userCountryName],
  };

  const documentField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field',
    id: 'documentNumber',
    name: 'documentNumber',
    value: formData.documentNumber,
    error: !!errors.documentNumber,
    helperText: errors.documentNumber,
  };

  const fantasyNameField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field',
    id: 'fantasyName',
    name: 'fantasyName',
    label: 'formfields_business_name',
    placeholder: 'formfields_business_name',
    value: formData.fantasyName,
    error: !!errors.fantasyName,
    helperText: errors.fantasyName,
  };

  const personInChargeField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field',
    id: 'personInChargeName',
    name: 'personInChargeName',
    label: 'formfields_responsible',
    placeholder: 'formfields_responsible',
    value: formData.personInChargeName,
    error: !!errors.personInChargeName,
    helperText: errors.personInChargeName,
  };

  const addressField: Partial<DynamicFormFieldControlProps> = {
    type: 'text-field',
    id: 'address',
    name: 'address',
    label: 'formfields_adress',
    placeholder: 'formfields_adress',
    value: formData.address,
    error: !!errors.address,
    helperText: errors.address,
  };

  const stateField: Partial<DynamicFormFieldControlProps> = {
    type: 'select',
    id: 'state',
    name: 'state',
    label: 'formfields_state',
    placeholder: 'formfields_adress',
    value: formData.ufId,
    options: states.map(state => ({
      key: `state-${state.id}`,
      value: state.id,
      label: state.name,
    })),
    error: !!errors?.ufId,
    helperText: errors?.ufId,
  };

  const cityField: Partial<DynamicFormFieldControlProps> = {
    type: 'auto-complete',
    id: 'city',
    name: 'city',
    label: 'formfields_label_city',
    placeholder: 'formfields_label_city',

    options: cities,
    getOptionKey: option => option.id,
    getOptionLabel: option => option.name,
    value: cities.find(city => city.id === formData?.cityId),
    error: !!errors.cityId,
    helperText: errors.cityId,
  };

  const brandField: Partial<DynamicFormFieldControlProps> = {
    type: 'auto-complete',
    id: 'brand',
    name: 'brand',
    label: 'formfields_brand',
    placeholder: 'formfields_brand',
    options: brands.filter(brand => brand.isAdministratorBrand === false),
    value: brands.filter(brand => formData.brandIds.includes(brand.id)),
    getOptionLabel: option => option.name,
    defaultValue: brands.filter(brand => formData.brandIds.includes(brand.id)),
    getOptionKey: option => option.id,
    error: !!errors.brandIds,
    helperText: errors.brandIds,
    filterSelectedOptions: true,
    multiple: true,
  };

  const regionField: Partial<DynamicFormFieldControlProps> = {
    type: 'select',
    id: 'region',
    name: 'region',
    label: 'formfields_region',
    placeholder: 'formfields_region',
    value: formData.regionId,
    options: regions.map(region => ({
      key: `region-${region.id}`,
      value: region.id,
      label: region.name,
    })),
    error: !!errors?.regionId,
    helperText: errors.regionId,
  };

  const imageFileField: Partial<DynamicFormFieldControlProps> = {
    type: 'image-file',
    id: 'logoFile',
    name: 'logoFile',
    label: 'formfields_company_logo',
    placeholder: 'formfields_upload_logo',
    value: formData.logoFile,
    defaultValue: formData.logoFile,
    error: !!errors.logoFile,
    helperText: errors.logoFile,
  };

  return {
    gdmSellerField,
    multiplierField,
    nameField,
    emailField,
    countryField,
    roleField,
    phoneField,
    documentField,
    fantasyNameField,
    personInChargeField,
    addressField,
    stateField,
    cityField,
    brandField,
    regionField,
    imageFileField,
  };
};

export default getCommonFormFieldProps;

// type="image-file"
// defaultValue={config.formData?.logoFile}
// onChange={handlers.file}

// type="select"
// label={getLabelTranslation('formfields_region')}
// disabled={
//   config.viewType === 'create' || config.viewType === 'edit'
//     ? false
//     : isDisabled || !config.formData?.ufId
// }
// onChange={handlers.changeRegion}
// options={config.regions?.map(region => ({
//   key: `region-${region.id}`,
//   value: region.id,
//   label: region.name,
// }))}

// type="auto-complete"
// id="brand"
// value={config.brands?.filter(brand => config.formData?.brandIds?.includes(brand.id))}
// defaultValue={config.brands.filter(brand =>
//   config.formData?.brandIds?.includes(brand.id),
// )}
// onChange={handlers.changeBrand}
// disabled={
//   [
//     `${getLabelTranslation('brandeselector_multiplier')}`,
//     `${getLabelTranslation('brandeselector_distributor')}`,
//   ].includes(entityType) ||
//   config.viewType === 'view' ||
//   config.viewType === 'edit'
//     ? true
//     : isDisabled
// }
// readOnly={[
//   `${getLabelTranslation('brandeselector_multiplier')}`,
//   `${getLabelTranslation('brandeselector_distributor')}`,
// ].includes(entityType)}

// type="auto-complete"
// id="city"
// inputValue={config.cityInputValue}
// onChangeInput={changeInputValue}

// type="select"
// id="state"
// onChange={handlers.changeStates}
// options={config.states?.map(state => ({
//   key: `state-${state.id}`,
//   value: state.id,
//   label: state.name,
// }))}

// type="text-field"
// id="fantasyName"
// disabled={config.viewType === 'view' || enableBusinessNameInput()}
// onChange={handlers.changeValues}

// type="text-field-mask"
// id="documentNumber"
// label={getDocumentLabel({ entityType, countryOfUser })}
// placeholder={getDocumentLabel({ entityType, countryOfUser })}
// value={documentInputValue}
// onChange={handlers.changeValues}
// mask={getDocumentMask({ entityType, countryOfUser })}

// type="select"
// id="role-fixed"
// label={
//   config.viewType === 'view'
//     ? getLabelTranslation('customRolesInputs_profile')
//     : getLabelTranslation('customRolesInputs_select_profile')
// }
// placeholder={getLabelTranslation('customRolesInputs_profile')}
// value={sellerGdmRoleId}
// options={sellerGdmRoleOptions}
// disabled

// type="select"
// id="country"
// disabled
// value={countryOfUser}
// onChange={handlers.changeCountry}
// options={[
//   { key: `country-${countryOfUser}`, label: countryOfUser, value: countryOfUser },
// ]}

// type="text-field"
// id="contactEmail"
// disabled={['view', 'edit'].includes(config.viewType) ? true : isDisabled}

// type="auto-complete"
// id="multiplier"
// value={
//   config.multipliers.find(
//     multiplier => multiplier.id === config.formData.multiplierId,
//   ) || null
// }
// onChange={handlers.multiplierId}
