import { SxProps } from '@mui/material';

export const boxStyle: SxProps = {
  backgroundColor: '#FAFAFA',
  padding: {
    xs: '0 4px',
    md: '64px',
  },
  borderRadius: '24px',
  boxShadow: ' 0px 3.342px 10.026px 0px rgba(34, 34, 34, 0.10)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '.MuiFormHelperText-root': {
    marginLeft: '0',
  },
};

export const inputMaskStyle = {
  padding: '14px 24px 14px 14px',
  borderRadius: '6px',
  height: '48px',
  fontSize: '16px',
  color: '#222222',
  border: '1px solid #A1A9B8',
  background: '#FAFAFA',
};

export const formControlStyle = {
  marginBottom: '18px',
};

export const formHelperTextStyle = {
  color: '#FB2047',
  position: 'absolute',
  top: '56px',
};
