import {
  DynamicFormData,
  GDMConditionalRender,
  GDMContainer,
  GDMDynamicFormFields,
  GDMLoading,
  GDMPageCard,
} from 'components';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import useModal from 'hooks/useModal/useModal';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dynamicFormValidateForm, getBrands, getRegions } from 'utils';
import { errorHandler } from 'utils/form/errorHandler';
import { SellerGDMService } from 'core/api/services/sellerGDM';
import { SellerGdmRegisterPageComponent } from '../SellerGdmRegisterPage.type';
import { updateSellerGdm } from '../utils';

const DEFAULT_ADMIN: Partial<DynamicFormData> = {
  name: '',
  contactEmail: '',
  phoneNumber: '',
  documentNumber: '',
};

const SellerGdmEditPage: SellerGdmRegisterPageComponent = () => {
  const { getLabelTranslation, currentLanguage } = useLanguageSwitcher();
  const {
    user: { countryName, countryId },
  } = useStore(UserProfileStore);

  const [sellerGdm, setSellerGdm] = useState<Partial<DynamicFormData>>(DEFAULT_ADMIN);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [regions, setRegions] = useState([]);
  const [entityType, setEntityType] = useState('Vendedor GDM');
  const { openModal, closeModal } = useModal();
  const { sellerGdmId } = useParams();

  useEffect(() => {
    const loadData: Array<Promise<any>> = [loadBrands(), loadRegions()];
    Promise.all(loadData).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsSubmiting(true);
    setEntityType('Vendedor GDM');

    SellerGDMService.getSellerGDMId(sellerGdmId)
      .then(response => {
        const data = response;

        setSellerGdm({
          ...data,
          id: sellerGdmId,
          contactEmail: data.email,
          brandIds: data.brands.map(item => item.id),
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmiting(false);
        setIsLoading(false);
      });
  }, [currentLanguage]);

  useEffect(() => {
    if (sellerGdm.documentNumber) {
      setDocumentTypeId();
    }

    if (sellerGdm.countryId) {
      setCountryId();
    }
  }, [sellerGdm.documentNumber, sellerGdm.countryId]);

  useEffect(() => {
    if (countryName) {
      setCountryId();
    }
  }, [countryName]);

  const loadBrands = () => {
    return getBrands({
      countryId,
      onError: () => console.error('Error on load brands!'),
      onInit: () => setIsLoading(true),
      onSuccess: brands => setBrands(brands),
    });
  };

  const loadRegions = () => {
    return getRegions({
      countryId,
      onError: () => console.error('Error on load regions!'),
      onInit: () => setIsLoading(true),
      onSuccess: regions => setRegions(regions),
    });
  };

  const handleValidate = sellerGdmFormdata =>
    dynamicFormValidateForm({
      formData: sellerGdmFormdata,
      countryName,
      entityType,
      getLabelTranslation,
    });

  const onValidate = errors => {
    if (Object.keys(errors)?.length) {
      setIsSubmiting(false);
    }
    setErrors(errors);
  };

  const handleChange = (e: any) => {
    const {
      target: { value, name },
    } = e;

    setSellerGdm(sellerGdmdata => ({ ...sellerGdmdata, [name]: value }));
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      onClose: closeModal,
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate(-1);
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const onError = errors => {
    errorHandler(errors, setErrors);

    openModal({
      type: 'error',
      title: getLabelTranslation('modal_tittle_update_error'),
      onClose: closeModal,
    });
  };

  const onInit = () => {
    setIsSubmiting(true);
  };

  const onSuccess = () => {
    openModal({
      type: 'success',
      title: getLabelTranslation('modal_tittle_update_success'),
      onClose: () => navigate('/account-seller-gdm-list'),
      primaryAction: {
        label: 'Ok',
        action: () => {
          closeModal();
          navigate('/account-seller-gdm-list');
        },
      },
    });
  };

  const onFinally = () => {
    setIsSubmiting(false);
  };

  const handleSubmit = async () => {
    await updateSellerGdm({
      sellerGdm,
      onError,
      onInit,
      onFinally,
      onSuccess,
      handleValidate,
      onValidate,
    });
  };

  const setDocumentTypeId = () => {
    if (countryName === 'Argentina') {
      setSellerGdm(prev => ({
        ...prev,
        documentTypeId: 'b85d6c71-e469-4268-a813-1bac98f596ae',
      }));
    }

    let onlyNumbers: number;

    if (sellerGdm.documentNumber) {
      onlyNumbers = sellerGdm.documentNumber.replace(/\D/g, '').length;
    }

    if (countryName === 'Brasil' && onlyNumbers === 11) {
      setSellerGdm(prev => ({
        ...prev,
        documentTypeId: 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d',
        fantasyName: '',
      }));
    } else if (countryName === 'Brasil' && onlyNumbers === 14) {
      setSellerGdm(prev => ({
        ...prev,
        documentTypeId: 'cc49f596-0841-4708-9ef8-6e372ea70ddf',
      }));
    }
  };

  const setCountryId = () => {
    if (countryName === 'Argentina') {
      setSellerGdm(prev => ({
        ...prev,
        countryId: '628ff4ec-0435-460a-b4ca-bcea09bf9f5b',
      }));
    }

    if (countryName === 'Brasil') {
      setSellerGdm(prev => ({
        ...prev,
        countryId: '41981f5c-0e28-44f5-ab84-d6259659abee',
      }));
    }
  };

  return (
    <GDMPageCard
      breadcrumbs={`${getLabelTranslation('administrative')} > ${getLabelTranslation(
        'ver_cadastros',
      )} > Vendedor GDM > ${getLabelTranslation('edit')}`}
      title={`${getLabelTranslation('reusableForm_EditRegistrationOf_')} Vendedor GDM`}
    >
      <GDMConditionalRender canRender={isLoading}>
        <GDMContainer sx={{ margin: 'auto auto' }}>
          <GDMLoading type="circular" />
        </GDMContainer>
      </GDMConditionalRender>

      <GDMConditionalRender canRender={!isLoading}>
        <GDMDynamicFormFields
          {...{
            entityType,
            viewType: 'edit',
            formData: sellerGdm,
            isSubmiting,
            errors,
            formDataEvents: {
              onCancel: handleCancel,
              onChange: handleChange,
              onSubmit: handleSubmit,
            },
            formDataOptions: {
              roles: [],
              brands,
              cities: [],
              states: [],
              regions,
              gdmSellers: [],
              multipliers: [],
            },
          }}
        />
      </GDMConditionalRender>
    </GDMPageCard>
  );
};

export default SellerGdmEditPage;
