import { SellerGDMService } from 'core/api/services/sellerGDM';
import { OnSubmitSellerGdmFunction } from './onSubmitSellerGdm.type';

const onSubmitSellerGdm: OnSubmitSellerGdmFunction = async ({
  sellerGdm,
  onInit,
  onError,
  onFinally,
  onSuccess,
  handleValidate,
  onValidate,
  method = 'post',
  id,
}) => {
  if (!!onInit) onInit();

  let errors: ObjectLiteral<string> = {};

  if (!!handleValidate) {
    errors = handleValidate(sellerGdm);
  }

  if (!!onValidate) onValidate(errors);

  if (handleValidate && Object.keys(errors)?.length) return;

  const sellerGdmData = {
    name: sellerGdm.name,
    email: sellerGdm.contactEmail,
    phoneNumber: sellerGdm.phoneNumber,
    documentTypeId: sellerGdm.documentTypeId,
    documentNumber: sellerGdm.documentNumber,
    countryId: sellerGdm.countryId,
    fantasyName: sellerGdm.fantasyName,
    personInChargeName: sellerGdm.personInChargeName,
    regionId: sellerGdm.regionId,
    brandIds: sellerGdm.brandIds,
  };

  try {
    const response = await SellerGDMService.upSertSellerGDM(method, sellerGdmData, id);

    if (!!onSuccess) onSuccess(response);
  } catch (error) {
    if (!!onError) onError(error);
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default onSubmitSellerGdm;
