import { SxProps } from '@mui/material';

export const dynamicFormSelectStyles = props => ({
  color: !props.value ? '#A1A9B8' : '#222222',
  borderRadius: '6px',
  height: '48px',
  boxSizing: 'border-box',
  '& .MuiSelect-select, & input': {
    height: '100%',
    boxSizing: 'border-box',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    display: 'flex',
    alignItems: 'center',
  },
  '&.Mui-disabled': {
    background: '#F2F4F9',
  },
  '& .MuiSelect-icon': {
    fontSize: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '& .MuiFormHelperText-root': {
    background: 'transparent',
    marginTop: '0px',
    paddingTop: '4px',
    height: '24px',
  },
  '& .Mui-error': {
    color: '#FB2047',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A1A9B8',
  },
});

export const dynamicFormSelectDefaultValueStyles: SxProps = {
  display: 'none',
};

export const selectFieldErrorMessageStyles: SxProps = {
  color: '#FB2047',
  marginTop: '0px',
  paddingTop: '4px',
  height: '24px',
};
