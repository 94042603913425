import { GDMCollapse } from 'components/gdm-collapse';
import { GDMContainer } from 'components/gdm-container';
import { GDMLink } from 'components/gdm-link';
import { GDMList } from 'components/gdm-list';
import { GDMListItem } from 'components/gdm-list-item';
import { GDMListItemIcon } from 'components/gdm-list-item-icon';
import { GDMTypography } from 'components/gdm-typography';
import colors from 'core/design-system/style-guide/colors.json';
import { FiEdit } from 'react-icons/fi';
import { IoEyeOutline } from 'react-icons/io5';
import { LuTrash2 } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { Email } from '../../AdminListPage.type';
import { AdminListListItemBodyMobileComponent } from './AdminListListItemBodyMobile.type';

const AdminParam = ({ label, value }: { label: string; value: string | Email }) => {
  return (
    <GDMListItem
      sx={{
        padding: '5px 0px',
      }}
    >
      <GDMTypography sx={{ flex: '0 0 auto' }} variant="body2">
        {label}
      </GDMTypography>
      <GDMTypography
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': '1',
        }}
        variant="body1"
        ml={1}
      >{` ${value}`}</GDMTypography>
    </GDMListItem>
  );
};

const AdminListListItemBodyMobile: AdminListListItemBodyMobileComponent = ({
  openedAdminId,
  admin,
  adminHeaderLabels,
  adminIdSelectedToDelete,
  handleOpenDeleteAdminModal,
}) => {
  const { id, countryName, documentNumber, email, phoneNumber } = admin;
  const navigate = useNavigate();
  const isSelectedToDelete = adminIdSelectedToDelete === id;

  const { countryNameLabel, documentNumberLabel, emailLabel, phoneNumberLabel, actionsLabel } =
    adminHeaderLabels;

  return (
    <GDMCollapse
      in={openedAdminId === id}
      timeout="auto"
      sx={{
        ...(isSelectedToDelete ? { backgroundColor: '#CFEFFF' } : {}),
      }}
      unmountOnExit
    >
      <GDMList component="div" disablePadding>
        <AdminParam label={emailLabel.label} value={email} />
        <AdminParam label={phoneNumberLabel.label} value={phoneNumber} />
        <AdminParam label={documentNumberLabel.label} value={documentNumber} />
        <AdminParam label={countryNameLabel.label} value={countryName} />

        <GDMListItem sx={{ pl: 0 }}>
          <GDMTypography variant="body2">{actionsLabel.label}</GDMTypography>
          <GDMContainer
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <GDMListItemIcon
              onClick={() => navigate(`/admins/view/${id}`)}
              sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
            >
              <IoEyeOutline color={colors.dark1} style={{ fontSize: '1.3rem' }} />
            </GDMListItemIcon>
            <GDMListItemIcon
              onClick={() => navigate(`/admins/edit/${id}`)}
              sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
            >
              <FiEdit color={colors.dark1} style={{ fontSize: '1.2rem' }} />
            </GDMListItemIcon>
            <GDMListItemIcon
              onClick={() => handleOpenDeleteAdminModal(id)}
              sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
            >
              <LuTrash2
                onClick={() => handleOpenDeleteAdminModal(id)}
                color={colors.dark1}
                style={{ fontSize: '1.2rem' }}
              />
            </GDMListItemIcon>
          </GDMContainer>
        </GDMListItem>
      </GDMList>
    </GDMCollapse>
  );
};

export default AdminListListItemBodyMobile;
