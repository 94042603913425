import { MultiplierService } from 'core/api/services/multiplier';
import { OnSubmitMultiplierFunction } from './onSubmitMultiplier.type';

const onSubmitMultiplier: OnSubmitMultiplierFunction = async ({
  multiplier,
  onInit,
  onError,
  onFinally,
  onSuccess,
  handleValidate,
  onValidate,
  method = 'post',
  id,
}) => {
  if (!!onInit) onInit();

  let errors: ObjectLiteral<string> = {};

  if (!!handleValidate) {
    errors = handleValidate(multiplier);
  }

  if (!!onValidate) onValidate(errors);

  if (handleValidate && Object.keys(errors)?.length) return;

  const multiplierFormData = new FormData();

  multiplierFormData.append('name', multiplier.name);
  multiplierFormData.append('fantasyName', multiplier.fantasyName || '');
  multiplierFormData.append('PersonInChargeName', multiplier.personInChargeName);
  multiplierFormData.append('contactEmail', multiplier.contactEmail);
  multiplierFormData.append('phoneNumber', multiplier.phoneNumber);
  multiplierFormData.append('documentTypeId', multiplier.documentTypeId);
  multiplierFormData.append('documentNumber', multiplier.documentNumber.replace('_', ''));
  multiplierFormData.append('cityId', multiplier.cityId);
  multiplierFormData.append('sellerGdmUserId', multiplier.sellerId);
  multiplierFormData.append('brandIds', multiplier.brandIds as any);
  multiplierFormData.append('address', multiplier.address);

  if (multiplier.logoFile) {
    multiplierFormData.append('logoFile', multiplier.logoFile);
  }

  try {
    const response = await MultiplierService.upSertMultiplier(method, multiplierFormData, id);

    if (!!onSuccess) onSuccess(response);
  } catch (error) {
    if (!!onError) onError(error);
  } finally {
    if (!!onFinally) onFinally();
  }
};

export default onSubmitMultiplier;
