import { GDMButton, GDMContainer } from 'components';
import { pageCardFooterStyles } from './GDMPageCardFooter.styles';
import { GDMPageCardFooterComponent } from './GDMPageCardFooter.type';

const GDMPageCardFooter: GDMPageCardFooterComponent = ({ footerActions = [] }) => {
  return (
    <GDMContainer sx={pageCardFooterStyles}>
      {footerActions.map(({ label, ...actionButtonProps }, index) => {
        const key = `card-footer-action-${index}-${label}`;

        return (
          <GDMButton key={key} {...actionButtonProps}>
            {label}
          </GDMButton>
        );
      })}
    </GDMContainer>
  );
};

export default GDMPageCardFooter;
