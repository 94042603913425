import type { Marker } from '@googlemaps/markerclusterer';
import { Avatar, Theme } from '@mui/material';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { GDMContainer } from 'components/gdm-container';
import { GDMTypography } from 'components/gdm-typography';
import { IRecommendation } from 'pages/dashboard/DashboardPage';
import { useCallback } from 'react';
import {
  markerAvatarInitialsContainerStyles,
  markerAvatarInitialsStyles,
  markerAvatarStyles,
} from './DashboardMapCustomMarker.styles';

export type DashboardMapCustomMarkerProps = {
  data: IRecommendation;
  onClick: (data: IRecommendation) => void;
  setMarkerRef: (marker: Marker | null, key: string) => void;
  theme: Theme;
};

const DashboardMapCustomMarker = (props: DashboardMapCustomMarkerProps) => {
  const { data, onClick, setMarkerRef, theme } = props;

  const handleClick = useCallback(() => onClick(data), [onClick, data]);
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef(marker, data.id),
    [setMarkerRef, data.id],
  );

  const firstName = data?.userName?.split(' ')[0] || '';
  const secondName = data?.userName?.split(' ')[1] || firstName || '';
  const firstString = firstName?.split('')[0] || '';
  const secondString = secondName?.split('')[0] || '';

  return (
    <AdvancedMarker position={data.position} ref={ref} onClick={handleClick}>
      <GDMContainer sx={{ position: 'relative' }}>
        <Avatar sx={markerAvatarStyles(theme)} alt="Marker">
          <svg
            width="30"
            height="40"
            viewBox="0 0 21 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.495 27.7033C10.4318 27.5529 9.11811 26.295 8.1774 25.1521C2.60408 17.9066 -3.60973 11.1744 3.64607 2.99317C6.85877 -0.0460703 10.8203 -0.219449 14.8928 1.1972C26.3601 7.69449 18.0576 18.2937 12.6062 25.2614L10.495 27.7033ZM13.9444 16.5971C21.5931 11.2411 15.6798 0.927371 7.27627 4.35602C4.82234 5.63721 3.60474 7.86642 3.56209 10.4958C3.44492 13.0537 4.83224 15.1926 7.03072 16.5029C8.60694 17.3429 9.29988 17.4946 11.0845 17.3905C12.2412 17.323 12.9078 17.1381 13.9444 16.5971Z"
              fill="currentColor"
            />
          </svg>
        </Avatar>
        <GDMContainer sx={markerAvatarInitialsContainerStyles(theme)}>
          <GDMTypography sx={markerAvatarInitialsStyles(theme)}>
            {`${firstString}${secondString}`}
          </GDMTypography>
        </GDMContainer>
      </GDMContainer>
    </AdvancedMarker>
  );
};

export default DashboardMapCustomMarker;
