import { useState } from 'react';

import { useMediaQuery } from '@mui/material';
import {
  GDMCollapse,
  GDMContainer,
  GDMDivider,
  GDMIconButton,
  GDMInputLabel,
  GDMList,
  GDMListItem,
  GDMListItemButton,
  GDMTable,
  GDMTableBody,
  GDMTableCell,
  GDMTableHead,
  GDMTableRow,
  GDMTypography,
} from 'components';
import GDMInputCheckbox from 'components/gdm-input-checkbox/GDMInputCheckbox';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import useThemeSwitcher from 'core/hooks/useThemeSwitcher';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { stringTruncate } from 'utils/formaters';

export default function PendingListTable({
  rows,
  setSelectedsIds,
  selectedsIds,
  orderColumn,
  setOrderColumn,
  showPlantingDate,
}: any): JSX.Element {
  const { theme } = useThemeSwitcher();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { getLabelTranslation } = useLanguageSwitcher();
  const [mobileOpen, setMobileOpen] = useState(null);

  const columnTitle: {
    id: number;
    name: string;
    title: any;
    type: string;
    hide?: boolean;
    canOrder?: boolean;
  }[] = [
    {
      id: 0,
      name: 'Select',
      title: '',
      type: 'checkbox',
    },
    {
      id: 1,
      name: 'Brand',
      title: getLabelTranslation('brand'),
      type: 'text',
    },
    {
      id: 2,
      name: 'RecommendationName',
      title: getLabelTranslation('table_recommendation_name'),
      type: 'text',
    },
    {
      id: 3,
      name: 'FarmerName',
      title: getLabelTranslation('table_farmer'),
      type: 'text',
      canOrder: true,
    },
    {
      id: 4,
      name: 'Account',
      title: getLabelTranslation('table_account_profile'),
      type: 'text',
    },
    {
      id: 5,
      name: 'SowingDate',
      title: getLabelTranslation('table_planting_date'),
      type: 'text',
      hide: !showPlantingDate,
      canOrder: true,
    },
    {
      id: 6,
      name: 'HarvestDate',
      title: getLabelTranslation('table_harvest_date'),
      type: 'text',
      hide: showPlantingDate,
      canOrder: true,
    },
    {
      id: 7,
      name: 'Status',
      title: getLabelTranslation('table_status'),
      type: 'text',
    },
  ];

  const changeOrderColumn = column => {
    setOrderColumn({
      column,
      orderDesc: !orderColumn.orderDesc,
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIds = rows.map(item => item.id);
      setSelectedsIds(newSelectedIds);
    } else {
      setSelectedsIds([]);
    }
  };

  const handleCheckboxClick = (id: string) => {
    setSelectedsIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      }
      return [...prevSelectedIds, id];
    });
  };

  const isSelected = (id: string) => selectedsIds.indexOf(id) !== -1;

  const numSelected = selectedsIds.length;
  const rowCount = rows.length;

  const handleMobileClick = id => {
    setMobileOpen(mobileOpen === id ? null : id);
  };

  return (
    <GDMTable>
      {isDesktop && (
        <GDMTableHead>
          <GDMTableRow sx={{ background: '#F2F4F9' }}>
            {columnTitle.map(column => {
              if (column.hide) return <></>;
              return (
                <GDMTableCell
                  key={column.id}
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {column.title}
                    </GDMTypography>
                    {column.type === 'text' && column.canOrder && (
                      <GDMIconButton
                        onClick={() => {
                          changeOrderColumn(column.name);
                        }}
                      >
                        <CgArrowsExchangeAltV />
                      </GDMIconButton>
                    )}
                    {column.type === 'checkbox' && (
                      <GDMInputCheckbox
                        id="select-all"
                        value="select-all"
                        onChange={handleSelectAllClick}
                        checked={numSelected === rowCount}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        sx={{
                          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                            color: theme.palette.primary.light,
                          },
                        }}
                      />
                    )}
                  </GDMContainer>
                </GDMTableCell>
              );
            })}
          </GDMTableRow>
        </GDMTableHead>
      )}
      <GDMTableBody>
        {rows?.length > 0 &&
          isDesktop &&
          rows.map(row => {
            const isItemSelected = isSelected(row.id);

            return (
              <GDMTableRow key={row.id} selected={isItemSelected}>
                <GDMTableCell>
                  <GDMInputCheckbox
                    id={row.id}
                    value={row.id}
                    checked={isItemSelected}
                    onChange={() => handleCheckboxClick(row.id)}
                    sx={{
                      color: '##CBCDD9',
                      '&.Mui-checked': {
                        color: theme.palette.primary.light,
                      },
                    }}
                  />
                </GDMTableCell>
                <GDMTableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >
                  <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                    {row.brandName}
                  </GDMTypography>
                </GDMTableCell>
                <GDMTableCell>
                  <GDMTypography
                    sx={{
                      color: '#222',
                      fontSize: '14px',
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '30ch',
                    }}
                  >
                    {row.name}
                  </GDMTypography>
                  <GDMTypography
                    sx={{ color: '#222', fontSize: '14px' }}
                  >{`${row.cultureName} | ${row.recommendationVariety[0]?.genotype}`}</GDMTypography>
                </GDMTableCell>
                <GDMTableCell>
                  <GDMTypography
                    sx={{
                      color: '#222',
                      fontSize: '14px',
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '20ch',
                    }}
                  >
                    {stringTruncate(row.farmerName, 35)}
                  </GDMTypography>
                  <GDMTypography
                    sx={{
                      color: '#222',
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '40ch',
                    }}
                  >
                    {`${stringTruncate(row.farmName, 20)} | ${stringTruncate(row.lotName, 20)}`}
                  </GDMTypography>
                </GDMTableCell>
                <GDMTableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20ch',
                  }}
                >
                  <GDMTypography
                    sx={{
                      color: '#222',
                      fontSize: '14px',
                      fontWeight: 600,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '22ch',
                    }}
                  >
                    {row.userName}
                  </GDMTypography>
                  <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                    {getLabelTranslation(row.userRole)}
                  </GDMTypography>
                </GDMTableCell>
                <GDMTableCell>
                  <GDMTypography sx={{ color: '#222', fontSize: '14px', textAlign: 'center' }}>
                    {showPlantingDate
                      ? row.recommendationVariety[0]?.sowingDate
                      : row.recommendationVariety[0]?.harvestDate}
                  </GDMTypography>
                </GDMTableCell>
                <GDMTableCell
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '20px',
                  }}
                >
                  <GDMTypography sx={{ color: '#222', fontSize: '14px' }}>
                    {getLabelTranslation(row.recommendationStatusName)}
                  </GDMTypography>
                </GDMTableCell>
              </GDMTableRow>
            );
          })}
        {rows.length > 0 && !isDesktop && (
          <>
            <GDMDivider />
            <GDMContainer
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <GDMInputCheckbox
                id="select-all-mobile"
                value="select-all-mobile"
                onChange={handleSelectAllClick}
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                sx={{
                  p: 0,
                  '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                    color: theme.palette.primary.light,
                  },
                }}
              />
              <GDMInputLabel
                sx={{
                  color: theme.palette.common.black,
                  fontSize: '16px',
                  lineHeight: '19px',
                }}
              >
                {numSelected === rowCount
                  ? getLabelTranslation('uncheck_all')
                  : getLabelTranslation('check_all')}
              </GDMInputLabel>
            </GDMContainer>
          </>
        )}
        {rows?.length > 0 &&
          !isDesktop &&
          rows.map(item => {
            const isItemSelected = isSelected(item.id);

            return (
              <GDMContainer key={item.id} sx={{ background: 'transparent' }}>
                <GDMDivider />
                <GDMListItemButton sx={{ p: 0 }}>
                  <GDMListItem sx={{ p: 0 }}>
                    <GDMInputCheckbox
                      id={item.id}
                      value={item.id}
                      checked={isItemSelected}
                      onChange={() => handleCheckboxClick(item.id)}
                      sx={{
                        p: 0,
                        '&.Mui-checked': {
                          color: theme.palette.primary.light,
                        },
                      }}
                    />
                    <GDMTypography
                      onClick={() => handleMobileClick(item.id)}
                      variant="body1"
                      sx={{
                        flex: 1,
                        fontWeight: 600,
                      }}
                      ml={1}
                    >{` ${item.name}`}</GDMTypography>
                  </GDMListItem>
                  <GDMIconButton
                    onClick={() => handleMobileClick(item.id)}
                    sx={{
                      color: '#222',
                      width: '32px',
                      height: '32px',
                      backgroundColor: theme.palette.primary.light,
                      borderRadius: '50%',
                    }}
                  >
                    {mobileOpen === item.id ? (
                      <MdExpandLess color={theme.palette.common.black} />
                    ) : (
                      <MdExpandMore color={theme.palette.common.black} />
                    )}
                  </GDMIconButton>
                </GDMListItemButton>
                <GDMCollapse
                  in={mobileOpen === item.id}
                  timeout="auto"
                  unmountOnExit
                  sx={{ background: 'transparent' }}
                >
                  <GDMList component="div" disablePadding>
                    <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <GDMTypography variant="body2">
                        {getLabelTranslation('culture_variety_label')}
                      </GDMTypography>
                      <GDMTypography
                        variant="body1"
                        sx={{ maxWidth: '70%', textAlign: 'right' }}
                      >{` ${item.cultureName} | ${item.recommendationVariety[0]?.genotype}`}</GDMTypography>
                    </GDMListItem>
                    <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <GDMTypography variant="body2">
                        {getLabelTranslation('brand_label')}
                      </GDMTypography>
                      <GDMTypography variant="body1" sx={{ maxWidth: '70%', textAlign: 'right' }}>
                        {item.brandName}
                      </GDMTypography>
                    </GDMListItem>
                    <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <GDMTypography variant="body2">
                        {columnTitle.find(x => x.name === 'FarmerName').title}
                      </GDMTypography>
                      <GDMTypography variant="body1" sx={{ maxWidth: '70%', textAlign: 'right' }}>
                        {item.farmerName}
                      </GDMTypography>
                    </GDMListItem>
                    <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <GDMTypography variant="body2">
                        {getLabelTranslation('farm_lot_name_label')}
                      </GDMTypography>
                      <GDMTypography variant="body1" sx={{ maxWidth: '70%', textAlign: 'right' }}>
                        {`${item.farmName} | ${item.lotName}`}
                      </GDMTypography>
                    </GDMListItem>
                    <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <GDMTypography variant="body2">
                        {columnTitle.find(x => x.name === 'Account').title}
                      </GDMTypography>
                      <GDMTypography
                        variant="body1"
                        sx={{ maxWidth: '70%', textAlign: 'right' }}
                      >{` ${item.userName} | ${getLabelTranslation(item.userRole)}`}</GDMTypography>
                    </GDMListItem>
                    {showPlantingDate && (
                      <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                        <GDMTypography variant="body2">
                          {columnTitle.find(x => x.name === 'SowingDate').title}
                        </GDMTypography>
                        <GDMTypography variant="body1" sx={{ maxWidth: '70%', textAlign: 'right' }}>
                          {item.recommendationVariety[0]?.sowingDate}
                        </GDMTypography>
                      </GDMListItem>
                    )}
                    {!showPlantingDate && (
                      <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                        <GDMTypography variant="body2">
                          {columnTitle.find(x => x.name === 'HarvestDate').title}
                        </GDMTypography>
                        <GDMTypography variant="body1" sx={{ maxWidth: '70%', textAlign: 'right' }}>
                          {item.recommendationVariety[0]?.harvestDate}
                        </GDMTypography>
                      </GDMListItem>
                    )}
                    <GDMListItem sx={{ pl: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <GDMTypography variant="body2">
                        {columnTitle.find(x => x.name === 'Status').title}
                      </GDMTypography>
                      <GDMTypography variant="body1" sx={{ maxWidth: '70%', textAlign: 'right' }}>
                        {getLabelTranslation(item.recommendationStatusName)}
                      </GDMTypography>
                    </GDMListItem>
                  </GDMList>
                </GDMCollapse>
              </GDMContainer>
            );
          })}
      </GDMTableBody>
    </GDMTable>
  );
}
