// src/axiosConfig.ts
import Axios, { AxiosRequestConfig } from 'axios';
import { getApiHost } from 'core/api/services/HostService/HostService';

import { acquireToken } from '../../../../authConfig';

export interface IRequest {
  url: string;
  params?: any;
  body?: any;
  config?: AxiosRequestConfig;
}
Axios.interceptors.request.use(
  async config => {
    try {
      const token = await acquireToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Error acquiring token', error);
    }
    config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_API_KEY;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const Api = {
  get: ({ url, params }: IRequest): Promise<any> =>
    Axios.get(`${getApiHost()}${url}`, {
      ...params,
    }),

  post: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.post(`${getApiHost()}${url}`, body, {
      ...config,
    }),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.put(`${getApiHost()}${url}`, body, {
      headers: {
        ...config?.headers,
      },
      ...config,
    }),

  delete: ({ url }: IRequest): Promise<any> => Axios.delete(`${getApiHost()}${url}`),
};

export default Api;
