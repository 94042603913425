import { Pagination, Table, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GDMCollapse } from 'components/gdm-collapse';
import { GDMContainer } from 'components/gdm-container';
import { GDMDivider } from 'components/gdm-divider';
import { GDMIconButton } from 'components/gdm-icon-button';
import { GDMList } from 'components/gdm-list';
import { GDMListItem } from 'components/gdm-list-item';
import { GDMListItemButton } from 'components/gdm-list-item-button';
import { GDMTableBody } from 'components/gdm-table-body';
import { GDMTableCell } from 'components/gdm-table-cell';
import { GDMTableContainer } from 'components/gdm-table-container';
import { GDMTableHead } from 'components/gdm-table-head';
import { GDMTableRow } from 'components/gdm-table-row';
import { GDMTypography } from 'components/gdm-typography';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useState } from 'react';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import colors from 'core/design-system/style-guide/colors.json';
import textStyles from 'core/design-system/style-guide/typography.json';

export const GDMTableAccounts = ({
  rows,
  filters,
  setFilters,
}: {
  rows: any;
  filters: {
    pages: number;
    currentPage: number;
    orderColum: string;
    orderDesc: boolean;
    totalPages: number;
  };
  setFilters: any;
}) => {
  const { getLabelTranslation } = useLanguageSwitcher();
  const theme = useTheme();
  const [openCollapse, setOpenCollapse] = useState(null);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const columns = [
    {
      name: 'RoleName',
      title: getLabelTranslation('account'),
    },
    {
      name: 'Name',
      title: getLabelTranslation('name'),
    },
    {
      name: 'FantasyName',
      title: getLabelTranslation('fantasy_name'),
    },
    {
      name: 'Email',
      title: getLabelTranslation('formfields_email'),
    },
    {
      name: 'PhoneNumber',
      title: getLabelTranslation('formfields_phone'),
    },
    {
      name: 'PersonInChargeName',
      title: getLabelTranslation('responsable'),
    },
  ];

  const handleOpenCollapse = id => {
    setOpenCollapse(openCollapse === id ? null : id);
  };

  const changeOrderColumn = (column: string) => {
    setFilters(prev => ({ ...prev, orderColum: column, orderDesc: !filters.orderDesc }));
  };
  return (
    <GDMContainer>
      <GDMTableContainer>
        <Table sx={{ width: '100%' }}>
          {isDesktop && (
            <GDMTableHead>
              <GDMTableRow sx={{ background: '#F2F4F9' }}>
                {columns.map((column, index) => {
                  const key = `col-${index}-${column.name}`;

                  return (
                    <GDMTableCell
                      key={key}
                      sx={{
                        border: 'none',
                      }}
                    >
                      <GDMContainer sx={{ display: 'flex', alignItems: 'center' }}>
                        <GDMTypography
                          variant="subtitle2"
                          sx={{
                            color: '#777D88',
                          }}
                        >
                          {column.title}
                        </GDMTypography>
                        <GDMIconButton
                          onClick={() => {
                            changeOrderColumn(column.name);
                          }}
                        >
                          <CgArrowsExchangeAltV />
                        </GDMIconButton>
                      </GDMContainer>
                    </GDMTableCell>
                  );
                })}
                <GDMTableCell
                  sx={{
                    border: 'none',
                  }}
                >
                  <GDMContainer sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GDMTypography
                      variant="subtitle2"
                      sx={{
                        color: '#777D88',
                      }}
                    >
                      {getLabelTranslation('brands_table')}
                    </GDMTypography>
                  </GDMContainer>
                </GDMTableCell>
              </GDMTableRow>
            </GDMTableHead>
          )}
          <GDMTableBody>
            {isDesktop
              ? rows.map(row => (
                  <GDMTableRow key={row.id}>
                    <GDMTableCell>
                      <GDMTypography
                        sx={{
                          color: '#222',
                          fontSize: '14px',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '20ch',
                        }}
                      >
                        {getLabelTranslation(row.roleName)}
                      </GDMTypography>
                    </GDMTableCell>
                    <GDMTableCell>
                      <GDMTypography
                        sx={{
                          fontSize: '14px',
                          color: '#222',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '20ch',
                        }}
                      >
                        {row.name}
                      </GDMTypography>
                    </GDMTableCell>
                    <GDMTableCell>
                      <GDMTypography
                        sx={{
                          fontSize: '14px',
                          color: '#222',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '20ch',
                        }}
                      >
                        {row.fantasyName}
                      </GDMTypography>
                    </GDMTableCell>
                    <GDMTableCell>
                      <GDMTypography
                        sx={{
                          fontSize: '14px',
                          color: '#222',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '25ch',
                        }}
                      >
                        {row.email}
                      </GDMTypography>
                    </GDMTableCell>
                    <GDMTableCell>
                      <GDMTypography
                        sx={{
                          color: '#222',
                          fontSize: '14px',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '20ch',
                        }}
                      >
                        {row.phoneNumber}
                      </GDMTypography>
                    </GDMTableCell>
                    <GDMTableCell>
                      <GDMTypography
                        sx={{
                          fontSize: '14px',
                          color: '#222',
                          fontWeight: 400,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: '25ch',
                        }}
                      >
                        {row.personInChargeName}
                      </GDMTypography>
                    </GDMTableCell>
                    <GDMTableCell>
                      {row.brands.map(brand => (
                        <GDMTypography
                          key={brand.id}
                          sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}
                        >
                          {brand.name}
                        </GDMTypography>
                      ))}
                    </GDMTableCell>
                  </GDMTableRow>
                ))
              : rows.map(row => (
                  <GDMContainer key={row.id}>
                    <GDMListItemButton onClick={() => handleOpenCollapse(row.id)}>
                      <GDMListItem
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <GDMTypography variant="body1" sx={{ fontWeight: 700 }}>
                          {getLabelTranslation(row.roleName)}
                        </GDMTypography>
                        <GDMTypography
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '20ch',
                          }}
                          variant="body1"
                        >
                          {row.name}
                        </GDMTypography>
                      </GDMListItem>
                      <GDMIconButton
                        sx={{
                          color: '#222',
                          width: '32px',
                          height: '32px',
                          backgroundColor: theme.palette.secondary.main,
                          borderRadius: '50%',
                        }}
                      >
                        {openCollapse === row.id ? <MdExpandLess /> : <MdExpandMore />}
                      </GDMIconButton>
                    </GDMListItemButton>
                    <GDMCollapse in={openCollapse === row.id} timeout="auto" unmountOnExit>
                      <GDMList
                        component="div"
                        disablePadding
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <GDMListItem sx={{ pl: 4, display: 'flex' }}>
                          <GDMTypography variant="body2">
                            {getLabelTranslation('fantasy_name')}
                          </GDMTypography>
                          <GDMTypography
                            variant="body1"
                            ml={1}
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '20ch',
                            }}
                          >
                            {row.fantasyName}
                          </GDMTypography>
                        </GDMListItem>
                        <GDMListItem sx={{ pl: 4, display: 'flex' }}>
                          <GDMTypography variant="body2">
                            {getLabelTranslation('formfields_email')}
                          </GDMTypography>
                          <GDMTypography
                            variant="body1"
                            ml={1}
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '30ch',
                            }}
                          >
                            {row.email}
                          </GDMTypography>
                        </GDMListItem>
                        <GDMListItem sx={{ pl: 4, display: 'flex' }}>
                          <GDMTypography variant="body2">
                            {getLabelTranslation('formfields_phone')}
                          </GDMTypography>
                          <GDMTypography variant="body1" ml={1}>
                            {row.phoneNumber}
                          </GDMTypography>
                        </GDMListItem>
                        <GDMListItem sx={{ pl: 4, display: 'flex' }}>
                          <GDMTypography variant="body2">
                            {getLabelTranslation('responsable')}
                          </GDMTypography>
                          <GDMTypography
                            variant="body1"
                            ml={1}
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '20ch',
                            }}
                          >
                            {row.personInChargeName}
                          </GDMTypography>
                        </GDMListItem>
                        <GDMListItem sx={{ pl: 4, display: 'flex' }}>
                          <GDMTypography variant="body2">
                            {getLabelTranslation('brands_table')}
                          </GDMTypography>
                          <GDMContainer sx={{ paddingLeft: '12px' }}>
                            {row.brands.map(brand => (
                              <GDMTypography
                                key={brand.id}
                                sx={{ fontSize: '14px', color: '#222', fontWeight: 400 }}
                              >
                                {brand.name}
                              </GDMTypography>
                            ))}
                          </GDMContainer>
                        </GDMListItem>
                      </GDMList>
                    </GDMCollapse>
                    <GDMDivider />
                  </GDMContainer>
                ))}
          </GDMTableBody>
        </Table>
      </GDMTableContainer>
      <Pagination
        count={filters.totalPages}
        page={filters.currentPage}
        onChange={(_, page) => setFilters(prev => ({ ...prev, currentPage: page }))}
        variant="outlined"
        shape="rounded"
        boundaryCount={isDesktop ? 1 : 0}
        sx={{
          mt: 2,
          justifyContent: 'center',
          display: 'flex',
          '& ul > li > button': {
            backgroundColor: colors.light2,
            width: {
              xs: '35px',
              sm: '40px',
              md: '45px',
              lg: '51px',
              xl: '51px',
            },
            height: {
              xs: '35px',
              sm: '40px',
              md: '45px',
              lg: '51px',
              xl: '51px',
            },
            border: 'none',
            ...textStyles.table.pagination,
          },
          '& ul > li:not(:first-child):not(:last-child) > .Mui-selected': {
            backgroundColor: colors.dark3,
          },
        }}
      />
    </GDMContainer>
  );
};
