/* eslint-disable no-console */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Rating,
  Snackbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GDMButton, GDMContainer, GDMTextField, GDMTypography } from 'components';
import { RecommendationService } from 'core/api/services/recommendation';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import { useEffect, useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { IoCloseSharp } from 'react-icons/io5';
import { LiaInfoCircleSolid } from 'react-icons/lia';
import colors from '../../../../core/design-system/style-guide/colors.json';
import GDMGridInfo from '../gdm-grid-info/GDMGridInfo';
import GDMLineInfo from '../gdm-line-info/GDMLineInfo';

const GDMGenericModal = ({
  openModal,
  recommendationVarietyId,
  recommendationId,
  setOpenModal,
  rattingParameters,
  recommendationSaved,
  recommendationParametersData,
  genotype,
}: {
  openModal: boolean;
  recommendationVarietyId: string;
  recommendationId?: string;
  setOpenModal: (b: boolean) => void;
  rattingParameters: any;
  recommendationParametersData: any;
  recommendationSaved: () => void;
  genotype: string;
}) => {
  const [step, setStep] = useState(0);
  const [recommendations, setRecommendations] = useState(recommendationParametersData);
  const [recommendationName, setRecommendationName] = useState('');
  const { getLabelTranslation } = useLanguageSwitcher();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [stateFeedback, setStateFeedBack] = useState<{
    open: boolean;
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  }>({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal, open } = stateFeedback;
  const [ratting, setRatting] = useState(null);
  const [rattingError, setRattingError] = useState(false);
  const [userEvaluation, setUserEvaluation] = useState(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const handleRatingDescription = () => {
    const rattingMap = {
      1: 'VERY_UNSATISFIED',
      2: 'UNSATISFIED',
      3: 'REGULAR',
      4: 'SATISFIED',
      5: 'VERY_SATISFIED',
    };

    const description = rattingMap[ratting];
    const parameter = rattingParameters.find(param => param.description === description);

    return parameter ? parameter.key : '';
  };

  const setRattingId = () => {
    const rattingMap = {
      1: 'VERY_UNSATISFIED',
      2: 'UNSATISFIED',
      3: 'REGULAR',
      4: 'SATISFIED',
      5: 'VERY_SATISFIED',
    };

    const description = rattingMap[ratting];
    const id = description && rattingParameters.find(param => param.description === description).id;
    return id || null;
  };
  const handleClose = () => {
    setStateFeedBack(prev => ({ ...prev, open: false }));
  };

  const handleCancelRating = async () => {
    if (step === 1) {
      const rattingId = null;
      try {
        await RecommendationService.rattingRecomendation({
          recommendationId,
          rattingId,
          userEvaluation,
        });
      } catch (err) {
        console.error(err);
      } finally {
        setOpenModal(false);
        setStep(0);
        setRatting(null);
        setUserEvaluation(null);
        setRattingError(false);
      }
    } else {
      setOpenModal(false);
      setStep(0);
      setRatting(null);
      setUserEvaluation(null);
      setRattingError(false);
    }
  };

  const handleSubmit = async () => {
    if (!recommendationName) return;
    const rattingId = await setRattingId();
    if (step === 0) {
      try {
        setSendLoading(true);
        await RecommendationService.saveRecommendation({
          recommendationId,
          recommendationName,
          recommendationVarietyId,
        });
        setStateFeedBack(prev => ({ ...prev, open: true }));
        setStep(1);
      } catch (err) {
        console.error(err);
      } finally {
        setSendLoading(false);
        recommendationSaved();
      }
    } else {
      const delay = ms =>
        new Promise(resolve => {
          setTimeout(resolve, ms);
        });

      if (!ratting) {
        setRattingError(true);
        return;
      }

      try {
        setSendLoading(true);

        await RecommendationService.rattingRecomendation({
          recommendationId,
          rattingId,
          userEvaluation,
        });

        await delay(1000);
      } catch (err) {
        console.error(err);
      } finally {
        if (ratting) {
          setSendLoading(false);
          setOpenModal(false);
          setStep(0);
          setRatting(null);
          setUserEvaluation(null);
          setRattingError(false);
        }
      }
    }
  };

  useEffect(() => {
    setRecommendations(recommendationParametersData);
    setNameError(false);
  }, [recommendationParametersData]);

  return (
    <Dialog
      open={openModal}
      maxWidth={step === 0 ? 'sm' : 'md'}
      sx={{ '& .MuiPaper-rounded': { borderRadius: '24px' } }}
    >
      {step === 0 ? (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <FiSave color="#222" />
              <GDMTypography
                variant="inherit"
                sx={{ color: '#222', fontWeight: 700, fontSize: '16px' }}
              >
                {getLabelTranslation('save_recommendation')}
              </GDMTypography>
            </GDMContainer>
            <IconButton onClick={() => setOpenModal(false)}>
              <IoCloseSharp color="#222" />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <GDMContainer>
              <GDMTypography
                fontWeight={400}
                component="div"
                color={colors.black}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '37px',
                  width: 'fit-content',
                  margin: '0 auto',
                  padding: '0 16px',
                  fontSize: '20px',
                  fontWeight: 400,
                  background: theme.palette.primary.light,
                  borderRadius: '50px',
                }}
              >
                {genotype}
              </GDMTypography>
            </GDMContainer>
            <GDMContainer>
              <GDMTypography
                sx={{
                  fontSize: isDesktop ? '16px' : '14px',
                  fontWeight: 500,
                  padding: isDesktop ? '8px 0 24px' : '8px 0 16px',
                }}
              >
                {getLabelTranslation('review_params')}
              </GDMTypography>
              <GDMContainer
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItem: 'center',
                  paddingBottom: '24px',
                  flexDirection: isDesktop ? 'row' : 'column',
                }}
              >
                {recommendations &&
                  recommendations.fixedVariables?.map(env => {
                    if (env.value)
                      return (
                        <GDMGridInfo id={env.id} key={env.id} name={env.name} value={env.value} />
                      );
                    return <></>;
                  })}
              </GDMContainer>
            </GDMContainer>
            <Divider />
            <GDMContainer
              sx={{ paddingTop: '24px', gap: '24px', display: 'flex', flexDirection: 'column' }}
            >
              {recommendations &&
                recommendations.environmentalVariables?.map(
                  env =>
                    env.value && (
                      <GDMLineInfo
                        name={env.name}
                        id={env.id}
                        type={env.type}
                        value={env.value}
                        key={env.id}
                        legend={env.legend}
                      />
                    ),
                )}
            </GDMContainer>
            <GDMContainer
              sx={{
                paddingBottom: '24px',
                paddingTop: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <GDMContainer sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <GDMTypography variant="inherit" sx={{ fontSize: '16px' }}>
                  {getLabelTranslation('save_as')}
                </GDMTypography>
                <GDMTextField
                  placeholder={getLabelTranslation('name_recommendation')}
                  value={recommendationName}
                  onChange={({ target }) => {
                    setRecommendationName(target.value);
                    if (target.value.length > 0) setNameError(false);
                  }}
                  helperText={
                    nameError && getLabelTranslation('save_recommendation_name_mandatory')
                  }
                  FormHelperTextProps={{ sx: { color: 'red' } }}
                  sx={{ background: '#fafafa', input: { padding: '12px 14px' } }}
                />
              </GDMContainer>
              <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <LiaInfoCircleSolid color="#0A3C56" fontSize={isDesktop ? 20 : 32} />
                <GDMTypography sx={{ fontSize: '14px', lineHeight: '24px' }}>
                  {getLabelTranslation('you_can_view')}
                  <strong>{getLabelTranslation('to_see_recommendations')}</strong>
                </GDMTypography>
              </GDMContainer>
            </GDMContainer>
            <Divider />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={() => handleCancelRating()}>
              <IoCloseSharp color="#222" />
            </IconButton>
          </DialogContent>

          <DialogContent sx={{ padding: '0 24px 24px 24px' }}>
            <GDMContainer
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <GDMTypography
                sx={{
                  fontSize: { xs: '20px', sm: '24px' },
                  fontWeight: 700,
                  lineHeight: '26.4px',
                  color: '#222222',
                  textAlign: 'center',
                  wordBreak: 'break-word',
                  maxWidth: '30ch',
                  pb: 2,
                }}
              >
                {getLabelTranslation('modal_stars_diserved_message')}
              </GDMTypography>
              <GDMContainer
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                  paddingBottom: '24px',
                }}
              >
                <Rating
                  size="large"
                  name="simple-controlled"
                  value={ratting}
                  onChange={(_, newValue) => {
                    setRatting(newValue);
                    setRattingError(false);
                  }}
                />
                {ratting && (
                  <GDMTypography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '19.6px',
                      color: '#222222',
                      fontWeight: 400,
                    }}
                  >
                    {getLabelTranslation(handleRatingDescription())}
                  </GDMTypography>
                )}
                {rattingError && (
                  <GDMTypography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '19.6px',
                      color: '#EB5757',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}
                  >
                    {getLabelTranslation('modal_stars_diserved_message_error')}
                  </GDMTypography>
                )}
              </GDMContainer>
            </GDMContainer>
            <GDMContainer
              sx={{
                paddingBottom: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <GDMContainer sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <GDMTextField
                  onChange={e => setUserEvaluation(e.target.value)}
                  placeholder={getLabelTranslation('modal_opinion_message')}
                  multiline
                  inputProps={{
                    maxLength: 250,
                    style: {
                      height: 'auto',
                      minHeight: '95px',
                    },
                  }}
                  sx={{
                    width: '100%',
                    borderRadius: '6px',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '16px',
                    backgroundColor: '#FAFAFA',
                    '&::placeholder': {
                      color: '#A1A9B8',
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                    },
                  }}
                />
                <GDMTypography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    color: '#777d88',
                    fontWeight: 400,
                    pt: '10px',
                  }}
                >
                  {getLabelTranslation('modal_limited_characters')}
                </GDMTypography>
              </GDMContainer>
              <GDMContainer sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <GDMTypography sx={{ fontSize: '16px', lineHeight: '22.4px' }}>
                  {getLabelTranslation('modal_feedback_important_message')}
                </GDMTypography>
              </GDMContainer>
            </GDMContainer>
            <Divider />
          </DialogContent>
        </>
      )}

      <DialogActions sx={{ padding: '0 24px 24px 24px' }}>
        <GDMContainer
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            flex: 1,
            gap: isDesktop ? '24px' : '16px',
          }}
        >
          <GDMButton
            onClick={() => {
              handleClose();
              handleCancelRating();
            }}
            sx={{
              display: 'flex',
              flex: 1,
              color: '#222222',
              background: theme.palette.secondary.main,
              '&:hover': {
                background: theme.palette.secondary.main,
                opacity: 0.8,
              },
            }}
          >
            {getLabelTranslation('cancel')}
          </GDMButton>
          <GDMButton
            onClick={() => {
              handleSubmit();
              setNameError(!recommendationName);
            }}
            disabled={sendLoading}
            sx={{
              display: 'flex',
              flex: 1,
              background: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.main,
                opacity: 0.8,
              },
              color: '#fafafa',
              '&:disabled': {
                background: '#777d88',
                color: '#fafafa',
              },
            }}
          >
            {step === 0 ? getLabelTranslation('save') : getLabelTranslation('send')}
          </GDMButton>
        </GDMContainer>
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        message={
          <GDMTypography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
            {getLabelTranslation('recommendation_saved')} <br />
            <strong>{getLabelTranslation('ver_recomendacoes')}</strong>
          </GDMTypography>
        }
        key={vertical + horizontal}
        ContentProps={{
          sx: {
            background: '#FAFAFA',
            borderLeft: '17px solid #06c270',
            color: '#222',
            fontSize: '14px',
          },
        }}
        action={
          <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
            <IoCloseSharp color="#222" />
          </IconButton>
        }
      />
    </Dialog>
  );
};

export default GDMGenericModal;
