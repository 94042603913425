import { CountriesDomainEnum } from 'core/typing/domains/CountriesDomain/CountriesDomain';

export enum MaskTypes {
  PERSON_DOCUMENT_NUMBER = 'personDocumentNumber',
  COMPANY_DOCUMENT_NUMBER = 'companyDocumentNumber',
  PHONE_NUMBER = 'phoneNumber',
}

export enum Masks {
  PERSON_DOCUMENT_NUMBER_BR = '',
  COMPANY_DOCUMENT_NUMBER_BR = '',
  PHONE_NUMBER_BR = '+99 99 99999-9999',
  PERSON_DOCUMENT_NUMBER_AR = '999.999.999-99',
  COMPANY_DOCUMENT_NUMBER_AR = '',
  PHONE_NUMBER_AR = '+99 9 999 999-9999',
}

export type MasksByCountry = {
  [maskType in MaskTypes]: Masks;
};

export type MaskConfig = {
  [countryName in CountriesDomainEnum]: MasksByCountry;
};
