import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const dynamicFormLabelStyles: SxProps = {
  fontSize: '16px',
  lineHeight: '22.4px',
  color: '#222222',
};
export const dynamicFormLabelRequiredIndicatorStyles: CSSProperties = {
  fontSize: '16px',
  lineHeight: '22.4px',
  color: '#FB2047',
};
