import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes as Switch, useNavigate } from 'react-router-dom';

import { SideBar } from 'core/components/SideBar/SideBar';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { useStore } from 'effector-react';
import { AdminListPage } from 'pages';

import AccountsEdit from 'pages/accounts/accounts-edit/AccountsEdit';
import ManagerDistList from 'pages/accounts/accounts-list/Manager-dist-list';
import ManagerMultList from 'pages/accounts/accounts-list/Manager-mult-list';
import SellerDistList from 'pages/accounts/accounts-list/Seller-dist-list';
import SellerGdmList from 'pages/accounts/accounts-list/Seller-gdm-list';
import SellerMultList from 'pages/accounts/accounts-list/Seller-mult-list';
import AccountsVisualize from 'pages/accounts/accounts-visualize/AccountsVisualize';
import DashboardPage from 'pages/dashboard/DashboardPage';
import DistributorPage from 'pages/distributor-list/DistributorList';
import FarmFormPage from 'pages/farm-form/FarmForm';
import FarmList from 'pages/farm-list/FarmList';
import FarmerForm from 'pages/farmer-form/FarmerForm';
import FarmerList from 'pages/farmer-list/FarmerList';
import FarmerRegister from 'pages/farmer-register/FarmerRegister';
import ListMenuPage from 'pages/list-menu/ListMenuPage';
import LotList from 'pages/lot-list/LotList';
import MultiplierPage from 'pages/multiplier-list/MultiplierList';
import ProfilePage from 'pages/profile/Profile';
import RecommendationPage from 'pages/recommendation/RecommendationPage';
import SelectBrandPage from 'pages/select-brand/SelectBrandPage';
import ShowFarmerRegisters from 'pages/show-farmer-registers/ShowFarmerRegisters';
import TermOfUser from 'pages/term-user/TermOfUser';

import { SxProps, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { GDMHeader } from 'components/gdm-header';
import { MultiplierEditPage, MultiplierRegisterPage, MultiplierViewPage } from 'pages/multiplier';
import DistributorViewPage from 'pages/distributor/pages/DistributorViewPage';
import DistributorRegisterPage from 'pages/distributor/pages/DistributorRegisterPage';
import DistributorEditPage from 'pages/distributor/pages/DistributorEditPage';

import { GDMContainer } from 'components';
import { useRouteContext } from 'core/store/contexts/routerContext';
import AccountsRegister from 'pages/accounts/accounts-register/AccountsRegister';
import Catalog from 'pages/catalog/Catalog';
import LotForm from 'pages/lot-form/LotForm';
import RecommendationList from 'pages/recommendation-list/RecommendationList';
import RecommendationPendingList from 'pages/recommendation-pending/RecommendationPendingList';
import RecommendationToSave from 'pages/recommendation/RecommendationToSave';
import RecommendationViewPage from 'pages/recommendation/RecommendationView/RecommendationViewPage';
import VarietiesToCompare from 'pages/varieties-to-compare/VarietiesToCompare';
import { AdminEditPage, AdminRegisterPage, AdminViewPage } from 'pages/admin';
import { SellerGdmEditPage, SellerGdmRegisterPage, SellerGdmViewPage } from 'pages/seller-gdm';
import backgrounTerm from '../../assets/images/Rectangle.jpg';
import { ProtectedRoute } from './ProtectedRoute';

const OnboardingPage = lazy(() => import('pages/onboarding/OnboardingPage'));

export function Routes(): JSX.Element {
  const theme = useTheme();
  const { user } = useStore(UserProfileStore);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const themeSelected = localStorage.getItem('theme_recomendador');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [termsWasOpened, setTermsWasOpened] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleBack = () => {
    setTermsWasOpened(false);
  };
  const handleNavigation = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  const { pathname } = useRouteContext();

  useEffect(() => {
    dispatchEvent(new CustomEvent('changeRoute', { detail: { pathname } }));
    if (pathname !== '/recommendation-list') {
      sessionStorage.removeItem('recommendationFilters');
    }
  }, [pathname]);

  const openPageTerms = user?.isLogged && !user?.termsAccepted;
  const openPageBrand =
    !themeSelected && user?.isLogged && user?.termsAccepted && user.brands.length > 1;
  const isFullAccess =
    !openPageTerms && !openPageBrand && user?.termsAccepted && user?.isLogged && user?.languageIso;

  return (
    <Suspense fallback={<span>Loading ...</span>}>
      {openPageTerms && (
        <GDMContainer
          sx={{
            ...flexLayout,
            padding: '80px',
            width: '100%',
            backgroundImage: `url(${backgrounTerm})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Switch>
            <Route path="/" element={<TermOfUser />} />
          </Switch>
        </GDMContainer>
      )}
      {openPageBrand && (
        <GDMContainer
          sx={{
            ...flexLayout,
            padding: '80px',
            width: '100%',
            backgroundImage: `url(${backgrounTerm})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100%',
          }}
        >
          <Switch>
            <Route
              path="/"
              element={
                <SelectBrandPage termsWasOpen={termsWasOpened} setTermsWasOpened={handleBack} />
              }
            />
          </Switch>
        </GDMContainer>
      )}
      {isFullAccess && (
        <GDMContainer sx={isDesktop ? flexLayout : flexLayoutMobile}>
          <GDMHeader handleToggle={handleToggle} />
          <GDMContainer sx={containerLayout}>
            <SideBar
              handleToggle={handleToggle}
              isOpen={isOpen}
              handleNavigation={handleNavigation}
            />
            <GDMContainer sx={contentPageStyles}>
              <Switch>
                <Route path="/" element={<DashboardPage />} />

                <Route
                  path="/multipliers/new"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <MultiplierRegisterPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/multipliers/view/:multiplierId" element={<MultiplierViewPage />} />
                <Route
                  path="/multipliers/edit/:multiplierId"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <MultiplierEditPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/admins/new"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <AdminRegisterPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/admins/view/:adminId" element={<AdminViewPage />} />
                <Route
                  path="/admins/edit/:adminId"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <AdminEditPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/account-seller-gdm/new"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <SellerGdmRegisterPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-seller-gdm/view/:sellerGdmId"
                  element={<SellerGdmViewPage />}
                />
                <Route
                  path="/account-seller-gdm/edit/:sellerGdmId"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <SellerGdmEditPage />
                    </ProtectedRoute>
                  }
                />

                <Route path="/onboarding" element={<OnboardingPage />} />
                <Route
                  path="distributor/new"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <DistributorRegisterPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="distributor/edit/:distributorId"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <DistributorEditPage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="distributor/view/:distributorId"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <DistributorViewPage />
                    </ProtectedRoute>
                  }
                />

                <Route path="farmer-register" element={<FarmerRegister />}>
                  <Route path=":farmerId" element={<FarmerForm />}>
                    <Route path="farm-step" element={<FarmerRegister />} />
                    <Route path="lot-step" element={<FarmerRegister />} />
                  </Route>
                </Route>
                <Route path="farmer-form-register" element={<FarmerForm />}>
                  <Route path=":farmerId" element={<FarmerForm />}>
                    <Route path="farm-flap" element={<FarmerForm />} />
                    <Route path="edit" element={<FarmerForm />} />
                    <Route path="view" element={<FarmerForm />} />
                  </Route>
                </Route>
                <Route path="farmer-farm-register" element={<FarmFormPage />}>
                  <Route path=":farmId" element={<FarmFormPage />}>
                    <Route path="lot-flap" element={<FarmFormPage />} />
                    <Route path="edit" element={<FarmFormPage />} />
                  </Route>
                </Route>
                <Route path="farmer-lot-register" element={<LotForm />}>
                  <Route path=":lotId" element={<LotForm />}>
                    <Route path="edit" element={<LotForm />} />
                  </Route>
                </Route>
                <Route
                  path="accounts-register"
                  element={
                    <ProtectedRoute requiredPermissions={['admin']}>
                      <AccountsRegister />
                    </ProtectedRoute>
                  }
                />
                <Route path="/farmer-farm-list" element={<FarmList />} />
                <Route path="/farmer-lot-list" element={<LotList />} />
                <Route
                  path="/admin-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <AdminListPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/multiplier-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <MultiplierPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/distributor-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <DistributorPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-manager-mult-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <ManagerMultList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-manager-dist-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <ManagerDistList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-seller-dist-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <SellerDistList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-seller-mult-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <SellerMultList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-seller-gdm-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <SellerGdmList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/list-menu-farmer"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <ShowFarmerRegisters />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/farmer-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <FarmerList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account-visualize"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <AccountsVisualize />
                    </ProtectedRoute>
                  }
                >
                  <Route path=":accountId" element={<AccountsVisualize />} />
                </Route>
                <Route
                  path="/account-edit"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <AccountsEdit />
                    </ProtectedRoute>
                  }
                >
                  <Route path=":accountId" element={<AccountsEdit />} />
                </Route>
                <Route path="/profile" element={<ProfilePage />} />
                <Route
                  path="/list-menu"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <ListMenuPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/recommendation"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <RecommendationPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/recommendation/to-save" element={<RecommendationToSave />} />
                <Route path="recommendation/view/:recId" element={<RecommendationViewPage />} />
                <Route path="/recommendation/pending" element={<RecommendationPendingList />} />
                <Route
                  path="/recommendation-list"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <RecommendationList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/varieties-to-compare"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <VarietiesToCompare />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/varieties-to-compare/:varietyId"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <VarietiesToCompare />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/varieties-to-compare/:varietyId/edit"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <VarietiesToCompare />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/catalog"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'admin',
                        'seller-gdm',
                        'multiplier',
                        'distributor',
                        'account',
                        'list',
                        'farmer',
                      ]}
                    >
                      <Catalog />
                    </ProtectedRoute>
                  }
                />
              </Switch>
            </GDMContainer>
          </GDMContainer>
        </GDMContainer>
      )}
    </Suspense>
  );
}
const flexLayout: SxProps = {
  display: 'flex',
  backgroundColor: '#E7E9F1',
  flexDirection: 'column',
  width: 'fit-content',
  minWidth: '100%',
};

const flexLayoutMobile: SxProps = {
  width: 'fit-content',
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
};

const containerLayout: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '100%',
  position: 'relative',
  overflow: 'auto',
};

const contentPageStyles: SxProps = {
  gridColumn: 2,
  gridRow: 1,
  width: { xs: '100vw', sm: '100vw', md: '100%', lg: '100%', xl: '100%' },
  minHeight: 'calc(100vh - 64px)',
  padding: {
    xs: '20px 12px',
    sm: '20px 12px',
    md: '30px',
    lg: '40px',
    xl: '44px',
  },
};
