/* eslint-disable react/no-this-in-sfc */
/* eslint-disable func-names */
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoIosArrowDown } from 'react-icons/io';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { AccountsService } from 'core/api/services/accounts';
import { DistributorService } from 'core/api/services/distributor';
import { MultiplierService } from 'core/api/services/multiplier';
import useLanguageSwitcher from 'core/hooks/useLanguageSwitcher';
import CountriesStore from 'core/store/stores/CountriesStore/CountriesStore';
import RolesStore from 'core/store/stores/RolesStore/RolesStore';
import UserProfileStore from 'core/store/stores/UserProfileStore/UserProfileStore';
import { Formatter } from 'core/utils';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useStore } from 'effector-react';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, FormControl, FormHelperText, TextField, useTheme } from '@mui/material';

import {
  GDMButton,
  GDMContainer,
  GDMCustomInputLabel,
  GDMLoading,
  GDMTypography,
} from 'components';

import useModal from 'hooks/useModal/useModal';

import { errorHandler } from 'utils/form/errorHandler';

import {
  boxStyle,
  inputMaskStyle,
  formControlStyle,
  formHelperTextStyle,
} from './AccountsRegister.styles';

export default function AccountsRegister(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useStore(UserProfileStore);
  const { countries } = useStore(CountriesStore);
  const { roles } = useStore(RolesStore);

  const [perfil, setPerfil] = useState('');
  const [multiplier, setMultiplier] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [distributorNames, setDistributorNames] = useState(['No options']);
  const [multipliersNames, setMultipliersNames] = useState(['No options']);
  const [multOrDistSelected, setMultOrDistSelected] = useState('');
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [fieldErrors, setFieldErrors] = useState<ObjectLiteral<string>>({});

  const { getLabelTranslation } = useLanguageSwitcher();

  const { openModal, closeModal } = useModal();

  const phoneValidation = {
    BrasilFixedLinePhone: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?([2-8]\d{3})-?(\d{3})$/,
    BrasilCellPhone:
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/,
    Argentina: /^[0-9()\s\-+]+$/,
  };

  const accountsFormSchema = yup.object({
    fullName: yup.string().required(`${getLabelTranslation('full_name_required')}`),
    country: yup.string().required(`${getLabelTranslation('country_required')}`),
    email: yup
      .string()
      .email(`${getLabelTranslation('invalid_email')}`)
      .required(`${getLabelTranslation('email_required')}`),
    phoneNumber: yup
      .string()
      .required(`${getLabelTranslation('phone_number_required')}`)
      .test('phoneNumber', `${getLabelTranslation('invalid_phone')}`, function (value) {
        const isBrasil = user.countryName === 'Brasil';
        const isArgentina = user.countryName === 'Argentina';
        const phoneValue = value as string;
        const cleanedPhoneNumber = phoneValue.replace(/[ \-+_]/g, '');
        const qtyOfNumbers = cleanedPhoneNumber.length;
        const fixedLinePhoneWithPrefixNine = cleanedPhoneNumber[4] === '9';
        const isABrasilFixedLinePhone = qtyOfNumbers === 12;
        const isABrasilCellPhone = qtyOfNumbers === 13;

        if (isArgentina) {
          if (!phoneValidation.Argentina.test(phoneValue)) {
            return false;
          }
        }

        if (isABrasilFixedLinePhone && isBrasil && fixedLinePhoneWithPrefixNine) {
          if (!phoneValidation.BrasilFixedLinePhone.test(phoneValue)) {
            return false;
          }
        }

        if (isABrasilCellPhone && isBrasil) {
          if (!phoneValidation.BrasilCellPhone.test(phoneValue)) {
            return false;
          }
        }

        if (
          qtyOfNumbers !== 12 &&
          qtyOfNumbers !== 13 &&
          !isABrasilCellPhone &&
          !isABrasilFixedLinePhone
        ) {
          return false;
        }

        return true;
      }),
    documentNumber: yup
      .string()
      .required(`${getLabelTranslation('document_required')}`)
      .test('documentNumber', `${getLabelTranslation('invalid_document')}`, value => {
        const documentNumber = value as string;
        const cleanedDocumentNumber = documentNumber.replace(/[.\-+/ ]/g, '');

        if (user.countryName === 'Brasil' && cleanedDocumentNumber?.length === 11) {
          return cpf.isValid(cleanedDocumentNumber);
        }

        if (user.countryName === 'Brasil' && cleanedDocumentNumber?.length === 14) {
          return cnpj.isValid(cleanedDocumentNumber);
        }

        if (user.countryName === 'Argentina') {
          return Formatter.cuitIsValid(cleanedDocumentNumber);
        }

        return true;
      })
      .test(
        'documentNumber',
        `O número do documento deve conter 11 dígitos (CPF) ou 14 dígitos (CNPJ).`,
        function (value) {
          const documentNumber = value as string;
          const cleanedDocumentNumber = documentNumber.replace(/[.\-+/ ]/g, '');
          if (
            user.countryName === 'Brasil' &&
            cleanedDocumentNumber?.length !== 11 &&
            cleanedDocumentNumber?.length !== 14
          ) {
            return false;
          }

          return true;
        },
      ),
    businessName: yup
      .string()
      .test('businessName', `${getLabelTranslation('business_name_required')}`, function (value) {
        const { documentNumber } = this.parent;
        const cleanedDocumentNumber = documentNumber ? documentNumber.replace(/\D/g, '') : '';
        if (cleanedDocumentNumber && cleanedDocumentNumber.length > 11 && !value) {
          return false;
        }
        return true;
      }),
    responsible: yup.string().required(`${getLabelTranslation('responsible_required')}`),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(accountsFormSchema),
  });

  const documentNumberTyped = watch('documentNumber');
  const onlyNumbersOfDocumentTyped = documentNumberTyped
    ? documentNumberTyped.replace(/\D/g, '')
    : '';

  const handleChangeBrands = (value: string) => {
    if (!value) {
      return;
    }
    const multOrDist = value;
    if (perfil.includes('Distribuidor')) {
      const distChosed = distributor.find(dist => dist.name === multOrDist);
      const distChosedBrands = distChosed.brands.map(brand => brand.name);
      setBrands(distChosedBrands);
    }

    if (perfil.includes('Multiplicador')) {
      const multChosed = multiplier.find(mult => mult.name === multOrDist);
      const multChosedBrands = multChosed.brands.map(brand => brand.name);
      setBrands(multChosedBrands);
    }
  };

  const setDocumentTypeId = () => {
    if (user.countryName === 'Argentina') {
      return 'b85d6c71-e469-4268-a813-1bac98f596ae';
    }

    let onlyNumbers: number;

    if (documentNumberTyped) {
      onlyNumbers = documentNumberTyped.replace(/\D/g, '').length;
    }

    if (user.countryName === 'Brasil' && onlyNumbers === 11) {
      return 'd2334494-b6d5-4a92-93cb-a3cd38d54d0d';
    }

    return 'cc49f596-0841-4708-9ef8-6e372ea70ddf';
  };

  const setCountryId = () => {
    if (user.countryName === 'Brasil') {
      const countryDefault = countries.find(country => country.name === 'Brasil');
      return countryDefault.id;
    }

    if (user.countryName === 'Argentina') {
      const countryDefault = countries.find(country => country.name === 'Argentina');
      return countryDefault.id;
    }

    return 'default_country_id';
  };

  const setRoleId = () => {
    if (perfil === `${getLabelTranslation('manager_multiplier')}`) {
      const roleDefault = roles.find(role => role.name === 'MULTIPLIER_MANAGER');
      return roleDefault.id;
    }
    if (perfil === `${getLabelTranslation('manager_distributor')}`) {
      const roleDefault = roles.find(role => role.name === 'DISTRIBUTOR_MANAGER');
      return roleDefault.id;
    }
    if (perfil === `${getLabelTranslation('seller_multiplier')}`) {
      const roleDefault = roles.find(role => role.name === 'MULTIPLIER_SELLER');
      return roleDefault.id;
    }
    if (perfil === `${getLabelTranslation('seller_distributor')}`) {
      const roleDefault = roles.find(role => role.name === 'DISTRIBUTOR_SELLER');
      return roleDefault.id;
    }

    return 'default_role_id';
  };

  const setMultiplierId = async () => {
    if (perfil.includes('Multiplicador') && multOrDistSelected) {
      const multplierSelected = multiplier.find(mult => mult.name === multOrDistSelected);
      return multplierSelected ? multplierSelected.id : null;
    }
    return null;
  };

  const setDistributorId = async () => {
    if (perfil.includes('Distribuidor') && multOrDistSelected) {
      const distSelected = distributor.find(dist => dist.name === multOrDistSelected);
      return distSelected ? distSelected.id : null;
    }
    return null;
  };

  const handleCancel = () => {
    openModal({
      type: 'warning',
      title: getLabelTranslation('modal_title_lost_info'),
      message: getLabelTranslation('modal_message_lost_info'),
      primaryAction: {
        label: getLabelTranslation('OK'),
        action: () => {
          navigate('/list-menu');
          closeModal();
        },
      },
      secondAction: {
        label: getLabelTranslation('ReusableFormKey_Back'),
        action: () => {
          closeModal();
        },
      },
    });
  };

  const handleCloseModal = () => {
    const profilePaths = {
      [getLabelTranslation('manager_multiplier')]: '/account-manager-mult-list',
      [getLabelTranslation('manager_distributor')]: '/account-manager-dist-list',
      [getLabelTranslation('seller_multiplier')]: '/account-seller-mult-list',
      [getLabelTranslation('seller_distributor')]: '/account-seller-dist-list',
    };

    if (!profilePaths[perfil]) {
      navigate('/');
    }

    if (profilePaths[perfil]) {
      navigate(profilePaths[perfil]);
    }
  };

  const onSubmit = async (data: any) => {
    const { fullName, email, phoneNumber, documentNumber, businessName, responsible } = data;
    const multiplierId = await setMultiplierId();
    const distributorId = await setDistributorId();

    const account = {
      name: fullName,
      email,
      phoneNumber,
      documentTypeId: setDocumentTypeId(),
      documentNumber,
      countryId: setCountryId(),
      roleId: setRoleId(),
      multiplierId,
      distributorId,
      fantasyName: businessName || '',
      personInChargeName: responsible,
    };

    try {
      setLoading(true);
      await AccountsService.createAccount(account);
      openModal({
        type: 'success',
        title: getLabelTranslation('modal_tittle_create_success'),
        onClose: handleCloseModal,
      });
    } catch (error) {
      errorHandler(error, setFieldErrors);

      openModal({
        type: 'error',
        title: getLabelTranslation('modal_tittle_create_error'),
        message: getLabelTranslation('modal_message_create_error'),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDistributorsAndMultipliers = async () => {
      try {
        const distributorsResponse = await DistributorService.getDistributors({
          currentPage: 1,
          itemsPerPage: 200,
          search: '',
          searchColumn: '',
          orderDesc: false,
        });
        setDistributor(distributorsResponse.items);
        setDistributorNames(distributorsResponse.items.map(dist => dist.name));

        const multipliersResponse = await MultiplierService.getMultipliers({
          currentPage: 1,
          itemsPerPage: 200,
          search: '',
          searchColumn: '',
          orderDesc: false,
        });
        setMultiplier(multipliersResponse.items);
        setMultipliersNames(multipliersResponse.items.map(mult => mult.name));

        handleChangeBrands(multOrDistSelected);
        setValue('country', user.countryName);
      } catch (error) {
        console.error('Failed to fetch distributors and multipliers', error);
      } finally {
        setLoadingPage(false);
      }
    };

    fetchDistributorsAndMultipliers();
  }, [multOrDistSelected, setValue]);

  const handleBlur = event => {
    const inputValue = event.target.value.replace(/\D/g, '');

    if (inputValue.length === 11 && user.countryName === 'Brasil') {
      const formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      event.target.value = formattedValue;

      setValue('businessName', '');
      setValue('documentNumber', formattedValue);
    }

    if (inputValue.length === 14 && user.countryName === 'Brasil') {
      const formattedValue = inputValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
      event.target.value = formattedValue;
      setValue('documentNumber', formattedValue);
    }

    if (inputValue.length === 11 && user.countryName === 'Argentina') {
      const formattedValue = inputValue.replace(/(\d{2})(\d{8})(\d{1})/, '$1-$2-$3');
      event.target.value = formattedValue;
      setValue('documentNumber', formattedValue);
    }
  };

  if (loadingPage) {
    return (
      <GDMContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh' }}
      >
        <GDMLoading type="circular" />
      </GDMContainer>
    );
  }

  return (
    <>
      <Box>
        <Box sx={boxStyle}>
          <GDMTypography
            sx={{ color: '#A1A9B8', fontSize: '14px', m: 0 }}
            display="block"
            gutterBottom
          >
            {`${getLabelTranslation('administrative')} ${'>'} ${getLabelTranslation(
              'create_account',
            )}`}
          </GDMTypography>
          <GDMTypography
            variant="h4"
            textAlign="left"
            mt={3}
            mb={3}
            fontWeight={700}
            gutterBottom
            component="div"
            color="#222222"
          >
            {getLabelTranslation('create_account')}
          </GDMTypography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '11px',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <GDMCustomInputLabel
                required
                label={getLabelTranslation('customRolesInputs_select_profile')}
                fontSize="16px"
                color="#222222"
              />
              <Autocomplete
                onChange={(_, value) => {
                  setPerfil(value);
                  setMultOrDistSelected(null);
                }}
                popupIcon={<IoIosArrowDown />}
                options={[
                  `${getLabelTranslation('manager_multiplier')}`,
                  `${getLabelTranslation('manager_distributor')}`,
                  `${getLabelTranslation('seller_multiplier')}`,
                  `${getLabelTranslation('seller_distributor')}`,
                ]}
                sx={{
                  width: {
                    xs: '100%',
                    md: '333.5px',
                  },
                  minHeight: '48px',
                  background: '#FAFAFA',
                  input: {
                    background: '#FAFAFA',
                    border: 'none',
                    height: '100%',
                  },
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    focused={false}
                    placeholder={getLabelTranslation('select_profile')}
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {perfil && (
                <>
                  <GDMCustomInputLabel
                    required
                    label={`${getLabelTranslation('select')} ${
                      perfil.includes('Multiplicador')
                        ? `${getLabelTranslation('ReusableFormKey_Multiplier')}`
                        : `${getLabelTranslation('brandeselector_distributor')}`
                    }`}
                    fontSize="16px"
                    color="#222222"
                  />
                  <Autocomplete
                    onChange={(_, value) => setMultOrDistSelected(value)}
                    value={multOrDistSelected}
                    popupIcon={<IoIosArrowDown />}
                    options={perfil.includes('Multiplicador') ? multipliersNames : distributorNames}
                    disabled={!perfil}
                    sx={{
                      width: {
                        xs: '100%',
                        md: '333.5px',
                      },
                      minHeight: '48px',
                      background: '#FAFAFA',
                      input: {
                        background: '#FAFAFA',
                        border: 'none',
                        height: '100%',
                      },
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        focused={false}
                        placeholder={`Selecionar ${
                          perfil.includes('Multiplicador')
                            ? `${getLabelTranslation('ReusableFormKey_Multiplier')}`
                            : `${getLabelTranslation('brandeselector_distributor')}`
                        }`}
                        variant="outlined"
                        disabled={!perfil || perfil === 'Vendedor GDM'}
                      />
                    )}
                  />
                </>
              )}
            </Box>
          </Box>
          <form id="accounts-form" noValidate>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_full_name')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  {...register('fullName')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_full_name')}
                      variant="outlined"
                      fullWidth
                      error={!!errors.fullName || !!fieldErrors?.fullName}
                      helperText={errors.fullName?.message || fieldErrors?.fullName}
                      {...field}
                      sx={{
                        input: {
                          padding: '0 0 0 14px',
                          height: '48px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_email')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  {...register('email')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_email')}
                      variant="outlined"
                      fullWidth
                      error={!!errors.email || !!fieldErrors?.contactEmail}
                      helperText={errors.email?.message || fieldErrors?.contactEmail}
                      {...field}
                      sx={{
                        input: {
                          padding: '0 0 0 14px',
                          height: '48px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_country')}
                  fontSize="16px"
                  color="#222222"
                />
                <FormControl>
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={user.countryName}
                    {...register('country')}
                    render={() => (
                      <TextField
                        fullWidth
                        disabled
                        defaultValue={user.countryName}
                        value={user.countryName}
                        sx={{
                          background: '#FAFAFA',
                          input: {
                            backgroundColor: '#F2F4F9',
                            padding: '0 0 0 14px',
                            height: '48px',
                            border: 'none',
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_phone')}
                  fontSize="16px"
                  color="#222222"
                />
                <FormControl sx={errors?.phoneNumber?.message && formControlStyle}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true }}
                    {...register('phoneNumber')}
                    render={({ field }) => (
                      <InputMask
                        style={inputMaskStyle}
                        mask={`${
                          user.countryName === 'Brasil' ? '+99 99 99999-9999' : '+99 9 999 999-9999'
                        }`}
                        name="phoneNumber"
                        placeholder={getLabelTranslation('formfields_phone')}
                        maskChar={null}
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                  {!!errors?.phoneNumber && (
                    <FormHelperText sx={formHelperTextStyle}>
                      {errors?.phoneNumber?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <GDMCustomInputLabel
                  required
                  label={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
                  fontSize="16px"
                  color="#222222"
                />
                <FormControl sx={errors?.documentNumber?.message && formControlStyle}>
                  <TextField
                    {...register('documentNumber')}
                    placeholder={user.countryName === 'Brasil' ? 'CPF/CNPJ' : 'CUIT'}
                    variant="outlined"
                    fullWidth
                    onBlur={e => handleBlur(e)}
                    inputProps={{ maxLength: 18 }}
                    sx={{
                      input: {
                        padding: '0 0 0 14px',
                        height: '48px',
                      },
                    }}
                  />
                  {!!errors?.documentNumber && (
                    <FormHelperText sx={formHelperTextStyle}>
                      {errors?.documentNumber?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_business_name')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="businessName"
                  control={control}
                  rules={{ required: true }}
                  {...register('businessName')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_business_name')}
                      variant="outlined"
                      disabled={onlyNumbersOfDocumentTyped.length <= 11}
                      fullWidth
                      error={!!errors.businessName || !!fieldErrors.businessName}
                      helperText={errors.businessName?.message || fieldErrors.businessName}
                      {...field}
                      sx={{
                        '& .Mui-disabled': {
                          backgroundColor: '#F2F4F9',
                        },
                        input: {
                          padding: '0 0 0 14px',
                          height: '48px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '11px',
                mt: 3,
                mb: {
                  xs: 2,
                  md: 8,
                },
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('formfields_responsible')}
                  fontSize="16px"
                  color="#222222"
                />
                <Controller
                  name="responsible"
                  control={control}
                  rules={{ required: true }}
                  {...register('responsible')}
                  render={({ field }) => (
                    <TextField
                      placeholder={getLabelTranslation('formfields_responsible')}
                      variant="outlined"
                      sx={{
                        width: {
                          xs: '100%',
                          md: '333.5px',
                        },
                        input: {
                          padding: '0 0 0 14px',
                          height: '48px',
                        },
                      }}
                      error={!!errors.responsible || !!fieldErrors.personInChargeName}
                      helperText={errors.responsible?.message || fieldErrors.personInChargeName}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <GDMCustomInputLabel
                  required
                  label={getLabelTranslation('header_brand')}
                  fontSize="16px"
                  color="#222222"
                />
                <Autocomplete
                  sx={{
                    '& .MuiAutocomplete-disabled': {
                      cursor: 'not-allowed',
                    },
                    '& .MuiChip-root.Mui-disabled': {
                      color: '#222222',
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      minHeight: '48px',
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    },
                    '& .MuiOutlinedInput-root': {
                      padding: '0px 9px',
                      minHeight: '48px',
                      boxSizing: 'border-box',
                    },
                    '& .MuiInputBase-input': {
                      padding: '10px 14px',
                      minHeight: 'auto',
                    },
                  }}
                  multiple
                  id="tags-outlined"
                  options={brands}
                  value={brands}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      sx={{
                        width: {
                          xs: '100%',
                          md: '333.5px',
                        },
                        minHeight: '48px',
                        '& .MuiOutlinedInput-root': {
                          minHeight: '48px',
                        },
                        '& .Mui-disabled': {
                          backgroundColor: '#F2F4F9',
                        },
                        '& .MuiFormHelperText-root': {
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: theme.spacing(0.5),
                          backgroundColor: '#FAFAFA',
                        },
                      }}
                    />
                  )}
                  disabled
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: {
                  xs: '12px',
                  md: 3,
                },
                flexDirection: 'row',
              }}
            >
              <GDMButton
                onClick={() => handleCancel()}
                disabled={loading}
                variant="outlined"
                sx={{
                  borderStyle: 'none',
                  width: {
                    xs: '100%',
                    md: '187px',
                  },
                  boxShadow: 'none',
                  color: theme.palette.secondary.dark,
                  background: theme.palette.secondary.main,
                  ':hover': {
                    borderStyle: 'none',
                    background: theme.palette.primary.light,
                  },
                  '&.Mui-disabled': {
                    background: theme.palette.action.disabled,
                    color: 'white',
                  },
                }}
              >
                {getLabelTranslation('changePasswordModal_cancel')}
              </GDMButton>
              <GDMButton
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
                type="button"
                variant="outlined"
                sx={{
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  height: '51px',
                  width: {
                    xs: '100%',
                    md: '187px',
                  },
                  boxShadow: 'none',
                  borderStyle: 'none',
                  ':hover': {
                    background: theme.palette.primary.dark,
                  },
                  '&.Mui-disabled': {
                    background: theme.palette.action.disabled,
                    color: 'white',
                  },
                }}
              >
                {loading ? (
                  <GDMLoading type="circular" {...{ size: 24 }} />
                ) : (
                  getLabelTranslation('changePasswordModal_save')
                )}
              </GDMButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}
